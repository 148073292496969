import { addHours, format, parseISO } from "date-fns";
import React, { Fragment, useContext } from "react";
import { TiCancel } from "react-icons/ti";
import { NovaContext } from "../context";
import { checkAccess } from "../Helper/GlobalHelpers";
import Button from "./Button";

const ListExpenses = (props) => {

    const {
        expenses,
        pageReportMode,
        selectAction
    } = props;

    const { currentRole } = useContext(NovaContext);
    const hasAuditorAccess = checkAccess(currentRole, 'Auditor');
    // console.log(expenses)
    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                    <thead>
                        <tr>
                            <th>Responsable</th>
                            <th>Cantidad</th>
                            <th>Fecha del cargo</th>
                            <th>Descripcion</th>
                            {
                                pageReportMode ?
                                    <>

                                        {
                                            hasAuditorAccess ?
                                                <th>Revocar</th> : null
                                        }
                                    </>
                                    :
                                    null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {expenses && expenses.filter((val) => { return val })
                            .map((expense, i) => (
                                <tr key={i}>
                                    <td>{expense.employee_name} {expense.employee_lastname}      </td>
                                    <td>{expense.amount}  </td>
                                    <td>{format(parseISO(expense.createdate), "dd/MM/yyyy HH:mm:ss")} hrs                            </td>
                                    <td>{expense.description}      </td>

                                    {
                                        pageReportMode ?
                                            <>
                                                <td>
                                                    {
                                                        hasAuditorAccess ?
                                                            <Button
                                                                type={"button"}
                                                                nameOfClass={"btn ml-2 btn-warning btn-hover-light icon-m alarm"}
                                                                clickBehaviour={() => selectAction(expense.id, 'RevokeExpense')}
                                                                text={""}
                                                                icon={<TiCancel />}
                                                            />
                                                            : null
                                                    }
                                                </td>
                                            </>
                                            :
                                            null
                                    }
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default ListExpenses;