import { addHours, formatISO } from 'date-fns';
import React, { useContext, useRef, useState, Fragment } from 'react';
import { NovaContext } from '../context';
import { getTimeDifferenceInHours } from '../Helper/GlobalHelpers';
import Modal from './Modal';

const NewExpense = (props) => {

    const {
        modalClass,
        setLoading
    } = props

    const { actions, filial, authenticatedUser, currentRole } = useContext(NovaContext);
    const [display, toggleDisplay] = useState(false);
    const [validationStatus, setValidationStatus] = useState(true)

    const amount = useRef();
    const description = useRef();

    const initializeForm = () => {
        amount.current.value = '0';
        description.current.value = '';
    };

    const handleClick = () => {
        initializeForm()
        toggleDisplay(!display)
    };

    const closeModalView = () => {
        initializeForm();
        toggleDisplay();
    };

    const handleChange = (event) => {
        //TBD add a validation for the onChange process
        setValidationStatus(validate())
    }

    const validate = () => {
        //TBD add a validation 
        return true
    }

    const handleCreate = async (e) => {
        e.preventDefault();
        setLoading(true);

        const validateCheck = validate()
        if (validateCheck) {
            const currentDate = addHours(new Date(),+getTimeDifferenceInHours());
            const tempExpense =
            {
                "amount": amount.current.value,
                "description": description.current.value,
                "createdate": formatISO(currentDate),
                "employeeId": authenticatedUser.employee_id,
                "filialId": filial
            }
            const newExpense = await actions.addExpense(tempExpense);
            closeModalView();
            window.location.reload();
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleCreate}>
            <Modal
                classNames={`${modalClass} ${display ? 'show' : ''}`}
                toggleModal={handleClick}
                buttonName="Agregar gasto"
                buttonClass={"btn btn-white font-weight-bold"}
                header="Agregar gasto"
                body={() => (
                    <Fragment>
                        <div className="grid-container-form">

                            <label>Cantidad en efectivo
                                <input type="number"
                                    className={`form-control text-right`}
                                    ref={amount}
                                    required
                                    onChange={(e) => handleChange(e)}
                                />
                            </label>

                            <label>Detalles del gasto
                                <textarea type="text"
                                    className="form-control"
                                    ref={description}
                                    required
                                />
                            </label>

                        </div>
                    </Fragment>
                )}

                footer={() => (
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-success"
                            onClick={() => handleCreate()}
                        >
                            Continuar
                        </button>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => closeModalView()}
                        >
                            Cancelar
                        </button>
                    </Fragment>
                )}
            />
        </form>
    )

}

export default NewExpense;