import React from "react";
import { CashflowProvider } from "../context/CashflowContext";

// component
import CashflowList from "../../pages/CashflowOverview/components/CashflowList";

const CashflowListWrapper = ({
  columnVisibilityModel,
  sortingFilter,
  customerId,
  employeeId,
}) => {
  return (
    <CashflowProvider
    // sortingFilter={sortingFilter}
    // customerIdFilter={customerId}
    // employeeIdFilter={employeeId}
    >
      <CashflowList columnVisibilityModel={columnVisibilityModel} />
    </CashflowProvider>
  );
};

export default CashflowListWrapper;
