import React, { useEffect } from "react";

// internal
import { getCashflow } from "../../utils/api/api_cashflow";
import { useCashflowContext } from "../context/CashflowContext";

//reducers
import { CashflowActions } from "../../reducers/CashFlowReducer";
import { LoadingActions } from "../../reducers/LoadingReducer";

// api library
import { alerts, displayAlert } from "../Helper/AlertToastify";

const CashflowController = ({
  sortingFilter,
  customerIdFilter,
  employeeIdFilter,
}) => {
  const {
    filial,
    dateRange,
    dispatchCashflow,
    dispatchLoading,
  } = useCashflowContext();
  const start = dateRange.start.toISOString();
  const end = dateRange.end.toISOString();

  useEffect(() => {
  // Construct the query parameters
  let queryParams = `filial=${filial}`;

  if (start) {
    queryParams += `&start=${start}`;
  }
  if (end) {
    queryParams += `&end=${end}`;
  }
  if (sortingFilter) {
    queryParams += `&sorting=${sortingFilter}`;
  }
  console.log(queryParams);

  getCashflow(
      queryParams
        // `filial=${filial}&start=${start}&end=${end}`
    )
      .then((resp) =>
        dispatchCashflow({ type: CashflowActions.SET_CASHFLOW, payload: resp })
      )
      .catch((err) =>
        displayAlert(alerts.ERROR, `No se han podido cargar las transacciones de caja: ${err}`)
      )
      .finally(() =>
        dispatchLoading({ type: LoadingActions.SET_LOADING, payload: false })
      );
  }, [filial, dateRange]);
  return null;
};

export default CashflowController;
