import React from "react";

// component
import ExpensesListWrapper from "../../../components/Wrappers/ExpensesListWrapper";

// mui
import Grid from "@mui/material/Grid2";
import { ItemPaper } from "../../../components/Helper/MUIStyledComponents";
import { columnVisibilityModelExpensesContainer } from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Expenses";
import { useNavigate } from "react-router-dom";
import BreadcrumbNavigation from "../../Reports/BreadcrumbReports";

const ExpensesContainer = () => {
  const navigate = useNavigate();

  const handleBackClick = (event) => {
    event.preventDefault();
    navigate("/Reports");
  };
  return (
    <Grid container sx={{ padding: { xs: " 10px", md: "25px" } }}>
      <Grid size={12} display="flex" justifyContent="left" alignItems="left">
      <BreadcrumbNavigation currentPage="Gastos" />
        {/* <h1 className="text-center">Gastos</h1> */}
      </Grid>
      <Grid size={12}>
        <ItemPaper>
          <ExpensesListWrapper
            columnVisibilityModel={columnVisibilityModelExpensesContainer}
          />
        </ItemPaper>
      </Grid>
    </Grid>
  );
};

export default ExpensesContainer;
