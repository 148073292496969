import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// internal misc
import { useAuditContext } from "../../../components/context/AuditContext";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../../components/Helper/MUIStyledComponents";

// components
import DateRange from "../../../components/common/DateRange/DateRange";
import { DateRangeActions } from "../../../components/common/DateRange/DateRangeReducer";
import { renderNoRowDataOverlay } from "../../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import CustomLoadingOverlay from "../../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";

// helper
import {
  prepareAuditColumns,
  getAuditAutoSizeOptions,
} from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Audit";

// mui
import Box from "@mui/material/Box";

const AuditList = ({ columnVisibilityModel }) => {
  const { loading, dateRange, dispatchDateRange, audits } = useAuditContext();
  const auditAutoSizeOptions = getAuditAutoSizeOptions();
  const navigate = useNavigate();

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });
  // const columnVisibilityModel = getPaymentVisibleColumns(currentRole);


  const changeDateRange = (updatedDates) => {
    dispatchDateRange({
      type: DateRangeActions.SET_DATE_RANGE_FILTER,
      payload: updatedDates,
    });
  };

  return (
    <div>
      <Box height="85vh" width="100%">
        <CustomDataGrid
          columns={prepareAuditColumns(navigate)}
          rows={audits}
          getRowId={(row) => row.id}
          slots={{
            toolbar: CustomGridToolbar,
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: () =>
              renderNoRowDataOverlay("No hay auditorías para este periodo"),
          }}
          slotProps={{
            toolbar: {
              component1: (
                <DateRange
                  dateObject={dateRange}
                  changeMethod={changeDateRange}
                />
              ),
            },
          }}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          filterModel={filterModel}
          initialState={{
            columns: {
              columnVisibilityModel,
            },
            filter: {
              filterModel: {
                filterModel: filterModel,
              },
            },
          }}
          autosizeOptions={auditAutoSizeOptions}
          loading={loading}
        />
      </Box>
    </div>
  );
};

export default AuditList;
