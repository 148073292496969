import React from 'react'
import Image from '../Image'
import loadingImage from '../../images/loading.gif'

const Loading = ( text, classNames ) => {
    
  return (
    <>
        <Image source={loadingImage} description={text} classNames={classNames}/>
    </>
  )
}

export default Loading