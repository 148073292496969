import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../components/context/index";
import LogoLanita from "../images/LOGO LANITA SOLIDARIA BLANCO.png"; // Logo Default
// import LogoLanita from "../images/lanita_naviBN_2.png"; // Logo Christmas
// import LogoLanita from "../images/Lanita_Valentin_White.png"; // Logo San Valentin
// import LogoLanita from "../images/lanita_284_BN_Muertos.png";
import backgroundImage from "../images/bg-1.jpg";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CssBaseline,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const LoginTextField = styled(TextField)(({ theme }) => ({
//   background: "transparent",
//   textAlign: "left",
//   padding: 0.25,
//   "& .MuiInputBase-root": {
//     color: "white",
//     fontWeight: "bold",
//     fontSize: { xs: 12, md: 16 },
//     borderRadius: 2,
//     background: "transparent",
//   },
//   "& .MuiInputBase-input": {
//     padding: 1,
//     color: "white",
//     fontSize: { xs: 12, md: 16 },
//     background: "transparent",
//   },
//   "& .MuiFormLabel-root": {
//     color: "white",
//   },
  // "& .MuiFormLabel-root.Mui-focused": {
  //   color: "secondary.main",
  // },
}));

const Login = () => {
  const { actions, authenticatedUser } = useContext(NovaContext);
  const userEmail = React.createRef();
  const userPassword = React.createRef();
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticatedUser) {
      navigate("/");
    }
  }, [authenticatedUser, navigate]);

  const onSubmitForm = (e) => {
    e.preventDefault();

    const errorList = [];

    if (!userEmail.current.value || !userPassword.current.value) {
      errorList.push("Please provide a valid email and password");
    }

    if (!errorList.length) {
      actions
        .login({
          email: userEmail.current.value,
          password: userPassword.current.value,
        })
        .then((status) => {
          if (status === 201) {
            navigate("/");
          } else {
            errorList.push("Invalid login");
            setErrors(errorList);
          }
        })
        .catch((err) => {
          console.error(err);
          setErrors(errorList);
          navigate("/error");
        });
    } else {
      setErrors(errorList);
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundSize: "cover",
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              borderRadius: "20px",
              padding: "24px",
              textAlign: "center",
            }}
          >
            <img
              src={LogoLanita}
              className="max-h-100px"
              alt="LanitaSolidaria"
            />
            {/* <div className="custom-background-svg-white"></div> */}
            {/* <Box
              sx={{
                // display: "flex",
                // flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
                // height: "75px",
                // backgroundSize: "cover",
                // backgroundImage: LogoLanita,
                // width: 28,
                height: '75px',
                "&:before": {
                  content: "''",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  left: 0,
                  top: 0,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                backgroundImage: LogoLanita,

                  // backgroundImage: convertSvg(<CheckCircleIcon color={primaryPale} />)
                },
              }}
            /> */}
            <Typography variant="h5" color="white" sx={{ marginTop: "25px" }}>
              Inicia sesión
            </Typography>
            <Typography
              variant="body1"
              color="grey"
              sx={{ marginBottom: "20px" }}
            >
              Ingresa tu usuario y contraseña
            </Typography>

            <form onSubmit={onSubmitForm}>
              <LoginTextField
                type="email"
                name="email"
                placeholder="Email"
                inputRef={userEmail}
                fullWidth
                margin="normal"
                sx={{
                    width: "90%",
                  padding: 0.25,
                  "& .MuiInputBase-root": {
                    color: "white",
                    fontWeight: "bold",
                    fontSize: { xs: 12,  },
                    borderRadius: 6,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                  },
                  "& .MuiInputBase-input": {
                    padding: 1,
                    color: "white",
                    fontSize: { xs: 12, },
                  },
                  "& .MuiFormLabel-root": {
                    color: "white",
                  },
                  "& .MuiFormControl-root-MuiTextField-root": {
                    
                  },
            
                }}
              />
              <LoginTextField
                type="password"
                name="password"
                placeholder="Contraseña"
                fullWidth
                variant="outlined"
                inputRef={userPassword}
                error={errors.length > 0}
                helperText={
                  errors.length > 0
                    ? "Ingrese un usuario y contraseña válidos."
                    : ""
                }
                sx={{
                  padding: 0.25,
                  width: "90%",
                  "& .MuiInputBase-root": {
                    color: "white",
                    fontSize: { xs: 12, },
                    borderRadius: 6,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                  },
                  "& .MuiInputBase-input": {
                    padding: 1,
                    fontSize: { xs: 12,},
                  },
                  "& .MuiFormLabel-root": {
                    color: "white",
                  },
                }}
              />
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  sx={{
                    fontWeight: "bold",
                    borderRadius: 6,
                    width: "40%"
                  }}
                >
                  Inicia sesión
                </Button>
              </Box>
            </form>

            <Box mt={2}>
              <Typography variant="body2" color="white">
                ¿Olvidaste tu contraseña?{" "}
                <Link
                  href="mailto:arturo.mendix@gmail.com"
                  sx={{ color: "grey" }}
                >
                  Contáctanos
                </Link>
              </Typography>
            </Box>
          </Box>

          <Box
            mt={2}
            sx={{
              color: "text.secondary",
              textAlign: "center",
            }}
          >
            <Typography variant="body2" color="white">
              © 2023 Novaloans
            </Typography>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Login;
