import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListFilialBySearch from "../components/Actions/ListFilialBySearch";
import Loading from "../components/Actions/Loading";
import { NovaContext } from "../components/context";
import { Grid } from "@mui/material";

// Data import

const Filials = () => {
  const { actions } = useContext(NovaContext);
  const [filials, setFilials] = useState([]);
  const [lockStatus, setLockStatus] = useState(false);

  let navigate = useNavigate();

  const handleView = (filialId) => {
    actions.setActiveFilial(filialId);
    const path = `/Filial/${filialId}`;
    navigate(path);
    return null;
  };

  // const setDefaultFilial = () => {
  //     actions.setActiveFilial(filials[0]);
  // };

  useEffect(() => {
    actions.getFilials().then((data) => {
      setFilials(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // <div className="page-container">
    <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }}>
      <div
        className={`over-page-loading ${
          lockStatus ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${
              lockStatus ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>
      {filials && filials.length === 1 ? (
        handleView(filials[0].filial_id)
      ) : filials && filials.length > 1 ? (
        <Fragment>
          <ListFilialBySearch filials={filials} setLockStatus={setLockStatus} />
        </Fragment>
      ) : (
        // filials && filials.length === 0 ?
        <Fragment>Ninguna sucursal asignada a este usuario.</Fragment>
      )}
      {/* </div> */}
    </Grid>
  );
};

export default Filials;
