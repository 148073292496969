import React, { Fragment } from "react";

const Dropdown = ({ className, label, value, options, onChange, readOnly }) => {
  return (
      readOnly ?
    <Fragment>
      <label className="label-no-margin-button">
        {label}
        <select className={className} value={value} onChange={onChange} disabled>
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label} </option>
          ))}
        </select>
      </label>
      </Fragment>
      :
      <Fragment>
      <label className="label-no-margin-button">
        {label}
        <select className={className} value={value} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label} </option>
          ))}
        </select>
      </label>
    </Fragment>
  );
};

export default Dropdown;

