import { Button } from "@mui/material";
import React, { Fragment, useState } from "react";

const ModalV2 = (props) => {
  const {
    toggleModal,
    classNames,
    buttonName,
    buttonClass,
    button,
    header,
    // target,
    submit,
    body,
    footer,
  } = props;

  // const [innerToggle, setInnerToggle] = useState(toggle);

  const handleSubmit = () => {
    console.log("I've been clicked");
    // submit();
  };

  return (
    <Fragment>
      <div>{button()}
      </div>

      {/* <div className={`modal ${innerToggle ? "show" : ""}`}> */}
      <div className={`modal ${classNames}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{header}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => toggleModal()}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">{body()}</div>

            <div className="modal-footer">{footer()}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModalV2;
