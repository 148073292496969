// Utility import
import { format, parseISO } from "date-fns";
import React, { Fragment, useState, useContext } from "react";
import { NovaContext } from "../context";
import { checkAccess } from "../Helper/GlobalHelpers";
import Button from "./Button";
import { TiCancel } from "react-icons/ti";

// Component import
const ListPaymentsBySearch = (props) => {

    const {
        payments,
        pageReportMode,
        selectAction
    } = props;

    const { currentRole } = useContext(NovaContext);
    const [searchText, setSearchText] = useState("");
    const hasAuditorAccess = checkAccess(currentRole, 'Auditor');

    if (payments) {
        payments.map((item) => { item.fullName = item.customer_name + ' ' + item.customer_lastname });
    }

    return (
        <Fragment>
            <form className="d-flex">
                <input type="text"
                    name="searchField"
                    className="form-control"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                />
            </form>
            <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Cliente</th>
                            <th>Cantidad total</th>
                            <th>Refrendo</th>
                            <th>Mora</th>
                            <th>Abono a capital</th>
                            <th>Fecha de pago</th>
                            {
                                pageReportMode ?
                                    <>
                                        <th>Uuid</th>
                                        {
                                            hasAuditorAccess ?
                                                <th>Revocar</th> : null
                                        }
                                    </>
                                    :
                                    null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {payments && payments.filter((value) => {
                            if (searchText === "") {
                                return value
                            }
                            else if (value.fullName.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            else if (value.customer_idnumber === parseInt(searchText)) {
                                return value
                            }
                            else if (value.payment_id.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            return null;
                        }).map((payment, i) => (
                            <tr key={i} >
                                <td>{payment.customer_idnumber}                             </td>
                                <td>{payment.fullName}                                      </td>
                                <td>{payment.payment_total}                                 </td>
                                <td>{payment.payment_interest}                              </td>
                                <td>{payment.payment_delay}                                 </td>
                                <td>{payment.payment_capital}                               </td>
                                <td>{format(parseISO(payment.payment_createdate), "dd/MM/yyyy HH:mm:ss")} hrs                            </td>
                                {
                                    pageReportMode ?
                                        <>
                                            <td className="container-flex"><a href={`/TicketPay/${payment.payment_id}`}>{payment.payment_id}</a></td>
                                            <td>
                                                {
                                                    hasAuditorAccess ?
                                                        <Button
                                                            type={"button"}
                                                            nameOfClass={"btn ml-2 btn-warning btn-hover-light icon-m alarm"}
                                                            clickBehaviour={() => selectAction(payment.payment_id, 'RevokePayment')}
                                                            text={""}
                                                            icon={<TiCancel />}
                                                        />
                                                        : null
                                                }
                                            </td>
                                        </>
                                        :
                                        null
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default ListPaymentsBySearch;