import React, { useState, useContext } from "react";
import { NovaContext } from "../../components/context/index";

// React router dom
import { Link } from "react-router-dom";

// Internal import
import { WeekDayDueToPayment } from "../../components/Helper/Enums";
import {
  calculateDelayedWeeks,
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
  calculateRisk,
  formatRiskIcon,
} from "../../components/Helper/LoanHelpers";
import { checkAccess } from "../../components/Helper/GlobalHelpers";

// Material UI import
import Box from "@mui/system/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  CustomDataGrid,
  CustomGridToolbar,
  toolbarTranslations,
} from "../../components/Helper/MUIStyledComponents";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

// Risk factor display
import Critical from "@mui/icons-material/GppBad";
import Alarm from "@mui/icons-material/SafetyCheck";
import Warning from "@mui/icons-material/GppMaybe";
import Normal from "@mui/icons-material/VerifiedUser";

// Date-fns imports
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import LoanList from "../LoanOverview/LoanList";
import MediaGallery from "../../components/MediaGallery";
import ListEmployeeLoansBySearch from "../../components/Actions/ListEmployeeLoansBySearch";
import FilialListRoles from "./FilialListRoles";
import EmployeeDocuments from "./EmployeeDocuments";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      sx={{
        width: "100%",
      }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EmployeeContent = (props) => {
  const {
    employee,
    employeeLoans,
    loansLoaded,
    viewLoanDetails,
    togglePaymentDialog,
    filialList,
    customerAudit,
    auditLoaded,
    employeeMedia,
    mediaLoaded,
    updateMedia,
  } = props;

  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const [selectedTab, setSelectedTab] = useState(0);
  const boxHeight = 650;
  // const boxHeight = "auto";

  const { currentRole } = useContext(NovaContext);
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  // const hideableColumns = [hasAdminAccess ? "" : "loan_employeeid", "status"];

  const hideableColumns = [hasAdminAccess ? "" : "loan_employeeid", "fullName"];

  const visibilityColumns = [
    "accionPago",
    "accionDetalles",
    "customer_idnumber",
    "customer_telephone",
    "customer_location",
    "customer_address",
    "loan_employeeid",
  ];

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const prepareAuditColumns = (auditTrail) => {
    const auditColumns = [
      {
        field: "audittype",
        headerName: "Tipo",
        width: "50",
        hideable: false,
        renderCell: (params) => {
          const auditType =
            params.row.audittype === "CASH" ? "DINERO" : "PROCESO";
          return (
            <Tooltip title={auditType} placement="right" arrow>
              {auditType === "DINERO" ? (
                <MonetizationOnIcon />
              ) : (
                <SettingsIcon />
              )}
            </Tooltip>
          );
        },
      },
      {
        field: "employee_name",
        headerName: "Responsable",
        width: "150",
        renderCell: (params) => {
          return (
            <Link to={`/employees/${params.row.employee}`}>
              {params.row.employee_name}
            </Link>
          );
        },
      },
      {
        field: "activeuserrole",
        headerName: "Rol",
        width: "100",
        renderCell: (params) => {
          let roleName = params.row.activeuserrole;
          switch (roleName) {
            case "Administrator":
              return "Administrador";
            case "Employee":
              return "Empleado";
            case "RegionalManager":
              return "Gerente Regional";
            case "Auditor":
              return "Auditor";
            case "Manager":
              return "Gerente";
            default:
              return roleName;
          }
        },
      },
      {
        field: "filial_name",
        headerName: "Sucursal",
        width: "150",
        renderCell: (params) => {
          return (
            <Link to={`/filials/${params.row.filial}`}>
              {params.row.filial_name}
            </Link>
          );
        },
      },
      {
        field: "description",
        headerName: "Descripción",
        width: "500",
      },
      {
        field: "timestamptz",
        headerName: "Fecha",
        width: "150",
        valueFormatter: (params) =>
          format(parseISO(params?.value), "dd-MM-yyyy hh:mm"),
      },
    ];
    return auditColumns;
  };

  return (
    <Box margin={1} paddingTop={{ xs: 5, md: 0 }}>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
        >
          <Tab label="Créditos" {...a11yProps(0)} />
          <Tab label="Permisos" {...a11yProps(1)} />
          <Tab label="Imágenes" {...a11yProps(2)} />
          <Tab label="Auditoría" {...a11yProps(3)} />
          <Tab label="Documentos" {...a11yProps(4)} />
          <Tab label="Documentos para <insert name here>" {...a11yProps(5)} />
        </Tabs>
      </Box>

      <TabPanel value={selectedTab} label="Créditos" index={0}>
        {/* <Box  height={boxHeight} width="100%"> */}
        <Grid container>
          <Grid size={12} height={boxHeight} width="100%">
            <LoanList
              loans={employeeLoans}
              viewDetails={viewLoanDetails}
              toggleDialog={togglePaymentDialog}
              // hideableColumns={{ employeeId: hasAdminAccess ? true : false }}
              hideableColumns={hideableColumns}
              visibilityColumns={visibilityColumns}
            />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={selectedTab} label="Permisos" index={1}>
        <Box height={boxHeight} width="100%">
          <FilialListRoles objectsArray={filialList} />
        </Box>
      </TabPanel>

      {/* under construction */}
      <TabPanel value={selectedTab} label="Imágenes" index={2}>
        <Box height={boxHeight} width="100%">
          {/* {customerMedia ? (
            <MediaGallery
              mediaItems={customerMedia}
              width={"100%"}
              height={boxHeight}
              columnNumber={5}
              rowHeight={150}
              loadingType={"lazy"}
              updateMedia={updateMedia}
            />
          ) : (
            <Typography>
              No se ha cargado ningún medio. Haga clic aquí para añadir uno.
            </Typography>
          )} */}
        </Box>
      </TabPanel>

      <TabPanel value={selectedTab} label="Auditoría" index={3}>
        <Box height={boxHeight} width="100%">
          {/* <CustomDataGrid
            columns={prepareAuditColumns(customerAudit)}
            rows={customerAudit}
            getRowId={(row) => row.id}
            // density="compact"
            slots={{
              toolbar: hasRegionalManagerAccess ? CustomGridToolbar : null,
            }}
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            filterModel={filterModel}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // columnXTohide: hasAdminAccess ? true : false,
                },
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterExcludeHiddenColumns: true,
                },
              },
            }}
          /> */}
        </Box>
      </TabPanel>

      <TabPanel value={selectedTab} label="Documentos" index={4}>
        <Box height={boxHeight} width="100%">
          <EmployeeDocuments
            documentList={employeeMedia?.filter((media) => media.media_type === "DOCUMENT" && media.media_foremployeeid === null)}
            currentRole={currentRole}
            updateMedia={updateMedia}
          />
        </Box>
      </TabPanel>

      {/* <TabPanel value={selectedTab} label={`Documentos para ${employee.employee_fullname}`} index={5}> */}
      <TabPanel value={selectedTab} label={`Documentos para <insert name here>`} index={5}>
        <Box height={boxHeight} width="100%">
          <EmployeeDocuments
            documentList={employeeMedia?.filter((media) => media.media_type === "DOCUMENT" && media.media_foremployeeid !== null)}
            currentRole={currentRole}
            updateMedia={updateMedia}
          />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default EmployeeContent;
