import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// internal misc
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../../components/Helper/MUIStyledComponents";
import { alerts, displayAlert } from "../../../components/Helper/AlertToastify";
import { useLoansContext } from "../../../components/context/LoansContext";
import { getPayments } from "../../../utils/api/api_payments";
import { deleteLoan } from "../../../utils/api/api_loan";
import { LoanActions } from "../../../reducers/LoansReducer";

// components
import DateRange from "../../../components/common/DateRange/DateRange";
import { DateRangeActions } from "../../../components/common/DateRange/DateRangeReducer";
import LoanDetailsDrawer from "../../LoanOverview/LoanDetailsDrawer";
import Confirmation from "../../../components/common/Confirmation";

// helper
import {
  // getLoanVisibleColumns,
  prepareLoanColumns,
} from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Loans";

// mui
import Box from "@mui/material/Box";
import { renderNoRowDataOverlay } from "../../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import CustomLoadingOverlay from "../../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";
import Payment_New from "../../Modal/Payment_New";

const LoansList = ({ columnVisibilityModel }) => {
  const [selectedLoan, setSelectedLoan] = useState("");
  const {
    currentRole,
    loading,
    loansList,
    dateRange,
    dispatchDateRange,
    dispatchLoanList,
    dispatchLoading,
  } = useLoansContext();
  const navigate = useNavigate();

  // modal states
  const [confirmation, setConfirmation] = useState(false);
  const [dialogPayment, setDialogPayment] = useState(false);

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const changeDateRange = (updatedDates) => {
    dispatchDateRange({
      type: DateRangeActions.SET_DATE_RANGE_FILTER,
      payload: updatedDates,
    });
  };

  const removeLoan = async () => {
    await deleteLoan(selectedLoan)
      .then((resp) => {
        if (resp.status === 200) {
          setConfirmation(false);
          setSelectedLoan("");
          // displayAlert(alerts.SUCCESS, "Prestamo eliminado");
          dispatchLoanList({
            type: LoanActions.REMOVE_LOAN,
            payload: selectedLoan,
          });
        } else {
          displayAlert(alerts.ERROR, `${resp.data[0]}`);
        }
      })
      .catch((err) =>
        displayAlert(
          alerts.ERROR,
          `No se ha podido eliminar el prestamo: ${err.data}`
        )
      );
  };

  const handleNavigation = (newPath) => {
    const path = `/${newPath}`;
    navigate(path);
  };

  // drawer data
  const [drawer, setDrawer] = useState(false);
  const [payments, setPayments] = useState([]);

  const handleClickDrawerLoanDetails = async (loan) => {
    await getPayments(`/loan/${loan.loan_id}`)
      .then((resp) => {
        setSelectedLoan(loan);
        setPayments(resp);
        setDrawer(true);
      })
      .catch((err) =>
        displayAlert(alerts.ERROR, `Couldn't retrieve payments: ${err}`)
      );
  };

    // toggle for payment Dialog
    const handleClickPayment = (loan) => {
      setSelectedLoan(loan);
      setDialogPayment(true);
    };

  // const handleNewPaymentClick = async (loan) => {
  //   await getPayments(`/loan/${loan.loan_id}`)
  //     .then((resp) => {
  //       setSelectedLoan(loan);
  //       setPayments(resp);
  //       setDrawer(true);
  //     })
  //     .catch((err) =>
  //       displayAlert(alerts.ERROR, `Couldn't retrieve payments: ${err}`)
  //     );
  // };

  const visibilityModel = columnVisibilityModel(currentRole);

  return (
    <div>
      <Confirmation
        open={confirmation}
        setOpen={setConfirmation}
        textContent={"Está seguro de que quiere revocar este prestamo?"}
        confirmationMethod={removeLoan}
      />
      <Payment_New
              open={dialogPayment}
              toggleDialog={setDialogPayment}
              loan={selectedLoan}
              lockStatus={loading}
              // setLockStatus={setLoading}
            />
      {loading ? (
        "Cargando..."
      ) : (
        <>
          <Box height="85vh" width="100%">
            <CustomDataGrid
              columns={prepareLoanColumns(
                loansList,
                setConfirmation,
                setSelectedLoan,
                handleNavigation,
                handleClickDrawerLoanDetails,
                handleClickPayment
              )}
              rows={loansList}
              getRowId={(row) => row.loan_id} // is this correct 04.10.24
              slots={{
                toolbar: CustomGridToolbar,
                oadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: () =>
                  renderNoRowDataOverlay("No hay créditos en este periodo"),
              }}
              slotProps={{
                toolbar: {
                  component1: (
                    <DateRange
                      dateObject={dateRange}
                      changeMethod={changeDateRange}
                    />
                  ),
                },
              }}
              onFilterModelChange={(newModel) => setFilterModel(newModel)}
              filterModel={filterModel}
              initialState={{
                columns: {
                  columnVisibilityModel: visibilityModel,
                },
                filter: {
                  filterModel: filterModel,
                },
              }}
            />
          </Box>
        </>
      )}
      <LoanDetailsDrawer
        open={drawer}
        toggleDrawer={setDrawer}
        loan={selectedLoan}
        payments={payments}
      />
    </div>
  );
};

export default LoansList;
