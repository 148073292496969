import React, { useEffect } from "react";

import { getPaychecks } from "../../utils/api/api_paychecks";
import { usePaycheckContext } from "../context/PaycheckContext";

// reducers
import { PaycheckActions } from "../../reducers/PaycheckReducer";
import { LoadingActions } from "../../reducers/LoadingReducer";

// misc
import { alerts, displayAlert } from "../Helper/AlertToastify";

const PaycheckController = ({
  sortingFilter,
  customerIdFilter,
  employeeIdFilter,
}) => {
  const {
    filial,
    dateRange,
    dispatchPaycheckList,
    dispatchLoading,
  } = usePaycheckContext();

  const start = dateRange.start.toISOString();
  const end = dateRange.end.toISOString();
  useEffect(() => {
    // Construct the query parameters
    let queryParams = `filial=${filial}`;

    if (start) {
      queryParams += `&start=${start}`;
    }
    if (end) {
      queryParams += `&end=${end}`;
    }
    if (sortingFilter) {
      queryParams += `&sorting=${sortingFilter}`;
    }
    if (employeeIdFilter) {
      queryParams += `&employeeId=${employeeIdFilter}`;
    }
    console.log(queryParams);

    getPaychecks(
      queryParams
      // `filial=${filial}&start=${start}&end=${end}`
    )
      .then((resp) =>
        dispatchPaycheckList({
          type: PaycheckActions.SET_PAYCHECK,
          payload: resp,
        })
      )
      .catch((err) =>
        displayAlert(
          alerts.ERROR,
          `No se han podido cargar las nóminas: ${err}`
        )
      )
      .finally(() =>
        dispatchLoading({ type: LoadingActions.SET_LOADING, payload: false })
      );
  }, [filial, dateRange]);
  return null;
};

export default PaycheckController;
