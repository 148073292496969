// import { getOverlappingDaysInIntervals } from "date-fns";
import React, { useState, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from '../context/index';
import { GenderOptions } from "../Helper/Enums";
import { GrEdit, GrUserAdd } from "react-icons/gr";
import Dropdown from "../Dropdown";
import { checkAccess, getUserRolesOptions } from "../Helper/GlobalHelpers";
import Modal from "./Modal";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

const NewEditEmployeeRole = (props) => {
    const {
        selectedEmployee,
        modalClass,
        buttonClass,
        setLockStatus,
        selectedFilial
    } = props;

    const { actions, currentRole } = useContext(NovaContext);
    const [display, toggleDisplay] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [userRole, setUserRole] = useState('0');

    const isNewMode = !selectedEmployee;
    const hasAdminAccess = checkAccess(currentRole,'Administrador');

    const initializeFormEmployee = (Employee) => {
        console.log(Employee)
        setUserRole(Employee ? (Employee.worksin_roleid ? Employee.worksin_roleid : '') : '');
    };

    const mapUserRoles = (data) => {
        const userRoleOptions = getUserRolesOptions(data,currentRole);
        setUserRoles(userRoleOptions);
    }

    const handleChange = (event, section) => {
        const value = event.target.value;
        switch (section) {
            case 'userRole': setUserRole(value); break;
            default: break;
        }
    };

    const handleClick = () => {
        console.log(isNewMode, selectedEmployee)
        if (!isNewMode) {
            actions.getEmployee(selectedEmployee.employee_id).then((data) => {
                initializeFormEmployee(data)
            })
        }
        //TBD - change it to have the UserRoles as Constants or Env-variables
        actions.getUserRoles().then((data) => mapUserRoles(data));
        toggleDisplay(!display);
    }

    const closeModalView = () => {
        clearFields();
        toggleDisplay();
    }

    const clearFields = () => {
        console.log('reseting form')
        initializeFormEmployee();
    }

    const handleCreate = async (e) => {
        e.preventDefault();
        setLockStatus(true);

        const tempEmployeeWorksIn =
        {
            "employeeId": selectedEmployee.employee_id,
            "roleId": userRole,
            "filialId": selectedFilial
            // "worksin_id": selectedEmployee.worksin_id
        }
        console.log(tempEmployeeWorksIn)

        const updatedEmployee = await actions.addWorksIn(tempEmployeeWorksIn);
        // console.log('updating employee:' updatedEmployee)

        if (updatedEmployee) {
            closeModalView();
            window.location.reload();
        } else {
            console.log('deleteEmployee failed');
        }

        setLockStatus(false);
    }

    return (
        (hasAdminAccess) ?
            <form onSubmit={handleCreate}>
                <Modal
                    classNames={`${modalClass} ${display ? 'show' : ''}`}
                    toggleModal={handleClick}
                    buttonName={<AiOutlineUsergroupAdd className="icon-small good" />}
                    // { isNewMode ? 'Empleado' : null}
                    buttonClass={`${buttonClass ? buttonClass : 'btn btn-primary btn-icon w-auto btn-clean'}`}
                    header={'Añadir Rol'}
                    body={() => (
                        <Fragment>
                            <div className="grid-container-form">
                                <Dropdown className="btn btn-outline-secondary align-left" label="Rol " options={userRoles} value={userRole} onChange={(e) => handleChange(e, 'userRole')} />
                            </div>
                        </Fragment>
                    )}
                    footer={() => (
                        <Fragment>
                            <button type="submit" className="btn btn-success" data-dismiss="modal" > Continuar </button>
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => closeModalView()} > Cancelar </button>
                        </Fragment>
                    )}
                />
            </form >
            :
            null
    )
};

export default NewEditEmployeeRole;