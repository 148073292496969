import { Button } from '@mui/material';
import React, { Fragment, useState } from 'react';

const Modal = ( props ) => {

    const {
        toggleModal,
        classNames,
        buttonName,
        buttonClass,
        header,
        // target,
        submit,
        body,
        footer
    } = props;

    // const [innerToggle, setInnerToggle] = useState(toggle);

    const handleSubmit = () => {
        console.log("I've been clicked");
        // submit();
    }

  return (
    <Fragment>
        <Button 
            type="button" 
            variant="contained"
            // className={buttonClass}
            onClick={() => toggleModal()}
        >
            {buttonName}
        </Button>

        {/* <div className={`modal ${innerToggle ? "show" : ""}`}> */}
        <div className={`modal ${classNames}`}>
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">{header}</h4>
                            <button type="button" 
                                    className="close" 
                                    data-dismiss="modal" 
                                    onClick={() => toggleModal()}>&times;
                            </button>
                    </div>

                    <div className="modal-body">
                        {body()}
                    </div>

                    <div className="modal-footer">
                        {footer()}
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default Modal;