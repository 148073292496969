import axios from 'axios';
// import { ServerURI, ServerPORT } from '../../config/uri';

/** 
 * Generalise the outgoing call 
 * @param {string} path to api
 * @param {string} method GET is default
 * @param {string} body payload of request
 * @param {boolean} requiresAuth whether authentication is required 
 * @param {string} credentials data necessary for authentification
 * @return Returns the response from authentication/normal server  
*/

export default class API {

        async call(
            path, 
            method = 'GET', 
            body, 
            requiresAuth = false, 
            credentials = null, 
            extraHeaders) {
        
        if ( requiresAuth ) {
            axios.defaults.headers.authorization = `Bearer ${credentials}`;
        }

        // let config = {
        //     headers: {
        //         extraHeaders
        //     }
        // };

        switch(method) {
            case "GET":
                return await axios.get(path, null, `bearer ${credentials}` );
                // return await axios.get(path, null);
            case "POST":
                // return await axios.post(path, body, { headers: {'Content-Type': 'multipart/form.data'} });
                if ( extraHeaders != null ) {
                    return await axios.post(path, body, { headers: {'Content-Type': 'multipart/form.data'} });
                } else {
                    return await axios.post(path, body);
                }
            case "PUT":
                return await axios.put(path, body);
            case "DELETE":
                return await axios.delete(path, null);
            default :
                return null; // TBD - what should be the default case?


        }
    }
}