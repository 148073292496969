import React, { Fragment, useState, useContext, useEffect } from "react";
import { NovaContext } from '../components/context';
import { calculateRangeDates, changeDateReference, checkMinAccess, filterFilialsWithAccess, noAccessMessage } from "../components/Helper/GlobalHelpers";
import Loading from "../components/Actions/Loading";
import { format } from "date-fns";
import TableReport from "../components/TableReport";
import Dropdown from "../components/Dropdown";
import { ReportMode } from "../components/Helper/Enums";

const PayRollMultiFilial = () => {
    const { actions, filials } = useContext(NovaContext);
    const hasMinRegionalManagerAccess = checkMinAccess(filials, 'Gerente Regional');
    const hasMinAuditorAccess = checkMinAccess(filials, 'Auditor');

    const [loading, setLoading] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [dateReference, setDateReference] = useState();
    const [mode, setMode] = useState('WEEK');
    const [range, setRange] = useState({});

    const [tableValues, setTableValues] = useState([]);
    const contentPosition = { headerStyle: 'text-center', firstColumnStyle: 'text-left', otherColumnsStyle: 'text-center table-column-max-width-200' }
    const headers = ['Descripción', 'Cantidad [$]']

    useEffect(() => {
        if (hasMinRegionalManagerAccess || hasMinAuditorAccess) {
            setLoading(true);
            const referenceDate = changeDateReference(null,mode,dateReference);
            setCurrentDateTime(format(referenceDate, "dd/MM/yyyy HH:mm:ss") + ' hrs');
            const datesRange = calculateRangeDates(mode, referenceDate)
            // console.log('Dates:',datesRange);
            setRange(datesRange);
            const tempFilials = [...filials];
            filterFilialsWithAccess(tempFilials);
            getFilialPayChecks(tempFilials,datesRange.startDate) // also setLoading(false) inside
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const getFilialPayChecks = async (filialsList,startDate) => {
        var title, description, amount, table = [], tableData = [], rowData = [], totalsData = [];
        var tempArray, tempRowData, tempSum = 0;

        for (var i = 0; i < filialsList.length; i++) {
            title = filialsList[i].filial_name;
            tempArray = await actions.getPayChecks(filialsList[i].filial_id,startDate);

            for (var j = 0; j < tempArray.length; j++) {
                description = tempArray[j].description
                amount = tempArray[j].amount
                tempSum += amount;
                tempRowData = {
                    description,
                    amount
                }
                Object.values(tempRowData).forEach(val => {
                    rowData.push({ val })
                });
                tableData.push({ rowData })
                rowData = [];
            }
            totalsData.push({ value: tempSum })
            table.push({ title, tableData, totalsData })
            totalsData = [];
            tableData = [];
            tempSum = 0;
        }
        setTableValues(table);
        setLoading(false);
    }

    const handleChange = (event) => {
        const value = event.target.value;
        const currentDate = dateReference;
        // console.log(value)
        setMode(value);
        setRange(calculateRangeDates(value, currentDate));
    }

    return (
        <Fragment>
            <div className="page-container">
                <div className={`over-page-loading ${loading ? 'show-loading' : 'hide-loading'}`}>
                    <div className="over-page-loading-content">
                        <Loading
                            text={'Cargando...'}
                            classNames={`over-page-loading ${loading ? 'show-loading' : 'hide-loading'}`}
                        />
                    </div>
                </div>

                {loading ?
                    <div>Cargando...</div>
                    :
                    !(hasMinRegionalManagerAccess || hasMinAuditorAccess) ?
                        <div>
                            <div className="temp-header-space"></div>
                            <div className="filial-missing">
                                <h1 className="text-center ">{noAccessMessage()}</h1>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="subheader py-2 subheader-transparent" id="kt_subheader">
                                <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                    <div className="d-flex align-items-center flex-wrap mr-1">
                                        <div className="d-flex flex-column">
                                            <span>
                                                <h2 className="text-black font-weight-bold my-2 mr-5">Nóminas </h2>
                                                <h6 className="text-black">{`Gastos al día:  ${currentDateTime}`}</h6>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h6 className="text-black mr-3">{`Rango  ${range.startDay}-${range.endDay}`}</h6>
                                        <Dropdown className="btn btn-outline-info align-left mr-3"
                                            options={ReportMode} value={mode}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column-fluid">
                                <div className="container">
                                    <div className="row">
                                        {tableValues && tableValues.map((dataBlock, i) => (
                                            <div key={i} className="col-xl-3 mb-5">
                                                <div className="card card-custom  card-stretch">
                                                    <div className="card-body-sm-padding">
                                                        <div className="border-0 pt-5">
                                                            <div className="card-title">
                                                                <div className="card-label">
                                                                    <div className="font-weight-bolder text-center font-size-h2">{dataBlock.title}</div>
                                                                    <TableReport
                                                                        hideColumn={false}
                                                                        headersList={headers}
                                                                        dataList={dataBlock.tableData}
                                                                        totalList={dataBlock.totalsData}
                                                                        contentPosition={contentPosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </Fragment>
    );
};

export default PayRollMultiFilial;
