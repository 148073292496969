import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

function Clock() {
  const [clockState, setClockState] = useState();

  useEffect(() => {
    let interval = setInterval(() => {
      const date = new Date();
      setClockState(date.toLocaleTimeString());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box sx={{ fontSize: {
      xs: "1.2em",  // Small to medium devices
      //md: "1.5em",  // Medium devices
      lg: "1.8em",  // Large devices
    }, fontWeight: "bold", m: 0.3 }}>
      {clockState}
    </Box>
    //<div>{clockState}</div>;
  );
}

export default Clock;
