import React, { Fragment, useState, useContext, useEffect } from "react";
import ListPaymentsBySearch from "../components/Actions/ListPaymentsBySearch";
import { NovaContext } from '../components/context';

const Payments = () => {

    // Import Context-API‚
    const { actions, filial } = useContext( NovaContext );
    const [payments, setPayments] = useState([]);


    useEffect(() => {
        actions.getPayments().then((data) => setPayments(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="page-container">
                {!filial || filial === "0" ?
                    <div>
                        <div className="temp-header-space"></div>
                        <div className="filial-missing">
                            <h1 className="text-center ">Selecciona una Sucursal primero</h1>
                        </div>
                    </div>
                :
                        <ListPaymentsBySearch payments={ payments }  pageReportMode={false}/>
                }
            </div>
        </Fragment>
    );
};

export default Payments;
