import React from 'react';
import { Button } from "@mui/material";

const useStyles = () => ({
    root: {
        margin: "0.5rem"
    },
    label: {
        textTransform: 'none'
    }
});

export default function CustomButton(props) {
    const { text, size, color, variant, onClick, ...other } = props;
    const classes = useStyles();

    return (
        <Button
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}
            sx={{ ...classes.root, ...classes.label }}
            {...other}>
            {text}
        </Button>
    );
}
