import { MEDIA as media } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";
import { parseApiError } from "./api_errorhandler";

const sendRequest = new SendRequest();
// const endpoint = `${ServerURI}:${ServerPORT}/${media}`;
const endpoint = "http://localhost:5000/media";
const accessToken = getCookie(NOVACOOKIE);

export const getMedia = async (params) => {
    return await
        sendRequest.request(
            `${endpoint}?${params}`,
            GET,
            null,
            accessToken,
            null
        )
            .then(resp => resp.data)
            .catch(err => err)
    // ideal solution - ToDo - look at structure in parseApiError
    // .catch(err => parseApiError(err))
}

export const createMedia = async (params, media) => {
    const formData = new FormData();
    if (params === "documents") {
        formData.append("document", media.media);
        formData.append("otherinfo", media.otherinfo);
    } else {
        formData.append("image", media.media);
        formData.append("otherinfo", media.otherinfo);
    }

    // temporary code to phase out original image workflow
    // ToDo: remove v1 in backend after feature is stable across all uses
    if (params === "images") {
        params = `v2/${params}`;
    }

    return await
        sendRequest.request(
            `${endpoint}/${params}`,
            POST,
            formData,
            accessToken,
            "multipart/form.data"
        )
            .then(resp => resp.data)
            .catch(err => err)
}

export const deleteMedia = async (params) => {
    return await
        sendRequest.request(
            `${endpoint}?${params}`,
            DELETE,
            null,
            accessToken,
            null
        )
            .then(resp => resp.data)
            .catch(err => ({ "code": 401, "text": err }))
}