import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { noAccessMessage } from "../../components/Helper/GlobalHelpers";
import FilialList from "./FilialList";
import Filial_New from "../Modal/Filial_New";
import Cluster_NewEdit from "../Modal/Cluster_NewEdit";

const Index = () => {
  const { actions, filial, currentRole } = useContext(NovaContext);
  const [clusters, setClusters] = useState([]);
  const [filials, setFilials] = useState([]);
  const [totalFilteredFilials, setTotalFilteredFilials] = useState(0);

  useEffect(() => {
    actions.getClusterList().then((data) => setClusters(data));
    actions.getFilials().then((data) => {
      setFilials(data);
      setTotalFilteredFilials(data.length);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ padding: {xs: "5px 10px 5px 10px", md: "75px 25px 0 25px"}}}>
      {!(currentRole === "Root") ? (
        <div>
          <div className="temp-header-space"></div>
          <div className="filial-missing">
            <h1 className="text-center ">{noAccessMessage()}</h1>
          </div>
        </div>
      ) : (
        <div>
          <Typography
            variant="h3"
            component="h3"
            display="flex"
            justifyContent="center"
            sx={{ textAlign: "center", mt: 1, mb: 2 }}
          >
            Company Lanita ({totalFilteredFilials})
          </Typography>

          {clusters.map((cluster, index) => {
            const filteredFilials = filials.filter(
              (filial) => filial.filial_cluster === cluster.id
            );

            return (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  sx={{ p: 0, mb: 0 }}
                >
                  <Typography sx={{fontWeight: "bold", fontSize: 20, pl: 2 }}>
                    {cluster.name} ({filteredFilials.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {filteredFilials.length === 0 ? (
                    <Typography>
                      Este Grupo no tiene ninguna Sucursal.
                    </Typography>
                  ) : (
                    <FilialList
                      objectsArray={filteredFilials}
                      cluster={cluster.id}
                    />
                  )}
                  <Filial_New cluster={cluster.id} />
                </AccordionDetails>
              </Accordion>
            );
          })}
          <Cluster_NewEdit />
        </div>
      )}
    </Box>
  );
};
export default Index;
