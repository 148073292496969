import React from "react";
import {
  PaymentsProvider,
  usePaymentsContext,
} from "../context/PaymentsContext";

// component
import PaymentsList from "../../pages/PaymentsOverview/components/PaymentsList";

const PaymentsListWrapper = ({ columnVisibilityModel }) => {
  return (
    <PaymentsProvider>
      <PaymentsList columnVisibilityModel={columnVisibilityModel} />
    </PaymentsProvider>
  );
};

export default PaymentsListWrapper;
