import { format, parseISO } from "date-fns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NovaContext } from "../components/context/index";
import {
  calculateDelayedWeeks,
  calculateOpenAmountTotal,
  calculateOpenInterest,
} from "../components/Helper/LoanHelpers";
import LogoLanita from "../images/LOGO LANITA SOLIDARIA NEGRO.png";
import { Box, Typography } from "@mui/material";

const Ticket2 = () => {
  const { actions, filial } = useContext(NovaContext);
  const params = useParams();
  const PaymentId = params.id;
  // console.log(PaymentId);
  const [createDate, setCreateDate] = useState();
  const [DueDate, setDueDate] = useState();
  const [payment, setPayment] = useState([]);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [currentFilial, setFilial] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
    actions.getPayment(PaymentId).then((data) => {
      console.log(data);
      
      setPayment(data);
      setCreateDate(
        format(parseISO(data.payment_createdate), "dd/MM/yyyy HH:mm:ss")
      );
      setDueDate(format(parseISO(data.loan_datedue), "dd/MM/yyyy "));

      const tempName = data.customer_name.toUpperCase();
      const tempLastName = data.customer_lastname.toUpperCase();
      setName(tempName);
      setLastName(tempLastName);
    });
    actions.getFilial(filial).then((data) => setFilial(data));

    setLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
    setLoading(false);
  }
}
  fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PaymentId]);


  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (payment && payment != [] ){
  return (
    <Fragment>
      <Box>
      <div className="ticket-page">
          <div className="mt-5">
            <div className="text-left text-dark p-7 position-relative">
              <h6 className="text-center">Tel.- {currentFilial.telephone}</h6>
              <div className="text-center mb-2">
                <a href="http://lanitasolidaria.com:3000">
                  {/* <img src={LogoLanita} className="max-h-100px" alt=""></img> */}
                  <div className="custom-background-svg-black"></div>
                  {/* <div className="custom-background-svg-black-christmas"></div> */}
                  {/* <div className="custom-background-svg-black-valentin"></div> */}
                </a>
              </div>
              <h6 className="mb-5 text-center">
                {" "}
                ¡UN CRÉDITO NUNCA HABIA SIDO TAN FACIL!
              </h6>
              <h2 className="mb-6 text-center"> RECIBO DE PAGO</h2>
              <h6 className="mb-6"> FOLIO.-{payment.payment_id}</h6>
              <h5 className="">
                {" "}
                NOMBRE CLIENTE:{" "}
                <span className="text-underline-style">
                  {name} {lastName}
                </span>{" "}
              </h5>
              <hr className="my-8"></hr>
              <h5 className="mb-6">
                {" "}
                FECHA DE PAGO: <span>{createDate}</span>{" "}
              </h5>
              <h3 className="mb-6 text-center"> MONTO PAGADO</h3>
              <h4 className="mb-3">
                {" "}
                REFRENDO:{" "}
                <span className="text-underline-style">
                  ${payment.payment_interest}
                </span>{" "}
              </h4>
              <h4 className="mb-3">
                {" "}
                MORA:{" "}
                <span className="text-underline-style">
                  ${payment.payment_delay}
                </span>{" "}
              </h4>
              <h4 className="mb-3">
                {" "}
                CAPITAL:{" "}
                <span className="text-underline-style">
                  ${payment.payment_capital}
                </span>{" "}
              </h4>
              <h4 className="mb-3 text-underline-style">
                {" "}
                TOTAL:{" "}
                <span className="text-underline-style">
                  ${payment.payment_total}
                </span>{" "}
              </h4>
              <hr className="my-8"></hr>
              <h3 className="mb-6 text-center"> PROXIMO PAGO</h3>
              <h4 className="mb-3">
                {" "}
                FECHA: <span className="text-underline-style">
                  {DueDate}
                </span>{" "}
              </h4>
              <h4 className="mb-3">
                {" "}
                CAPITAL:{" "}
                <span className="text-underline-style">
                  ${payment.loan_remaining}
                </span>{" "}
              </h4>
              <h4 className="mb-3">
                {" "}
                REFRENDO:{" "}
                <span className="text-underline-style">
                  ${calculateOpenInterest(payment)}
                </span>{" "}
              </h4>
              <h4 className="mb-3">
                {" "}
                TOTAL ADEUDO:{" "}
                <span className="text-underline-style">
                  ${calculateOpenAmountTotal(payment)}
                </span>{" "}
              </h4>
              <h4 className="mb-3">
                {" "}
                SEMANAS DE ATRASO:{" "}
                <span className="text-underline-style">
                  {calculateDelayedWeeks(payment)}
                </span>{" "}
              </h4>
              <hr className="my-8"></hr>
              <h6 className="mb-2"> DUDAS O ACLARACIONES:</h6>
              <h6 className="mb-1"> Tel.- {currentFilial.telephone}</h6>
              <h6 className="mb-5"> FB: LANITA SOLIDARIA</h6>
              <h4 className="">
                {" "}
                *LA FALTA DE PAGO EN OFICINA O BIEN PAGO CON ATRASO (DESPUES DE
                LA 1PM), GENERARÁ UN CARGO MORATORIO EN SU PAGO EL CUAL ES
                EQUIVALENTE A 20 PESOS POR DÍA*
              </h4>
              <h2 className="text-center">
                {" "}
                <span className="text-underline-style">¡RECUERDE PAGAR</span>
              </h2>
              <h2 className="mb-8 text-center">
                {" "}
                <span className="text-underline-style">PUNTUALMENTE!</span>
              </h2>
              <h4 className="mb-5 text-center">
                {" "}
                *ESTE NO ES UN COMPROBANTE FISCAL*
              </h4>
            </div>
          </div>
        </div>
      </Box>
    </Fragment>
  );
}

return <Typography variant="h6">Data not available</Typography>;
};

export default Ticket2;
