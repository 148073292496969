import React from 'react'

const Button = ( props ) => {

    const {
        type,
        nameOfClass,
        clickBehaviour,
        text,
        icon
    } = props;
    
    const handleClick = () => {
        clickBehaviour();
    };
    
    return (
        <button type={`${type}`} 
                className={`${nameOfClass}`} 
                data-toggle="modal"
                onClick={handleClick}
        >
            {
                icon ? icon : '' 
            }
            {text}
        </button>
    )
}

export default Button;
