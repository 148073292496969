import React, { useContext, useEffect } from "react";
import { menuData } from "./MenuData";
import CustomNavLink from "./NavLinkCustom";

// internal import
import { NovaContext } from "../context/index";

// Material ui import
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { renderIcon } from "../Helper/GlobalHelpers";
import { useTheme } from "@mui/material/styles";

const NavBarMenuList = () => {
  const { authenticatedUser } = useContext(NovaContext);

  const theme = useTheme();
  useEffect(() => {}, []);

  return authenticatedUser ? (
    <MenuList
      autoFocus={false}
      autoFocusItem={false}
      sx={{
        display: "flex",
        alignItems: { xs: "left", md: "center" },
        flexDirection: { xs: "column", md: "row" },
      }}
      disablePadding
    >
      {menuData.map((item) => (
        <CustomNavLink
          to={item.link}
          key={item.link}
          style={{ textDecoration: "none", ml: { xs: 0, md: 1 } }}
          activeStyle={{ backgroundColor: theme.palette.secondary.main, borderRadius: "8px" }}
        >
          <MenuItem
            sx={{
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
          >
            <ListItemIcon sx={{ color: { xs: "black", md: "white" } }}>
              {renderIcon(item)}
            </ListItemIcon>
            <Typography
              sx={{
                color: { xs: "black", md: "white" },
                fontSize: { xs: 12, md: 15 },
              }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        </CustomNavLink>
      ))}
    </MenuList>
  ) : null;
};

export default NavBarMenuList;

{
  /* <CustomNavLink
          to={item.link}
          key={item.link}
          style={{ textDecoration: "none", borderRadius: "8px", ml: { xs:0, md: 1 }}}
          activeStyle={{ backgroundColor: theme.palette.secondary.main }}
          sx={{'&:hover': {
            backgroundColor: theme.palette.secondary.light,
          },}}
        >
          <MenuItem>
            <ListItemIcon sx = {{color:  {xs: "black", md: "white"}}}>{renderIcon(item)}</ListItemIcon>
            <Typography sx = {{color:  {xs: "black", md: "white"},fontSize: { xs: 12, md: 15 },}}>{item.title}</Typography>
          </MenuItem>
        </CustomNavLink> */
}
