import React, { useMemo } from "react";
import { Grid2 as Grid, Button, Box, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

// import EditIcon from '@mui/icons-material/Edit';
import {} from "@mui/x-data-grid";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";

const boxHeight = 650;

const FilialListRoles = (props) => {
  const {
    objectsArray,
    // handleNewClick,
  } = props;

  const columns = useMemo(
    () => [
      {
        field: "filial_name",
        headerName: "Nombre",
        flex: 1,
        minWidth: 150,
        // maxWidth: 300,
      },
      {
        field: "role_name",
        headerName: "Permiso",
        flex: 1,
        minWidth: 150,
        // maxWidth: 300,
      },
      {
        field: "actions",
        type: "actions",
        width: 100,
        renderCell: (params) => [],
      },
    ],
    []
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflowX: "auto", // Ensure horizontal overflow is handled
      }}
    >
      <Box height={boxHeight} width="100%">
        <CustomDataGrid
          slots={{
            toolbar: CustomGridToolbar,
          }}
          rows={objectsArray}
          columns={columns}
          getRowId={(row) => uuidv4()}
        />
      </Box>
    </Box>
  );
};

export default FilialListRoles;
