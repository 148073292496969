// Utility import
import React, { Fragment, useState, useContext } from "react";
import { NovaContext } from "./context";
import { Link } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

// Component import
const TableRiskWithCustomers = ({
  hideColumn,
  dataList,
  headersList,
  totalList,
  contentPosition,
  clickAction,
}) => {
  // console.log('Table:', dataList)
  return (
    <Fragment>
      <div className="table-responsive">
        <table className="table table-head-custom table-head-report table-head-bg table-vertical-top table-bordered table-hover table-sm">
          <thead>
            <tr>
              {headersList &&
                headersList
                  .filter((val) => {
                    return val;
                  })
                  .map((header, i) => (
                    <th
                      key={`${header}-${i}`}
                      className={`${
                        hideColumn && i === 0 ? "desktop-no-show" : ""
                      } ${contentPosition.headerStyle}`}
                    >
                      {header}
                    </th>
                  ))}
            </tr>
          </thead>
          <tbody>
            {dataList &&
              dataList
                .filter((val) => {
                  return val;
                })
                .map((data, i) => (
                  <tr
                    key={i}
                    className={`${
                      data.rowHide ? "desktop-no-show" : ""
                    } ${
                        i === 0  ? contentPosition.firstColumnStyle : ""
                      }`}
                  >
                    {data &&
                      data.rowData.map((inner, y) => (
                        <td
                          key={y}
                          className={`${
                            hideColumn && y === 0 ? "desktop-no-show" : ""
                          } `}
                        >
                          {typeof inner.val === "object"
                            ? inner.val.map((item, j) => (
                                <Link
                                  component="button"
                                  variant="body2"
                                  className='TableElementLongText'
                                  onClick={() => { clickAction(item.Id) }}
                                >
                                  {item.Name}
                                </Link>
                                // <button onClick={() => { clickAction(item.Id) }}>{item.Name} </button>
                              ))
                            : inner.val}
                        </td>
                      ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default TableRiskWithCustomers;
