import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = ( props ) => {

    const { context } = props;

  let navigate = useNavigate();

  useEffect(() => {
    context.actions.logout();
    console.log('closing session');
    navigate('login');
    // window.location.reload();
  }, [context, navigate]);

    // context.actions.logout();
    // console.log('closing session');
    
    // navigate('login');
    // // window.location.reload();
  return (null);
}

export default Logout;