import React from "react";
import { PaycheckProvider } from "../context/PaycheckContext";

// component
import PaycheckList from "../../pages/PaychecksOverview/components/PaycheckList";

const PaycheckListWrapper = ({
  columnVisibilityModel,
  sortingFilter,
  customerId,
  employeeId,
}) => {
  return (
    <PaycheckProvider
    // sortingFilter={sortingFilter}
    // customerIdFilter={customerId}
    // employeeIdFilter={employeeId}
    >
      <PaycheckList columnVisibilityModel={columnVisibilityModel} />
    </PaycheckProvider>
  );
};

export default PaycheckListWrapper;
