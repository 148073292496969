import React, { useState, useContext } from "react";
import { NovaContext } from "../../components/context/index";
import { v4 as uuidv4 } from "uuid";

// Internal import
import { calculateOpenArrear } from "../../components/Helper/LoanHelpers";

// Material UI import
import Box from "@mui/system/Box";
import { DataGrid } from "@mui/x-data-grid";
import {
  CustomDataGrid,
  CustomGridToolbar,
  toolbarTranslations,
} from "../../components/Helper/MUIStyledComponents";

//Icons-Material
import {
  AiFillInfoCircle,
  AiOutlineCheckCircle,
  AiOutlineWarning,
} from "react-icons/ai";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Date-fns imports
import { useNavigate } from "react-router-dom";
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  getCustomerStatusIcon,
  getValueFromEnum,
} from "../../components/Helper/GlobalHelpers";
import NewEditCustomer from "../../components/popups/NewEditCustomer";
import { CustomerStatus } from "../../components/Helper/Enums";

const EmployeeListAll = (props) => {
  const { employees, setLockStatus } = props;
  const { currentRole } = useContext(NovaContext);
  //   const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  //   const hasAdminAccess = checkAccess(currentRole, "Administrador");
  let navigate = useNavigate();

  if (employees) {
    console.log(employees);
    employees.map((item) => {
      item.fullName = item.employee_name + " " + item.employee_lastname;
    });
  }

  const [filterModel, setFilterModel] = React.useState({
    // items: [{ columnField: "state", operatorValue: "contains", value: "blo" }],
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const boxHeight = 1200;

  const handleView = (employeeId) => {
    const path = `/Employees/${employeeId}`;
    navigate(path, { replace: true });
  };

  const prepareColumns = (dataArray) => {
    const columns = [
      {
        field: "accion",
        headerName: "",
        sortable: false,
        width: 60,
        hideable: false,
        filterable: false,
        disableExport: true,
        renderCell: (params) => {
          return (
            <Box display="flex" alignItems={"center"}>
              <Tooltip
                id="button-employee-details"
                title="Ver detalles del empleado."
              >
                <IconButton
                  // autoFocus
                  color="primary"
                  variant="contained"
                  size="large"
                  data-target={`#id${params?.row.employee_id}`}
                  onClick={() => handleView(params?.row.employee_id)}
                >
                  {""}
                  <OpenInNewIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: "employee_name",
        headerName: "Nombre",
        headerClassName: "GridHeader",
        align: "left",
        flex: 1,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        field: "employee_lastname",
        headerName: "Apellido",
        headerClassName: "GridHeader",
        align: "left",
        flex: 1,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        field: "employee_email",
        headerName: "Correo",
        headerClassName: "GridHeader",
        align: "left",
        flex: 1,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        field: "employee_telephone",
        headerName: "Telefono",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 110,
        maxWidth: 130,
      },
      {
        field: "rolesAndFilials",
        headerName: "Roles and Filials",
        headerClassName: "GridHeader",
        align: "left",
        flex: 1,
        minWidth: 200,

        renderCell: (params) => (
          <ul>
            {params.value.map((item, index) => (
              <li key={index}>
                {item.filial_name} - {item.role_name}
                {/* <button onclick="removeItem(this)">Remove</button> */}
              </li>
            ))}
          </ul>
        ),
        // filterable: true,
        // filterModel: {
        //   items: [
        //     {
        //       columnField: "rolesAndFilials",
        //       operatorValue: "contains",
        //       value: "",
        //     },
        //   ],
        // },
        // filterFunction: (value, filterValue) => {
        //   return value.some(
        //     (item) =>
        //       item.role_name
        //         .toLowerCase()
        //         .includes(filterValue.toLowerCase()) ||
        //       item.filial_name.toLowerCase().includes(filterValue.toLowerCase())
        //   );
        // },
      },
    ];
    return [...columns];
  };

  const prepareRows = (dataArray) => {
    return dataArray && dataArray[0]
      ? dataArray?.map((item) => {
          return {
            ...item,
          };
        })
      : [];
  };

  return (
    <Box height="85vh" width="100%">
      <CustomDataGrid
        columns={prepareColumns(employees)}
        rows={prepareRows(employees)}
        getRowId={() => uuidv4()}
        slots={{
          toolbar: CustomGridToolbar,
        }}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        filterModel={filterModel}
        initialState={{
          filter: {
            filterModel: filterModel,
          },
        }}
      />
    </Box>
  );
};

export default EmployeeListAll;
