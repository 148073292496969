// Utility import
import React, { Fragment, useState, useContext } from "react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { RiDeleteBin2Line } from "react-icons/ri";
import { NovaContext } from "./context";
import { canEditDeleteEmployee } from "./Helper/GlobalHelpers";
import NewEditEmployee from "./popups/NewEditEmployee";
import NewEditEmployeeRole from "./popups/NewEditEmployeeRole";


// Component import
const TableEmployees = (props) => {

    const {
        dataList,
        headersList,
        setLockStatus
    } = props;
    const { actions, currentRole } = useContext(NovaContext);

    const handleDeleteRole = async (worksInId, filialId, employeeId) => {
        const body = { worksInId, filialId, employeeId };
        const roleDeleted = await actions.deleteWorksIn(body);
        if (roleDeleted === true) {
            window.location.reload();
        } else {
            console.log('deleteEmployee failed');
        }
    };

    const handleCreateNewRole = async (roleId, filialId, employeeId) => {
        const body = { roleId, filialId, employeeId };
        const roleCreated = await actions.addWorksIn(body);
        if (roleCreated === true) {
            window.location.reload();
        } else {
            console.log('deleteEmployee failed');
        }
    };

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-vertical-center table-bordered table-hover table-sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            {headersList && headersList.filter((val) => { return val })
                                .map((header, i) => (
                                    <th key={i} className="text-center">
                                        {header.filial_name}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList && dataList.filter((val) => {
                            if (currentRole !== 'Root' && val.employeeObj.role_name === 'Root') {
                                return null
                            }
                            return val
                        })
                            .map((data, i) => (
                                <tr key={i} >
                                    {/* {console.log(data)} */}
                                    <td className="container-flex">
                                        {data.employeeObj.employee_name}
                                        {/* {canEditDeleteEmployee(currentRole, data.employeeObj.role_name) ?
                                            <NewEditEmployee
                                                selectedEmployee={data.employeeObj}
                                                setLockStatus={setLockStatus}
                                                buttonClass={'btn btn-icon w-auto btn-clean"'}
                                            />
                                            :
                                            null
                                        } */}
                                    </td>

                                    {/* {console.log('data', data)} */}
                                    {data && data.rightsRow.map((inner, y) => (
                                        <td key={y} className="text-center">
                                            {inner.roleName !== null ?
                                                <>
                                                    <span >{inner.roleName}</span>
                                                    {
                                                        canEditDeleteEmployee(currentRole, inner.roleName) ?
                                                            < button
                                                                className="btn btn-icon w-auto btn-clean"
                                                                onClick={() => {
                                                                    handleDeleteRole(inner.roleId, inner.filialId, data.employeeObj.employee_id)
                                                                }}
                                                            ><RiDeleteBin2Line className="icon-m alarm" s />
                                                            </button>
                                                            : null
                                                    }
                                                </>
                                                :
                                                // <button className="btn btn-icon w-auto btn-clean"
                                                // onClick={() => {
                                                //     console.log('Button Clicked:', inner.roleName,data);
                                                // }}
                                                // ><AiOutlineUsergroupAdd className="icon-small good" /></button>
                                                <NewEditEmployeeRole
                                                    selectedEmployee={data.employeeObj}
                                                    setLockStatus={setLockStatus}
                                                    selectedFilial={inner.filialId}
                                                    buttonClass={'btn btn-icon w-auto btn-clean"'}
                                                />
                                            }
                                        </td>
                                    ))}

                                </tr>
                            ))}



                    </tbody>
                </table>
            </div>
        </Fragment >
    );
};

export default TableEmployees;