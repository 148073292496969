import React, { createContext, useContext, useState, useReducer } from "react";
import { NovaContext } from ".";

//internal
import LoansController from "../controllers/LoansController";
import { LoanReducer } from "../../reducers/LoansReducer";

import { DateRangeReducer } from "../common/DateRange/DateRangeReducer";
import { LoadingReducer } from "../../reducers/LoadingReducer";

// misc
import subMonths from "date-fns/subMonths";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

//States
const initialLoanState = [];
const initialDateRangeState = {
  start: startOfDay(subMonths(new Date(), 2)),
  end: endOfDay(new Date()),
};
const initialLoadingReducer = true;

const LoansContext = createContext();

const LoansProvider = ({
  children,
  statusFilter,
  sortingFilter,
  customerIdFilter,
  employeeIdFilter,
}) => {
  const { filial, currentRole } = useContext(NovaContext);
  const [dateRange, dispatchDateRange] = useReducer(
    DateRangeReducer,
    initialDateRangeState
  );
  const [loading, dispatchLoading] = useReducer(
    LoadingReducer,
    initialLoadingReducer
  );
  const [loansList, dispatchLoanList] = useReducer(
    LoanReducer,
    initialLoanState
  );

  // ToDo: add method called from reducer to pop loan off list when revoked

  return (
    <LoansContext.Provider
      value={{
        filial,
        currentRole,
        dateRange,
        dispatchDateRange,
        loansList,
        dispatchLoanList,
        loading,
        dispatchLoading,
        // dateState,
        // dateDispatch,
        // updateLoans,
        // setLoading
      }}
    >
      {children}
      <LoansController
        statusFilter={statusFilter}
        sortingFilter={sortingFilter}
        customerIdFilter={customerIdFilter}
        employeeIdFilter={employeeIdFilter}
      />
    </LoansContext.Provider>
  );
};

export { LoansContext, LoansProvider };

export const useLoansContext = () => {
  return useContext(LoansContext);
};
