import React from "react";
import { TextField } from "@mui/material";

export default function TextArea(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    rows = 4,
    ...other
  } = props;

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      multiline
      rows={rows}
      margin="normal"
      fullWidth
      onChange={handleChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
