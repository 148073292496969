export const LoanActions = {
    SET_LOAN: 'SET_LOAN',
    CREATE_LOAN: 'CREATE_LOAN',
    REMOVE_LOAN: 'REMOVE_LOAN'
}

export const LoanReducer = (state, action) => {
    switch (action.type) {
        case LoanActions.SET_LOAN:
            return action.payload;
        case LoanActions.CREATE_LOAN:
            return [action.payload, ...state];
        case LoanActions.REMOVE_LOAN:
            return state.filter((loan) => loan.id !== action.payload);
        default:
            return state;
    }
}