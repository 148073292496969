import React, { useMemo, useState } from "react";
import { Grid, Button, Box } from "@mui/material";
// import EditIcon from '@mui/icons-material/Edit';
import { grey } from "@mui/material/colors";
import ActionChanges from "./ActionChanges";
import ActionOpenNewEdit from "./ActionOpenNewEdit";
import { GridToolbar, gridClasses } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";

const LoanIncrementList = (props) => {
  const {
    loanIncrements,
    // handleNewClick,
    handleEditClick,
    // rowId,
    // setRowId,
    setItemForEdit,
    setOpenForm,
  } = props;

  const [rowId, setRowId] = useState(null);

  const prepareColumns = () => {
    const columns = [
      {
        field: "value",
        headerName: "Valor de Crédito",
        flex: 1,
      },
      {
        field: "renewals",
        headerName: "# de Renovaciones",
        editable: true,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        width: 100,
        renderCell: (params) => [
          <Box display="flex" alignItems={"center"}>
            <ActionOpenNewEdit
              params={params}
              setItemForEdit={setItemForEdit}
              setOpenForm={setOpenForm}
              icon={<EditOutlinedIcon />} // Pass the icon prop here
            />
            <ActionChanges params={params} rowId={rowId} setRowId={setRowId} />,
          </Box>,
        ],
      },
    ];
    return [...columns];
  };

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Box
          sx={{
            width: "auto",
            height: 400,
          }}
        >
          <CustomDataGrid
            columns={prepareColumns()}
            rows={loanIncrements}
            getRowId={(row) => row.value}
            slots={{
              toolbar: CustomGridToolbar,
            }}
            // sx={{
            //   [`& .${gridClasses.row}`]: {
            //     bgcolor: (theme) =>
            //       theme.palette.mode === "light" ? grey[200] : grey[900],
            //   },
            // }}
            onCellEditStop={(params) => {
              console.log("edit ", params);
              setRowId(params.id);
            }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default LoanIncrementList;
