import React from 'react';

const Image = ({ source, description, classNames }) => {
  return (
    <div className={classNames}>
        <img src={source} alt={description} />
    </div>
  );
}

export default Image;