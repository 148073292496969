import React from "react";

import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BreadcrumbNavigation = ({ currentPage }) => {
  const navigate = useNavigate();

  const handleBackClick = (event) => {
    event.preventDefault();
    navigate("/Reports");
  };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        underline="hover"
        color="inherit"
        href="/Reports"
        onClick={handleBackClick}
      >
        <Typography variant="h4">Reports</Typography>
      </Link>
      <Typography variant="h4" color="textPrimary">{currentPage}</Typography>
    </Breadcrumbs>
  );
};

export default BreadcrumbNavigation;