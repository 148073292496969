import React from 'react';

// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NoRowDataOverlay = ({ noRowText }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
        >
            <Box sx={{ mt: 2 }}>
                <Typography variant='h5'>
                    {noRowText ? noRowText : "No se han encontrado datos"}
                </Typography>
            </Box>
        </Box>
    );
}

export default NoRowDataOverlay;