import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  IconButton,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(1),
  },
}));

// function PasswordChange#() #{
    const EditEmployeePassword = (props) => {
        const {
            selectedCustomer
        } = props

    const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePasswordChange = () => {
    if (passwordOne === passwordTwo) {
      // Do something with the passwords here
      console.log(`Password changed to ${passwordOne}`);
      handleClose();
    } else {
      setError('Las contraseñas no coinciden.');
    }
  };

  const handlePasswordOneChange = (event) => {
    setPasswordOne(event.target.value);
    setError('');
  };

  const handlePasswordTwoChange = (event) => {
    setPasswordTwo(event.target.value);
    setError('');
  };

  return (
    <>
       <IconButton color="primary" onClick={handleClickOpen}>
        <LockIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Cambiar contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="grid-container-form">
              <TextField
                id="passwordOne"
                label="Contraseña nueva"
                type="password"
                value={passwordOne}
                onChange={handlePasswordOneChange}
                fullWidth
              />
              <TextField
                id="passwordTwo"
                label="Repetir contraseña"
                type="password"
                value={passwordTwo}
                onChange={handlePasswordTwoChange}
                fullWidth
              />
              <div className="error-message">{error}</div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handlePasswordChange} color="primary">
            Cambiar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditEmployeePassword;
