import {
  addDays,
  addWeeks,
  differenceInDays,
  differenceInWeeks,
  format,
  getDay,
  isPast,
  isSameWeek,
  isToday,
  isYesterday,
  nextMonday,
  nextTuesday,
  nextWednesday,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import { calculateCustomerRiskFL } from "./FuzzyLogic";
import Critical from "@mui/icons-material/GppBad";
import Alarm from "@mui/icons-material/SafetyCheck";
import Warning from "@mui/icons-material/GppMaybe";
import Normal from "@mui/icons-material/VerifiedUser";
import { Box, Tooltip, Typography } from "@mui/material";

export const calculateOpenWeeks = (loan) => {
  if (!loan || !loan.loan_datedue || !loan.loan_dateissue) return 0;

  const currentDate = new Date();
  const dateDue = parseISO(loan.loan_datedue);
  const dateIssue = parseISO(loan.loan_dateissue);
  var arrearWeeks = 0;
  if (isPast(dateDue)) {
    arrearWeeks = Math.abs(differenceInWeeks(dateDue, currentDate)) + 1;
  } else if (isSameWeek(dateIssue, currentDate, { weekStartsOn: 1 })) {
    arrearWeeks = 1;
  }
  // console.log(arrearWeeks, currentDate, dateIssue, dateDue)
  return arrearWeeks;
};

export const calculateDelayedWeeks = (loan) => {
  // console.log(loan);
  
  if (!loan || !loan.loan_datedue) return 0;
  const currentDate = new Date();
  const dateDue = parseISO(loan.loan_datedue);
  var arrearWeeks = 0;
  if (isPast(dateDue) && loan.loan_status === "OPEN") {
    arrearWeeks = Math.abs(differenceInWeeks(dateDue, currentDate)) + 1;
  }
  // console.log(arrearWeeks, currentDate, dateIssue, dateDue)
  return arrearWeeks;
};

export const calculateOpenAmountTotal = (loan) => {
  if (!loan || !loan.loan_datedue) return 0;
  var tempInterestWeeks = 1; //default value if the dueDate is in the future
  const dateDue = parseISO(loan.loan_datedue);
  if (isPast(dateDue)) {
    tempInterestWeeks = calculateOpenWeeks(loan);
  }
  const openInterest =
    tempInterestWeeks * Math.round(loan.loan_remaining * 0.15);
  const tempAmount = loan.loan_remaining + openInterest;
  return tempAmount;
};

export const calculateOpenAmount = (loan) => {
  const OneInterestValue = Math.round(loan.loan_remaining * 0.15);
  const tempAmount = loan.loan_remaining + OneInterestValue;
  return tempAmount;
};

export const calculateOpenInterest = (loan) => {
  if (!loan || !loan.loan_datedue) return 0;
  var tempInterestWeeks = 1; //default value if the dueDate is in the future
  const dateDue = parseISO(loan.loan_datedue);
  if (isPast(dateDue)) {
    tempInterestWeeks = calculateOpenWeeks(loan);
  }
  // const openInterest= Math.round(tempInterestWeeks * loan.loan_remaining*0.15) ;
  const openInterest =
    tempInterestWeeks * Math.round(loan.loan_remaining * 0.15);
  // console.log(loan.loan_datedue, tempInterestWeeks, openInterest)
  return openInterest;
};

export const calculateInterest = (amount, dateissue, dueDate) => {
  var tempInterestWeeks = 1; //default value if the dueDate is in the future
  // const dateDue = parseISO(dueDate);
  if (isPast(dueDate)) {
    tempInterestWeeks = calculateWeeks(dateissue, dueDate );
  }
  const openInterest = tempInterestWeeks * Math.round(amount * 0.15);
  
  // console.log("props", openInterest, amount, dateissue, dueDate );
  return openInterest;
};
export const calculateWeeks = ( dateissue, dueDate ) => {
  const currentDate = new Date();
  // const dateDue = parseISO(dueDate);
  var arrearWeeks = 0;
  if (isPast(dueDate)) {
    arrearWeeks = Math.abs(differenceInWeeks(dueDate, currentDate)) + 1;
  } else if (isSameWeek(dateissue, currentDate, { weekStartsOn: 1 })) {
    arrearWeeks = 1;
  }
  // console.log(arrearWeeks, currentDate, dateissue, dueDate)
  return arrearWeeks;
};

export const calculateOpenArrear = (loan) => {
  if (!loan || !loan.loan_amount) {
    return '-';
  }

  
  const currentDate = new Date();
  const dateDue = parseISO(loan.loan_datedue);
  const dateIssue = parseISO(loan.loan_dateissue);
  const arrearWeeks = calculateOpenWeeks(loan);
  var openArrear = loan.arrears;

  if (
    loan.loan_status === "OPEN" &&
    (!isSameWeek(dateIssue, currentDate) || isPast(dateDue))
  ) {
    if (
      isPast(dateDue) &&
      (arrearWeeks > 0 || isSameWeek(dateDue, currentDate, { weekStartsOn: 1 }))
    ) {
      const arrearWeeks = differenceInWeeks(currentDate, dateDue);
      const diffDays = differenceInDays(currentDate, dateDue);
      const arrearDays = diffDays - arrearWeeks;
      openArrear = openArrear + arrearDays * 20;
    }
  }
  return openArrear;
};

export const calculateDueDate = (Date, dueDay, extendFlag) => {

  var dueDate = Date;

  const todaysDay = getDay(Date);
  //If the loan is created on a Friday/Saturday/Sunday, we need to leave a week in between
  if (extendFlag && (todaysDay === 5 || todaysDay === 6 || todaysDay === 0)) {
    console.log("adding 1 week");
    dueDate = addWeeks(dueDate, 1);
    // console.log(dueDate);
  }

  if (dueDay === "1") {
    dueDate = nextMonday(dueDate);
  } else if (dueDay === "2") {
    if (todaysDay === 1) {
      dueDate = addWeeks(dueDate, 1);
    }
    dueDate = nextTuesday(dueDate);
  } else {
    if (todaysDay === 1 || todaysDay === 2) {
      dueDate = addWeeks(dueDate, 1);
    }
    dueDate = nextWednesday(dueDate);
  }
  dueDate = setHours(dueDate, 13);
  dueDate = setMinutes(dueDate, 15);
  // console.log(dueDate);
  return dueDate;
};

export const recalculateDueDate = (DateInitial, weeksChange) => {
  //for an existing loan it will calculate the new date based on the payments.
  const oldDueDate = DateInitial;
  const tempDaysToAdd = weeksChange ? 7 * weeksChange : 7;
  const currentDate = new Date();
  // console.log(weeksChange, tempDaysToAdd);
  const dueDate =
    weeksChange === 0 ? oldDueDate : addDays(oldDueDate, tempDaysToAdd);
  // console.log(dueDate);
  return dueDate;
};

export const formatDateToDisplay = (Date) => {
  return format(parseISO(Date), "dd/MM/yyyy ");
};

export const hasFreshPayment = (loan) => {
  // const currentDate = new Date();
  const dateIssue = parseISO(loan.loan_dateissue);
  const dateChange = parseISO(loan.loan_datechange);
  const dateDue = parseISO(loan.loan_datedue);
  // console.log(loan);
  // console.log("hasFreshPayment", currentDate, dateIssue, dateChange, dateDue);

  if (
    dateIssue !== dateChange &&
    (isYesterday(dateChange) || isToday(dateChange))
  ) {
    if (!isPast(dateDue)) return true;
  }
  return false;
};

export const calculateRisk = (loan) => {
  // console.log('Loan:',loan);
  
  if (loan && JSON.stringify(loan) !== "{}") {
    if (loan.loan_remaining <= 0) return 0;

    const date =
      loan.loan_status === "CLOSE" ? parseISO(loan.loan_datedue) : new Date();
    const delay = differenceInDays(date, parseISO(loan.loan_dateissue));

    if (delay <= 7) return 0;

    const payment = Math.round(
      ((loan.loan_amount - loan.loan_remaining) / loan.loan_amount) * 100
    );
    const body = { payment, delay };

    return calculateCustomerRiskFL(body);
  }
  return 0;
};

export const formatRiskIcon = (risk) => {
  const riskLevelText =
    risk > 25
      ? risk > 50
        ? risk > 75
          ? "Critico"
          : "Alarma"
        : "Advertencia"
      : "Normal";
  const riskLevelDisplay =
    risk > 25 ? (
      risk > 50 ? (
        risk > 75 ? (
          <Critical color="error" />
        ) : (
          <Alarm color="warning" />
        )
      ) : (
        <Warning color="info" />
      )
    ) : (
      <Normal color="success" />
    );
  return (
    <Tooltip title={riskLevelText} placement="left" arrow>
      <Typography variant="body1" align="left" component="div">
        {riskLevelDisplay}
        {/* ({riskLevelText}){riskLevelDisplay}  */}
      </Typography>
    </Tooltip>
  );
};

export const formatRiskIconWithLabel = (risk) => {
  const riskLevelText =
    risk > 25
      ? risk > 50
        ? risk > 75
          ? "Critico"
          : "Alarma"
        : "Advertencia"
      : "Normal";
  const riskLevelDisplay =
    risk > 25 ? (
      risk > 50 ? (
        risk > 75 ? (
          <Critical color="error" />
        ) : (
          <Alarm color="warning" />
        )
      ) : (
        <Warning color="info" />
      )
    ) : (
      <Normal color="success" />
    );
  return (
    <Tooltip title={riskLevelText} placement="left" arrow>
      <Typography variant="body1" align="left" >
        {/* {riskLevelDisplay} */}
        {riskLevelDisplay} ({riskLevelText})
      </Typography>
    </Tooltip>
  );
};

export const RiskIcon = (loan) => {
  const risk = calculateRisk(loan);
  return formatRiskIcon(risk);
};

export const RiskWithLabel = (loan) => {
  const risk = calculateRisk(loan);
  return formatRiskIconWithLabel(risk);
};


export const RiskStatusLabel = (risk) => {
  const riskText =
    risk > 25
      ? risk > 50
        ? risk > 75
          ? "Critico"
          : "Alarma"
        : "Advertencia"
      : "Normal";
  return `${riskText}`;
};

export const calculateAverageRisk = (loan, risk) => {
  return ((calculateRisk(loan) + risk) * 0.5).toFixed(2);
};
export const loanPercentagePaid = (loan) => {
  const percentage = Math.round(
    ((loan.loan_amount - loan.loan_remaining) / loan.loan_amount) * 100
  );

  return `${percentage}`;
};

