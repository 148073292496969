export const PaymentActions = {
    SET_PAYMENT: 'SET_PAYMENT',
    CREATE_PAYMENT: 'CREATE_PAYMENT',
    REMOVE_PAYMENT: 'REMOVE_PAYMENT'
}

export const PaymentReducer = (state, action) => {
    switch (action.type) {
        case PaymentActions.SET_PAYMENT:
            return action.payload;
        case PaymentActions.CREATE_PAYMENT:
            return [action.payload, ...state];
        case PaymentActions.REMOVE_PAYMENT:
            return state.filter((payment) => payment.id !== action.payload);
        default:
            return state;
    }
}