import { Link } from "react-router-dom";

// date-fns
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

//internal
import AmountDisplay from "../../../common/AmountDisplay";
import { checkAccess } from "../../GlobalHelpers";
import { differenceInDays } from "date-fns";

export const prepareExpenseColumns = (openConfirmation, setSelectedExpense) => {
  const columns = [
    {
      field: "createdate",
      headerName: "Fecha",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 170,
      maxWidth: 250,
      hideable: false,
      valueFormatter: (value) =>
        `${format(parseISO(value), "dd/MM/yyyy HH:mm")} hrs`,
      sortComparator: (v1, v2) => v1.createdate - v2.createdate,
    },
    {
      field: "description",
      headerName: "Descripción",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 200,
      //   width: 400,
      hideable: false,
    },
    {
      field: "employee_name",
      headerName: "RESPONSABLE",
      flex: 1,
      minWidth: 150,
      maxWidth: 500,
      hideable: false,
      renderCell: (params) => {
        return (
          <Link to={`/Employees/${params.row.employeeid}`}>
            {params.row.employee_name}
          </Link>
        );
      },
    },
    {
      field: "amount",
      headerName: "Cantidad",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      renderCell: (params) => <AmountDisplay amount={-params?.row.amount} />,
      sortComparator: (v1, v2) => v1.amount - v2.amount,
    },

    {
      field: "accionRevocar",
      headerName: "",
      width: 80,
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const triggerConfirmationPage = (e) => {
          e.stopPropagation();
          setSelectedExpense(params.row.id);
          openConfirmation(true);
        };
        const isDisabled = differenceInDays(new Date(), parseISO(params?.row.createdate)) > 15;

        return (
          <Box>
            <Button
              color="warning"
              variant="contained"
              size="medium"
              onClick={triggerConfirmationPage}
              disabled={isDisabled}

            >
              <DoDisturbIcon fontSize="small" />
            </Button>
          </Box>
        );
      },
    },
  ];

  return columns;
};

export const columnVisibilityModelExpensesFilialPage = (currentRole) => {
  return {
    employee_name: false,
    accionRevocar: false,
  };
};

export const columnVisibilityModelExpensesContainer = (currentRole) => {
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  console.log(
    hasAuditorAccess,
    hasRegionalManagerAccess,
    hasAuditorAccess || hasRegionalManagerAccess
  );

  return {
    accionRevocar: hasAuditorAccess || hasRegionalManagerAccess,
    //   employee_fullname: false,
  };
};
