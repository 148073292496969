// Utility import
import React, { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

// Component import

const CashRegisterReportTableData = ( {Data} ) => {
    console.log('Data:',Data)
    return (
        <Fragment >
                {Data.map((DataObj, i) => (
                    <div key={`${DataObj}-${i}`}> {/* Combining DataObj with the index */}
                        {DataObj}  
                    </div>
                ))}
        </Fragment>
    );
};

export default CashRegisterReportTableData ;
