import { Box, CircularProgress, Fab, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { green } from "@mui/material/colors";
import { NovaContext } from "../../components/context";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const ActionOpenNewEdit = ({
  params,
  setItemForEdit,
  setOpenForm,
  icon,
}) => {
  const { actions } = useContext(NovaContext);
  // const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClick = () => {
    const item = params.row;
    console.log(item);
    setItemForEdit(item);
    setOpenForm(true);
  };

  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      <Fab
        color="primary"
        sx={{
          width: 40,
          height: 40,
        }}
        // disabled={params.id !== rowId || loading}
        onClick={handleClick}
      >
        {icon}
      </Fab>
    </Box>
  );
};

export default ActionOpenNewEdit;
