import React from 'react'

// mui
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CustomLoadingOverlay = () => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" />
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
            </Box>
        </Box>
    );
}

export default CustomLoadingOverlay;