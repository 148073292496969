// Utility import
import React, { Fragment, useState, useContext } from "react";
import { NovaContext } from "./context";


// Component import
const TableReport = ({ hideColumn, dataList, headersList, totalList, contentPosition }) => {
    // console.log('Table:', dataList, totalList)
    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-head-custom table-head-report table-head-bg table-vertical-center table-bordered table-hover table-sm">
                    <thead>
                        <tr>
                            {headersList && headersList.filter((val) => { return val })
                                .map((header, i) => (
                                    <th key={i} className={`${hideColumn && i === 0 ? 'desktop-no-show' : ''} ${contentPosition.headerStyle}`}>
                                        {header}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList && dataList.filter((val) => { return val })
                            .map((data, i) => (
                                <tr key={i} className={`${data.rowHide ? 'desktop-no-show' : 'text-right'}`}>
                                    {data && data.rowData.map((inner, y) => (
                                        <td key={y} className={`${hideColumn && y === 0 ? 'desktop-no-show' : ''} ${y === 0 ? contentPosition.firstColumnStyle : contentPosition.otherColumnsStyle}`}>
                                            {inner.val}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        {totalList ?
                            <tr className="font-weight-bolder text-right font-size-h4 mt-3">
                                <td className={`${hideColumn ? 'desktop-no-show' : ''} ${contentPosition.firstColumnStyle}`}>TOTAL </td>
                                {totalList && totalList.map((inner, y) => (
                                    <td key={y} className={`${contentPosition.otherColumnsStyle}`}>
                                        {inner.value}
                                    </td>
                                ))}
                            </tr>
                            :
                            null
                        }
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default TableReport;