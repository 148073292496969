import React, { Fragment, useState, useContext, useEffect } from "react";
import ListLoansBySearch from "../components/Actions/ListLoanBySearch";
import Loading from "../components/Actions/Loading";
import { NovaContext } from '../components/context';

const Loans = () => {

    const { actions, filial } = useContext(NovaContext);
    const [loans, setLoans] = useState([]);
    // const Search = useLocation().search;
    const filterStatus = 'OPEN';
    const [lockStatus, setLockStatus] = useState(false);


    useEffect(() => {
        actions.getLoansOpen().then((data) => setLoans(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="page-container">
                <div className={`over-page-loading ${lockStatus ? 'show-loading' : 'hide-loading'}`}>
                    <div className="over-page-loading-content">
                        <Loading
                            text={'Cargando...'}
                            classNames={`over-page-loading ${lockStatus ? 'show-loading' : 'hide-loading'}`}
                        />
                    </div>
                </div>

                {!filial || filial === "0" ?
                <div>
                    <div className="temp-header-space"></div>
                    <div className="filial-missing">
                        <h1 className="text-center ">Selecciona una Sucursal primero</h1>
                    </div>
                </div>
                :
                <ListLoansBySearch 
                    loans={loans} 
                    filterStatus={filterStatus} 
                    pageReportMode={false}
                    lockStatus={lockStatus} 
                    setLockStatus={setLockStatus} />
                }
            </div>
        </Fragment>
    );
};

export default Loans;
