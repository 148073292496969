import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import { NovaContext } from '../components/context';

const Loan = () => {

    const params = useParams();
    const loanId = params.id;
    // Import Context-API‚
    const { actions } = useContext( NovaContext );
    const [loan, setLoan] = useState([]);
    // const location = useLocation().pathname;
    // const loanFromLocation = location.substring(location.lastIndexOf('/') + 1, location.length);


    useEffect(() => {
        if (loanId) {
            actions.getLoan(loanId).then((data) => {
                console.log(data)
                setLoan(data)
            })
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
            <div className="grid-pagecontainer">
                <div className="pageitem1">
                    <h1 className="text-center ">{` ${loan.loan_amount} ${loan.loan_status}`}</h1>

                    <h2 className="text-center ">{` ${loan.loan_dueday} ${loan.loan_datedue}`}</h2>
                </div>
                <div className="pageitem3">
                    <div className="grid-container">
                        
                    </div>  
                <div className="pageitem5">Agregar comentarios</div>
                </div>        
            </div>
    );
};

export default Loan;
