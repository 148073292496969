import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useForm, Form } from "../../components/useForm";
import Controls from "../../components/controls/Controls";

const LoanIncrementForm = ({ setIsOpen, addOrEdit, recordForEdit }) => {
  // const [open, setIsOpen] = useState(false);

  const initialValues = {
    id: 0,
    value: 0,
    renewals: 0,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("value" in fieldValues)
      temp.value = fieldValues.value > 0 ? "" : "Este campo es obligatorio.";
    if ("renewals" in fieldValues)
      temp.renewals =
        fieldValues.renewals > 0 ? "" : "Este campo es obligatorio.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    console.log("openniiiiing!!");
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="value"
            label="Valor de Crédito"
            type="integer"
            value={values.value}
            error={errors.value}
            onChange={handleInputChange}
            required
          />
          <Controls.Input
            name="renewals"
            label="Renovaciones"
            type="integer"
            value={values.renewals}
            error={errors.renewals}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <div>
          <Button
            color="success"
            variant="contained"
            onClick={handleSubmit}
          >
            Guardar
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
        </div>
      </Grid>
    </Form>
  );
};

export default LoanIncrementForm;
