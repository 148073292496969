import { formatISO } from 'date-fns';
import React, { useContext, useRef, useState, Fragment } from 'react';
import { NovaContext } from '../context';

const Confirmation = ( props ) => {

    const {
        classNames,
        closeModal,
        setLoading,
        confirmAction,
        cancelAction,
        headerText,
        bodyText,
        hasErrors
    } = props;

    const closeModalView = () => {
        closeModal();
        // toggleDisplay();
    };

  return (
    <form onSubmit={confirmAction}>
        <div className={`${classNames}`}>
            <div className="modal-header">
                <h4 className="modal-title">{headerText}</h4>
                <button type="button" 
                        className="close" 
                        data-dismiss="modal" 
                        onClick={() => closeModalView()}>&times;
                </button>
            </div>

            <div className="modal-body">
                <p>
                    {bodyText}
                </p>
                {
                    hasErrors ?
                        <ul>
                            {hasErrors.map((error, index) => {
                                return <li key={index}
                                           className="error-text"
                                        >
                                            {error}
                                        </li>
                            })}
                        </ul> : ''
                }
            </div>

            <div className="modal-footer">
                <button
                    type="submit"
                    className="btn btn-success"
                    onClick={confirmAction}
                >
                    Continuar
                </button>
                <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => closeModalView()}
                >
                    Cancelar
                </button>
            </div>
        </div>
    </form>
  )
}

export default Confirmation;