import React from 'react';

// mui
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

const CustomButton = (props) => {
    const {
        locked,
        variant,
        color,
        buttonTrigger,
        buttonText,
    } = props
    return (
        <>
            {
                locked ?
                    <LoadingButton
                        loading
                        variant="contained"
                    >
                        Submit
                    </LoadingButton>
                    :
                    <Button
                        onClick={buttonTrigger}
                        variant={variant}
                        color={color}
                        disabled={locked}
                    >
                        {buttonText}
                    </Button>
            }
        </>
    )
}

export default CustomButton