import { addHours, formatISO } from 'date-fns';
import React, { useContext, useRef, useState, Fragment } from 'react';
import { NovaContext } from '../context';
import Dropdown from '../Dropdown';
import { cashFlowOptions } from "../Helper/Enums";
import { checkAccess, getTimeDifferenceInHours } from '../Helper/GlobalHelpers';
import Modal from './Modal';

const NewCashFlow = () => {

    const { actions, filial, authenticatedUser, currentRole } = useContext(NovaContext);
    const [display, toggleDisplay] = useState(false);
    const [validationStatus, setValidationStatus] = useState(true)
    const [cashFlowMode, setCashFlowMode] = useState('0');
    const hasRegionalManagerAccess = checkAccess(currentRole,'Gerente Regional');
    const amount = useRef();
    const description = useRef();

    const initializeForm = () => {
        amount.current.value = '0';
        description.current.value = '';
        setCashFlowMode('0');
    };

    const handleClick = () => {
        initializeForm()
        toggleDisplay(!display)
    };

    const closeModalView = () => {
        initializeForm();
        toggleDisplay();
    };

    const handleChange = (event, section) => {
        const value = event.target.value;
        switch (section) {
            case 'cashFlowMode': setCashFlowMode(value); break;
            default: break;
        }
        setValidationStatus(validate())
    }

    const validate = () => {
        //TBD add a validation 
        return true
    }

    const handleCreate = async (e) => {
        e.preventDefault();

        const validateCheck = validate()
        if (validateCheck) {
            const currentDate = addHours(new Date(),+getTimeDifferenceInHours());
            const newAmount = cashFlowMode === '1' ? amount.current.value : amount.current.value*(-1);
            console.log(newAmount);
            const tempCashFlow =
            {
                "amount": newAmount,
                "description": description.current.value,
                "createdate": formatISO(currentDate),
                "employeeId": authenticatedUser.employee_id,
                "filialId": filial
            }
            const newCashFlow = await actions.addCashFlow(tempCashFlow);
            closeModalView();
            window.location.reload();
        }
    };

    return (
        hasRegionalManagerAccess?
            <form onSubmit={handleCreate}>
                <Modal
                    classNames={`modal-dropdown ${display ? 'show' : ''}`}
                    toggleModal={handleClick}
                    buttonName="Agregar transacción de caja"
                    buttonClass={"btn btn-white font-weight-bold"}
                    header="Agregar transacción de caja"
                    body={() => (
                        <Fragment>
                            <div className="grid-container-form">
                                <Dropdown
                                    className={`btn btn-outline-secondary align-left`}
                                    label="Tipo de movimiento "
                                    options={cashFlowOptions}
                                    value={cashFlowMode}
                                    onChange={(e) => handleChange(e, 'cashFlowMode')} />

                                <label>Cantidad en efectivo
                                    <input type="number"
                                        className={`form-control text-right`}
                                        ref={amount}
                                        required
                                        onChange={(e) => handleChange(e)}
                                    />
                                </label>

                                <label>Descripcion del movimiento
                                    <textarea type="text"
                                        className="form-control"
                                        ref={description}
                                        required
                                    />
                                </label>

                            </div>
                        </Fragment>
                    )}

                    footer={() => (
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={() => handleCreate()}
                            >
                                Continuar
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={() => closeModalView()}
                            >
                                Cancelar
                            </button>
                        </Fragment>
                    )}
                />
            </form>
            :
            null
    )

}

export default NewCashFlow;