import React, { createContext, useContext, useState, useReducer } from "react";
import { NovaContext } from ".";

// internal
import CashflowController from "../controllers/CashflowController";
import { CashflowReducer } from "../../reducers/CashFlowReducer";
import { DateRangeReducer } from "../common/DateRange/DateRangeReducer";
import { LoadingReducer } from "../../reducers/LoadingReducer";

// date-fns
import subMonths from "date-fns/subMonths";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

// states
const initialCashflowState = [];
const initialDateRangeState = {
  start: startOfDay(subMonths(new Date(), 1)),
  end: endOfDay(new Date()),
};
const initialLoadingReducer = true;

const CashflowContext = createContext();

const CashflowProvider = ({
  children,
  sortingFilter,
  customerIdFilter,
  employeeIdFilter,
}) => {
  const { filial, currentRole } = useContext(NovaContext);
  const [dateRange, dispatchDateRange] = useReducer(
    DateRangeReducer,
    initialDateRangeState
  );
  const [cashflowList, dispatchCashflow] = useReducer(
    CashflowReducer,
    initialCashflowState
  );
  const [loading, dispatchLoading] = useReducer(
    LoadingReducer,
    initialLoadingReducer
  );

  return (
    <CashflowContext.Provider
      value={{
        filial,
        currentRole,
        loading,
        dispatchLoading,
        dateRange,
        dispatchDateRange,
        cashflowList,
        dispatchCashflow,
      }}
    >
      {children}
      <CashflowController
        sortingFilter={sortingFilter}
        employeeIdFilter={employeeIdFilter}
      />
    </CashflowContext.Provider>
  );
};

export { CashflowContext, CashflowProvider };

export const useCashflowContext = () => {
  return useContext(CashflowContext);
};
