// Utility import
import { addHours, format, parseISO } from "date-fns";
import React, { Fragment, useState, useContext } from "react";
import { TiCancel } from "react-icons/ti";
import { NovaContext } from "../context";
import { checkAccess, getTimeDifferenceInHours } from "../Helper/GlobalHelpers";
import Button from "./Button";

// Component import
const ListCashFlowsBySearch = (props) => {

    const {
        cashflows,
        selectAction
    } = props;

    const { actions, currentRole } = useContext(NovaContext);
    const hasAuditorAccess = checkAccess(currentRole,'Auditor');

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                    <thead>
                        <tr>
                            <th>Responsable</th>
                            <th>Cantidad</th>
                            <th>Fecha de transacción</th>
                            <th>Descripcion</th>
                            {
                                hasAuditorAccess ?
                                    <th>Revocar</th> : null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {cashflows && cashflows.filter((val) => { return val })
                            .map((cashflow, i) => (
                                <tr key={i}>
                                    <td>{cashflow.employee_name} {cashflow.employee_lastname}      </td>
                                    <td>{cashflow.amount * (-1)}  </td>
                                    <td>{format(parseISO(cashflow.createdate), "dd/MM/yyyy HH:mm:ss")} hrs                            </td>
                                    <td>{cashflow.description}      </td><td>
                                        {
                                            hasAuditorAccess ?
                                                <Button
                                                    type={"button"}
                                                    nameOfClass={"btn ml-2 btn-warning btn-hover-light icon-m alarm"}
                                                    clickBehaviour={() => selectAction(cashflow.id, 'RevokeCashFlow')}
                                                    text={""}
                                                    icon={<TiCancel />}
                                                />
                                                : null
                                        }
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default ListCashFlowsBySearch;