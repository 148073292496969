import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useForm, Form } from "../../components/useForm";
import Controls from "../../components/controls/Controls";

const FilialForm = ({ setIsOpen, addOrEdit, recordForEdit }) => {
  // const [open, setIsOpen] = useState(false);

  const initialValues = {
    id: 0,
    filial_address: "",
    telephone: "",
    initialloanvalue: "",
    maxloanvalue: "",
  };

  const validate = (fieldValues = values) => {
    console.log("1:", fieldValues, values);
    let temp = { ...errors };
    if ("filial_address" in fieldValues)
      temp.filial_address =
        fieldValues.filial_address !== "" ? "" : "Este campo es obligatorio.";
    if ("telephone" in fieldValues)
      temp.telephone =
        fieldValues.telephone !== "" ? "" : "Este campo es obligatorio.";
    if ("initialloanvalue" in fieldValues)
      temp.initialloanvalue =
        fieldValues.initialloanvalue !== "" ? "" : "Este campo es obligatorio.";
    if ("maxloanvalue" in fieldValues)
      temp.maxloanvalue =
        fieldValues.maxloanvalue !== "" ? "" : "Este campo es obligatorio.";
    setErrors({
      ...temp,
    });
    console.log("2:", fieldValues, values, temp);

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Controls.Input
            name="filial_address"
            label="Dirección"
            type="text"
            value={values.filial_address}
            error={errors.filial_address}
            onChange={handleInputChange}
            required
          />
          <Controls.Input
            name="telephone"
            label="Teléfono"
            type="integer"
            value={values.telephone}
            error={errors.telephone}
            onChange={handleInputChange}
            required
          />
          <Controls.Input
            name="initialloanvalue"
            label="Máximo valor inicial"
            type="integer"
            value={values.initialloanvalue}
            error={errors.initialloanvalue}
            onChange={handleInputChange}
            step={100}
            required
          />
          <Controls.Input
            name="maxloanvalue"
            label="Máximo valor de prestamo"
            type="integer"
            value={values.maxloanvalue}
            error={errors.maxloanvalue}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <div>
          <Controls.Button type="submit" text="Continuar" />
          <Controls.Button
            autoFocus
            color="secondary"
            text="Cancelar"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </Grid>
    </Form>
  );
};

export default FilialForm;
