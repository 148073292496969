import { Box, Typography } from '@mui/material';
import React from 'react';

const AmountDisplay = ({ amount }) => {

    const convertNumber = (toBeChanged) => {
        return new Intl.NumberFormat('en-US',
            {
                style: 'decimal'
            })
            .format(toBeChanged)
    }

    return (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
        >
            <Typography>$</Typography>
            <Typography>{convertNumber(amount)}</Typography>
        </Box>
    )
}

export default AmountDisplay