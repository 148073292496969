import React, { useEffect } from 'react';

// internal
import { getAuditTrail } from '../../utils/api/api_audit';
import { useAuditContext } from '../context/AuditContext';
import { alerts, displayAlert } from '../Helper/AlertToastify';
import { AuditActions } from '../../reducers/AuditReducer';
import { LoadingActions } from '../../reducers/LoadingReducer';

const AuditController = () => {
    const { filial, dispatchLoading, currentRole, dispatchAudit, dateRange } = useAuditContext();
    const start = dateRange.start.toISOString();
    const end = dateRange.end.toISOString();

    useEffect(() => {
        getAuditTrail("filial", `filial=${filial}&currentRole=${currentRole}&start=${start}&end=${end}`)
            .then(resp => {
                if (resp.status === 404 || resp.status === 500) {
                    displayAlert(alerts.ERROR, resp.text)
                } else {
                    dispatchAudit({ type: AuditActions.SET_AUDITS, payload: resp });
                }
            })
            .catch(resp => displayAlert(alerts.ERROR, resp.text))
            .finally(() => dispatchLoading({ type: LoadingActions.SET_LOADING, payload: false }));
    }, [filial, dateRange])

    return null;
}

export default AuditController;