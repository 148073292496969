export const PaycheckActions = {
    SET_PAYCHECK: 'SET_PAYCHECK',
    CREATE_PAYCHECK: 'CREATE_PAYCHECK',
    REMOVE_PAYCHECK: 'REMOVE_PAYCHECK'
}

export const PaycheckReducer = (state, action) => {
    switch (action.type) {
        case PaycheckActions.SET_PAYCHECK:
            return action.payload;
        case PaycheckActions.CREATE_PAYCHECK:
            return [action.payload, ...state];
        case PaycheckActions.REMOVE_PAYCHECK:
            return state.filter((cashflow) => cashflow.id !== action.payload);
        default:
            return state;
    }
}