import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    cursor: "pointer",
    borderRadius: "12px", // add fallback value
    padding: "2rem 2.25rem",
    transition: "background-color 0.2s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "black",
    },
  },
  icon: {
    fontSize: "2rem",
    marginBottom: "2px",
  },
  title: {
    textAlign: "center",
    // fontWeight: 'bold',
    color: "white",
  },
}));

const AdminCardItem = ({ header, triggerPage, icon }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.card}
      onClick={triggerPage}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {icon}
      {/* <Icon className={classes.icon}>{icon}</Icon> */}
      <Typography variant="h5" component="h2" className={classes.title}>
        {header}
      </Typography>
    </div>
  );
};

{/* <Box
onClick={triggerPage}
sx={{
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  cursor: "pointer",
  borderRadius: "12px",
  padding: "2rem 2.25rem",
  transition: "background-color 0.2s ease-in-out",
  backgroundColor: "primary.main",
  "&:hover": {
    backgroundColor: "primary.light",
    color: "black",
  },
}}
>
{icon && (
  <Box sx={{ fontSize: "2rem", marginBottom: "2px" }}>
    {icon}
  </Box>
)}
<Typography variant="h5" component="h2" sx={{ textAlign: "center", color: "white" }}>
  {header}
</Typography>
</Box> */}

export default AdminCardItem;
