import React, { Fragment, useState, useContext, useEffect } from "react";
import { NovaContext } from '../components/context';
import { addHours, format } from "date-fns";
import { calculateReportDates, checkMinAccess, filterFilialsWithAccess, getTimeDifferenceInHours, noAccessMessage } from "../components/Helper/GlobalHelpers";
import TableReport from "../components/TableReport";
import Loading from "../components/Actions/Loading";
import { calculateReportDataTotals } from "../components/Helper/ReportsHelpers";

const RegisterCutMultiFilial = () => {
    const { actions, filials } = useContext(NovaContext);
    const hasMinRegionalManagerAccess = checkMinAccess(filials, 'Gerente Regional');
    const hasMinAuditorAccess = checkMinAccess(filials, 'Auditor');
    const [reportData1, setReportData1] = useState([]);
    const [reportData2, setReportData2] = useState([]);
    const [reportData3, setReportData3] = useState([]);
    const [reportData4, setReportData4] = useState([]);
    const [reportData5, setReportData5] = useState([]);
    const [reportData6, setReportData6] = useState([]);
    const [reportData7, setReportData7] = useState([]);
    const [totalRow1, setTotalRow1] = useState([]);
    const [totalRow2, setTotalRow2] = useState([]);
    const [totalRow3, setTotalRow3] = useState([]);
    const [totalRow4, setTotalRow4] = useState([]);
    const [totalRow5, setTotalRow5] = useState([]);
    const [totalRow6, setTotalRow6] = useState([]);
    const [totalRow7, setTotalRow7] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [reportMode, setReportMode] = useState('WEEK');
    const [loading, setLoading] = useState(false);
    const [range, setRange] = useState({});

    const contentPosition = { headerStyle: 'text-center', firstColumnStyle: 'text-left', otherColumnsStyle: 'text-center' }

    const headersTable1 = ['Sucursal', 'Total [$]', '# Clientes']
    const headersTable2 = ['Sucursal', 'Meta [$]', 'Monto [$]', '% Meta', '% Mora']
    const headersTable3 = ['Sucursal', '# Clientes', 'Monto [$]', '% Riesgo']
    const headersTable4 = ['Sucursal', 'Nuevos', 'Renovados', 'Cancelados']
    const headersTable5 = ['Sucursal', 'Total [$]', 'Refrendo [$]', 'Mora [$]', 'Capital [$]', 'Prestado [$]', 'Crecimiento [$]']
    const headersTable6 = ['Sucursal', 'Oficina [$]', 'Nominas [$]', 'Total [$]']
    const headersTable7 = hasMinRegionalManagerAccess ? ['Sucursal', 'Traslados [$]', 'Saldo [$]'] : ['Sucursal', 'Saldo [$]']

    useEffect(() => {
        if (hasMinRegionalManagerAccess || hasMinAuditorAccess) {
            setLoading(true);
            setCurrentDateTime(format(new Date(), "dd/MM/yyyy HH:mm:ss") + ' hrs');
            const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
            const DataRange = calculateReportDates(reportMode, currentDate)
            setRange(DataRange);
            const tempFilials = [...filials];
            filterFilialsWithAccess(tempFilials);
            getFilialsReportData(tempFilials, DataRange);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFilialsReportData = async (filialsList, DataRange) => {
        var tempFilialId, tempCashRegister, tempCountCustomers, tempTotalRow, tempDelayLoans, tempSumDelayLoans;
        var rowData = []
        var DataTable1 = [], DataTable2 = [], DataTable3 = [], DataTable4 = [], DataTable5 = [], DataTable6 = [], DataTable7 = [];
        var tempDataTable1 = [], tempDataTable2 = [], tempDataTable3 = [], tempDataTable4 = [], tempDataTable5 = [], tempDataTable6 = [], tempDataTable7 = [];
        var tempCashRegisterReport, tempCashRegisterLastReport, cashregistertotal, growth
        var filialName, openloanstotal, countCustomers, interesttotal, arrearsPaidPercentage
        var arrearstotal, capitaltotal, loanstotal, counternewloans, counterrenewals, counterexpenses
        var expensestotal, counterpayroll, countercashflow, filialGoal, filialGoalPercentage;
        var countercancelled, delayedLoans, sumDelayLoans, filialRisikPercentage, paymenttotal;
        const currentDate = addHours(new Date(), +getTimeDifferenceInHours());

        for (var i = 0; i < filialsList.length; i++) {
            tempFilialId = filialsList[i].filial_id
            tempCountCustomers = await actions.countActiveCustomers(tempFilialId)
            tempCashRegister = await actions.getCashRegister(tempFilialId)
            tempCashRegisterReport = await actions.getCurrentCashRegisterReport(tempFilialId)
            tempCashRegisterLastReport = await actions.getLastCashRegisterReport(tempFilialId)
            tempDelayLoans = await actions.countLoans('OPEN', currentDate, tempFilialId);
            tempSumDelayLoans = await actions.sumLoansRemaining(currentDate, tempFilialId);

            filialName = filialsList[i].filial_name;
            openloanstotal = tempCashRegisterReport.openloanstotal;
            countCustomers = parseInt(tempCountCustomers)
            interesttotal = tempCashRegisterReport.interesttotal;
            arrearstotal = tempCashRegisterReport.arrearstotal;
            capitaltotal = tempCashRegisterReport.capitaltotal;
            paymenttotal = interesttotal + arrearstotal + capitaltotal;
            loanstotal = tempCashRegisterReport.loanstotal;
            counternewloans = tempCashRegisterReport.counternewloans;
            counterrenewals = tempCashRegisterReport.counterrenewals;
            counterexpenses = tempCashRegisterReport.counterexpenses;
            counterpayroll = tempCashRegisterReport.counterpayroll;
            countercashflow = tempCashRegisterReport.countercashflow;
            countercancelled = 0
            cashregistertotal = tempCashRegisterReport.cashregistertotal;
            expensestotal = counterexpenses + counterpayroll
            filialGoal = Math.round(tempCashRegisterLastReport.openloanstotal * .15)
            filialGoalPercentage = (filialGoal > 0) ? parseFloat(((interesttotal / filialGoal) * 100).toFixed(3)) : 0
            delayedLoans = parseInt(tempDelayLoans);
            sumDelayLoans = parseInt(tempSumDelayLoans);
            filialRisikPercentage = (openloanstotal > 0) ? parseFloat(((sumDelayLoans / openloanstotal) * 100).toFixed(3)) : 0
            arrearsPaidPercentage = (filialGoal > 0) ? parseFloat(((arrearstotal / filialGoal) * 100).toFixed(3)) : 0
            growth = loanstotal - capitaltotal

            //////////     Table1      //////////
            tempDataTable1 = {
                filialName,
                openloanstotal,
                countCustomers
            }

            Object.values(tempDataTable1).forEach((val) => {
                const row = { val };
                rowData.push(row);
            });

            DataTable1.push({ rowData })
            rowData = [];

            //////////     Table2       //////////
            tempDataTable2 = {
                filialName,
                filialGoal,
                interesttotal,
                filialGoalPercentage,
                arrearsPaidPercentage
            }
            Object.values(tempDataTable2).forEach((val) => {
                const row = { val };
                rowData.push(row);
            });

            DataTable2.push({ rowData })
            rowData = [];

            //////////     Table3       //////////
            tempDataTable3 = {
                filialName,
                delayedLoans,
                sumDelayLoans,
                filialRisikPercentage
            }
            Object.values(tempDataTable3).forEach((val) => {
                const row = { val };
                rowData.push(row);
            });

            DataTable3.push({ rowData })
            rowData = [];

            //////////     Table4       //////////
            tempDataTable4 = {
                filialName,
                counternewloans,
                counterrenewals,
                countercancelled
            }
            Object.values(tempDataTable4).forEach((val) => {
                const row = { val };
                rowData.push(row);
            });

            DataTable4.push({ rowData })
            rowData = [];

            //////////     Table5       //////////
            tempDataTable5 = {
                filialName,
                paymenttotal,
                interesttotal,
                arrearstotal,
                capitaltotal,
                loanstotal,
                growth
            }

            Object.values(tempDataTable5).forEach((val) => {
                const row = { val };
                rowData.push(row);
            });

            DataTable5.push({ rowData })
            rowData = [];

            //////////     Table6       //////////

            tempDataTable6 = {
                filialName,
                counterexpenses,
                counterpayroll,
                expensestotal
            }

            Object.values(tempDataTable6).forEach((val) => {
                const row = { val };
                rowData.push(row);
            });

            DataTable6.push({ rowData })
            rowData = [];

            //////////     Table7       //////////
            tempDataTable7 = hasMinRegionalManagerAccess ? {
                filialName,
                countercashflow,
                cashregistertotal
            } : {
                filialName,
                cashregistertotal
            }

            Object.values(tempDataTable7).forEach((val) => {
                const row = { val };
                rowData.push(row);
            });

            DataTable7.push({ rowData })
            rowData = [];
        }

        setReportData1(DataTable1)
        tempTotalRow = calculateReportDataTotals(DataTable1)
        setTotalRow1(tempTotalRow)

        setReportData2(DataTable2)
        tempTotalRow = calculateReportDataTotals(DataTable2)
        setTotalRow2(tempTotalRow)

        setReportData3(DataTable3)
        tempTotalRow = calculateReportDataTotals(DataTable3)
        setTotalRow3(tempTotalRow)

        setReportData4(DataTable4)
        tempTotalRow = calculateReportDataTotals(DataTable4)
        setTotalRow4(tempTotalRow)

        setReportData5(DataTable5)
        tempTotalRow = calculateReportDataTotals(DataTable5)
        setTotalRow5(tempTotalRow)

        setReportData6(DataTable6)
        tempTotalRow = calculateReportDataTotals(DataTable6)
        setTotalRow6(tempTotalRow)

        setReportData7(DataTable7)
        tempTotalRow = calculateReportDataTotals(DataTable7)
        setTotalRow7(tempTotalRow)


        setLoading(false);
    }



    return (
        <Fragment>
            <div className="page-container">
                <div className={`over-page-loading ${loading ? 'show-loading' : 'hide-loading'}`}>
                    <div className="over-page-loading-content">
                        <Loading
                            text={'Cargando...'}
                            classNames={`over-page-loading ${loading ? 'show-loading' : 'hide-loading'}`}
                        />
                    </div>
                </div>

                {loading ?
                    <div>Cargando...</div>
                    :
                    !(hasMinRegionalManagerAccess || hasMinAuditorAccess) ?
                        <div>
                            <div className="temp-header-space"></div>
                            <div className="filial-missing">
                                <h1 className="text-center ">{noAccessMessage()}</h1>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="subheader py-2 subheader-transparent" id="kt_subheader">
                                <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                    <div className="d-flex align-items-center flex-wrap mr-1">
                                        <div className="d-flex flex-column">
                                            <span>
                                                <h2 className="text-black font-weight-bold my-2 mr-5">CORTE </h2>
                                                <h6 className="text-black">{`Corte de caja al día:  ${currentDateTime}`}</h6>
                                            </span>
                                            <span><h6 className="text-black">{`Intervalo del corte:  ${range.startDay} - ${range.endDay}`}</h6></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column-fluid">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-3 mb-5">
                                            <div className="card card-custom  card-stretch">
                                                <div className="card-body-sm-padding">
                                                    <div className="border-0 pt-5">
                                                        <div className="card-title">
                                                            <div className="card-label">
                                                                <div className="font-weight-bolder text-center font-size-h2">CARTERA TOTAL</div>
                                                                <TableReport
                                                                    hideColumn={false}
                                                                    dataList={reportData1}
                                                                    headersList={headersTable1}
                                                                    contentPosition={contentPosition}
                                                                    totalList={totalRow1}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 mb-5">
                                            <div className="card card-custom  card-stretch">
                                                <div className="card-body-sm-padding">
                                                    <div className="border-0 pt-5">
                                                        <div className="card-title">
                                                            <div className="card-label">
                                                                <div className="font-weight-bolder text-center font-size-h2">CUMPLIMIENTO DE META</div>
                                                                <TableReport
                                                                    hideColumn={true}
                                                                    dataList={reportData2}
                                                                    headersList={headersTable2}
                                                                    contentPosition={contentPosition}
                                                                    totalList={totalRow2}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 mb-5">
                                            <div className="card card-custom  card-stretch">
                                                <div className="card-body-sm-padding">
                                                    <div className="border-0 pt-5">
                                                        <div className="card-title">
                                                            <div className="card-label">
                                                                <div className="font-weight-bolder text-center font-size-h2">CARTERA DE RIESGO</div>
                                                                <TableReport
                                                                    hideColumn={true}
                                                                    dataList={reportData3}
                                                                    headersList={headersTable3}
                                                                    contentPosition={contentPosition}
                                                                    totalList={totalRow3}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 mb-5">
                                            <div className="card card-custom  card-stretch">
                                                <div className="card-body-sm-padding">
                                                    <div className="border-0 pt-5">
                                                        <div className="card-title">
                                                            <div className="card-label">
                                                                <div className="font-weight-bolder text-center font-size-h2">NUMERO DE CREDITOS</div>
                                                                <TableReport
                                                                    hideColumn={true}
                                                                    dataList={reportData4}
                                                                    headersList={headersTable4}
                                                                    contentPosition={contentPosition}
                                                                    totalList={totalRow4}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-7 mb-5">
                                            <div className="card card-custom  card-stretch">
                                                <div className="card-body-sm-padding">
                                                    <div className="border-0 pt-5">
                                                        <div className="card-title">
                                                            <div className="card-label">
                                                                <div className="font-weight-bolder text-center font-size-h2">PAGOS INGRESADOS</div>
                                                                <TableReport
                                                                    hideColumn={false}
                                                                    dataList={reportData5}
                                                                    headersList={headersTable5}
                                                                    contentPosition={contentPosition}
                                                                    totalList={totalRow5}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 mb-5">
                                            <div className="card card-custom  card-stretch">
                                                <div className="card-body-sm-padding">
                                                    <div className="border-0 pt-5">
                                                        <div className="card-title">
                                                            <div className="card-label">
                                                                <div className="font-weight-bolder text-center font-size-h2">GASTOS</div>
                                                                <TableReport
                                                                    hideColumn={true}
                                                                    dataList={reportData6}
                                                                    headersList={headersTable6}
                                                                    contentPosition={contentPosition}
                                                                    totalList={totalRow6}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 mb-5">
                                            <div className="card card-custom  card-stretch">
                                                <div className="card-body-sm-padding">
                                                    <div className="border-0 pt-5">
                                                        <div className="card-title">
                                                            <div className="card-label">
                                                                <div className="font-weight-bolder text-center font-size-h2">CAJA</div>
                                                                <TableReport
                                                                    hideColumn={true}
                                                                    dataList={reportData7}
                                                                    headersList={headersTable7}
                                                                    contentPosition={contentPosition}
                                                                    totalList={totalRow7}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </Fragment>
    );
};

export default RegisterCutMultiFilial;
