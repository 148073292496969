import React, { Fragment, useState, useContext, useEffect } from "react";
import { NovaContext } from "../components/context";
import CashRegisterReportTable from "../components/Actions/CashRegisterReportTable";
import { format, isSameMonth, isSameWeek } from "date-fns";
import CashRegisterControl from "../components/popups/CashRegisterControlCheck";
import {
  calculateReportDates,
  changeDateReference,
  checkAccess,
  noAccessMessage,
} from "../components/Helper/GlobalHelpers";
import Dropdown from "../components/Dropdown";
import { ReportMode } from "../components/Helper/Enums";
import { AiFillStepBackward, AiFillStepForward } from "react-icons/ai";
import { Box, Divider, Grid2 as Grid, Paper, Typography } from "@mui/material";
import {
  ItemPaper,
} from "../components/Helper/MUIStyledComponents";
import CashRegisterTotal from "../components/CashRegisterTotal";
import FilialGoalItem from "./Filial/FilialGoalItem";

const RegisterCut = () => {
  const { actions, filial, currentRole } = useContext(NovaContext);
  const [counterActiveCustomers, setCounterActiveCustomers] = useState(0);
  const [counterDelayedLoans, setCounterDelayedLoans] = useState(0);
  const [CashRegisterReport, setCashRegisterReport] = useState(0);
  const [counterCustomers, setCounterCustomers] = useState(0);
  const [reportTotalData, setReportTotalData] = useState({});
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [dateReference, setDateReference] = useState();
  const [reportMode, setReportMode] = useState("WEEK");
  const [arrearTotal, setArrearTotal] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [currentFilial, setFilial] = useState("");
  const [range, setRange] = useState({});

  const hasManagerAccess = checkAccess(currentRole, "Gerente");
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  useEffect(() => {
    if (hasManagerAccess) {
      const referenceDate = changeDateReference(
        null,
        reportMode,
        dateReference
      );
      setCurrentDateTime(format(referenceDate, "dd/MM/yyyy HH:mm:ss") + " hrs");
      const DataRange = calculateReportDates(reportMode, referenceDate);
      // console.log('DR:',DataRange);
      
      setRange(DataRange);
      DataRange.filialId = filial;
      actions.getFilial(filial).then((data) => setFilial(data));
      actions
        .countCustomers(filial, referenceDate)
        .then((data) => setCounterCustomers(data));
      actions
        .countActiveCustomers(filial)
        .then((data) => setCounterActiveCustomers(data));

      // actions
      //   .getCashRegister(filial)
      //   .then((data) => setCashRegisterTotal(data.total));
      actions.getReports(DataRange).then((data) => setReportTotalData(data));
      actions.getReportMatrix(DataRange).then((data) => setReportData(data));
      actions
        .sumLoansRemaining(referenceDate)
        .then((data) => setArrearTotal(data));
      actions
        .countLoans("OPEN", referenceDate)
        .then((data) => setCounterDelayedLoans(data));
      actions
        .getCurrentCashRegisterReport(filial)
        .then((data) => setCashRegisterReport(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportMode, dateReference]);

  // const calculateGoalPercentage = () => {
  //   if (
  //     CashRegisterReport &&
  //     reportTotalData &&
  //     CashRegisterReport.weeklygoal !== 0 &&
  //     reportTotalData.interest
  //   ) {
  //     const Percentage =
  //       (reportTotalData.interest / CashRegisterReport.weeklygoal) * 100;
  //     return Percentage.toFixed(3);
  //   }
  //   return 0;
  // };

  const calculateRisk = () => {
    if (
      arrearTotal &&
      CashRegisterReport &&
      CashRegisterReport.openloanstotal
    ) {
      const Percentage =
        (arrearTotal / CashRegisterReport.openloanstotal) * 100;
      return Percentage.toFixed(3);
    }
    return 0;
  };

  // const calculateArrearPaid = () => {
  //   if (
  //     CashRegisterReport &&
  //     CashRegisterReport.arrearstotal &&
  //     CashRegisterReport.weeklygoal !== 0
  //   ) {
  //     const Percentage =
  //       (CashRegisterReport.arrearstotal / CashRegisterReport.weeklygoal) * 100;
  //     return Percentage.toFixed(3);
  //   }
  //   return 0;
  // };

  // reactivate if the Range configuration is needed
  const handleChange = (event) => {
    const value = event.target.value;
    const currentDate = dateReference;
    setReportMode(value);
    // setCurrentDateTime(format(currentDate, 'EEE dd MMMM'));
    setRange(calculateReportDates(value, currentDate));
  };

  const handleChangeReference = (delta) => {
    var newDate = changeDateReference(delta, reportMode, dateReference);

    setDateReference(newDate);
  };

  return (
    <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }}>
      {!filial || filial === "0" ? (
        <Grid size={12} pt="200px">
          <Typography
            variant="h1"
            component="h1"
            display="flex"
            justifyContent="center"
            sx={{ textAlign: "center" }}
          >
            Selecciona una Sucursal primero
          </Typography>
        </Grid>
      ) : !hasManagerAccess ? (
        <Grid size={12} pt="200px">
          <Typography
            variant="h1"
            component="h1"
            display="flex"
            justifyContent="center"
            sx={{ textAlign: "center" }}
          >
            {noAccessMessage()}
          </Typography>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid size={{ xs: 7, sm: 7, md: 7, lg: 8 }}>
            <Typography align="left" component="div">
              <Box sx={{ fontSize: "2.5em", fontWeight: "bold", m: 0.3 }}>
                Corte de sucursal {currentFilial.filial_name}
              </Box>
              <Box sx={{ m: 0.3 }}>
                {`Corte de caja al día:  ${currentDateTime}`}
              </Box>

              <Box sx={{ m: 0.3 }}>
                {`Intervalo del corte:  ${range.startDay} - ${range.endDay}`}
              </Box>
            </Typography>
          </Grid>

          <Grid
            size={{ xs: 5, sm: 2}}
            display="flex"
            justifyContent="right"
            alignItems="center"
          >
            {!(hasRegionalManagerAccess || hasAuditorAccess) ? (
              <></>
            ) : (
              <>
                {reportMode === "YEAR" ? (
                  <div className={`mr-2 btn btn-light.disabled icon-xl`}>
                    <AiFillStepBackward />
                  </div>
                ) : (
                  <div
                    className="mr-2 btn btn-outline-info icon-xl"
                    onClick={() => handleChangeReference(-1)}
                  >
                    <AiFillStepBackward />
                  </div>
                )}
                {/* /> */}
                <Dropdown
                  className="btn btn-outline-info align-left mr-1"
                  options={ReportMode}
                  value={reportMode}
                  onChange={handleChange}
                  // readOnly={hasRegionalManagerAccess? '' : 'disabled'}
                />
                {!dateReference ||
                isSameWeek(dateReference, new Date()) ||
                (reportMode === "MONTH" &&
                  isSameMonth(dateReference, new Date())) ||
                reportMode === "YEAR" ? (
                  <div className={`mr-1 btn btn-light.disabled icon-xl`}>
                    <AiFillStepForward />
                  </div>
                ) : (
                  <div
                    className={`mr-1 btn btn-outline-info icon-xl`}
                    onClick={() => handleChangeReference(+1)}
                  >
                    <AiFillStepForward />
                  </div>
                )}

                {reportMode !== "WEEK" ? (
                  <h6 className="text-black text-hover-white opacity-75 hover-opacity-100">
                    {" "}
                    Nota: al modificar el intervalo no se pierde la veracidad de
                    valores pasados
                  </h6>
                ) : null}
              </>
            )}
          </Grid>

          <Grid
            size={{ xs: 12, sm: 3, lg: 2 }}
            display="flex"
            justifyContent="right"
            sx={{ marginTop: { xs: "-16px", sm: "initial" } }}
          >
            <CashRegisterTotal />
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}

          <Grid size={{ xs: 6, sm: 4, md: 4 }}>
          <FilialGoalItem filialId={filial}/>
          </Grid>
          <Grid size={{ xs: 6, sm: 4, md: 4 }}>
            <ItemPaper>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                Clientes
              </Typography>
              <Divider />
              <Typography variant="h5">Totales - {counterCustomers}</Typography>
              <Typography variant="h5">
                Activos - {counterActiveCustomers}
              </Typography>
              <Typography variant="h5">
                En mora - {counterDelayedLoans}
              </Typography>
              <Typography variant="h5">Riesgo - ${arrearTotal}</Typography>
              <Typography variant="h5">{calculateRisk()}% en mora</Typography>
            </ItemPaper>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <ItemPaper>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                Créditos
              </Typography>
              <Divider />
              <Typography variant="h5">
                Totales - {CashRegisterReport.counterloantotal}
              </Typography>
              <Typography variant="h5">
                Nuevos - {CashRegisterReport.counternewloans}
              </Typography>
              <Typography variant="h5">
                Renovados - {CashRegisterReport.counterrenewals}
              </Typography>
              <Typography variant="h5">Cancelados - 0</Typography>
              <Typography variant="h5">Cancelados Totales - 0</Typography>
            </ItemPaper>
          </Grid>
          {hasRegionalManagerAccess || hasAuditorAccess ? (
            <Grid size={{ xs: 12, md: 8 }}>
              <ItemPaper>
                <CashRegisterReportTable
                  reportData={reportData}
                  reportMode={reportMode}
                  referenceDate={dateReference}
                />
              </ItemPaper>
            </Grid>
          ) : null}

          <Grid size={{ xs: 12, md: 4 }}>
            <ItemPaper>
              <Typography variant="h4">Cartera Total</Typography>
              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                ${CashRegisterReport.openloanstotal}
              </Typography>
              <Typography variant="h4">Refrendo Total</Typography>
              <Typography variant="h4">
                ${CashRegisterReport.interesttotal}
              </Typography>
              {hasRegionalManagerAccess || hasAuditorAccess ? (
                <>
                  <Typography variant="h4">Mora Total</Typography>
                  <Typography variant="h4">
                    ${CashRegisterReport.arrearstotal}
                  </Typography>
                </>
              ) : null}
              <Typography variant="h4">Capital Recuperado</Typography>
              <Typography variant="h4">
                ${CashRegisterReport.capitaltotal}
              </Typography>
              <Typography variant="h4">Prestado Total</Typography>
              <Typography variant="h4">
                ${CashRegisterReport.loanstotal}
              </Typography>
              <Typography variant="h4">Crecimiento</Typography>
              <Typography variant="h4">
                $
                {CashRegisterReport.loanstotal -
                  CashRegisterReport.capitaltotal}
              </Typography>
              <Typography variant="h4">Gastos</Typography>
              <Typography variant="h4">
                ${CashRegisterReport.counterexpenses}
              </Typography>
            </ItemPaper>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default RegisterCut;
