
export const calculateReportDataTotals = (data) => {
    var totals = [];
    var value;
    const rowCounter = data.length;
    // console.log(rowCounter)
    
    data.forEach(element => {
        element.rowData.forEach((key, index) => {
            if (index !== 0) {
                if (totals[index - 1]) {
                    value = Number.isInteger(key.val) ? key.val : parseFloat((key.val).toFixed(3))
                    totals[index - 1].value += value;
                } else {
                    value = Number.isInteger(key.val) ? key.val : parseFloat((key.val).toFixed(3))
                    totals.push({ value })
                }
            }
        });
    });

    // console.log(totals)
    for (var i=0 ; i <= totals.length ; i++) {
        if (totals[i] && !Number.isInteger(totals[i].value)) {
            totals[i].value = (totals[i].value / rowCounter).toFixed(3);
            // console.log(totals[i])
        }
    }
    return totals
};



export const calculateGoalPercentage = (CashRegisterReport,reportTotalData) => {
    if (
      CashRegisterReport &&
      reportTotalData &&
      CashRegisterReport.weeklygoal !== 0 &&
      reportTotalData.interest
    ) {
      const Percentage =
        (reportTotalData.interest / CashRegisterReport.weeklygoal) * 100;
      return Percentage.toFixed(3);
    }
    return 0;
  };

 export const calculateArrearPaid = (CashRegisterReport) => {
    if (
      CashRegisterReport &&
      CashRegisterReport.arrearstotal &&
      CashRegisterReport.weeklygoal !== 0
    ) {
      const Percentage =
        (CashRegisterReport.arrearstotal / CashRegisterReport.weeklygoal) * 100;
      return Percentage.toFixed(3);
    }
    return 0;
  };
