import { recalculateDueDate, calculateOpenAmount, calculateOpenAmountTotal, calculateOpenArrear, calculateOpenInterest, hasFreshPayment, calculateRisk } from "../Helper/LoanHelpers"
import { addHours, formatISO, isPast, parseISO, setHours, setMinutes } from 'date-fns';
import React, { useContext, useRef, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { NovaContext } from '../context';
import Modal from './Modal';
import { checkAccess, getTimeDifferenceInHours } from "../Helper/GlobalHelpers";

const New_Payment = (props) => {

    const {
        loan,
        setLockStatus,
        lockStatus
    } = props;

    const { actions, filial, authenticatedUser, currentRole } = useContext(NovaContext);
    let navigate = useNavigate();
    const hasAuditorAccess = checkAccess(currentRole, 'Auditor');
    
    const [display, toggleDisplay] = useState(false);
    
    const [openLoanAmount, setOpenLoanAmount] = useState(0)
    const [openLoanArrear, setOpenLoanArrear] = useState(0)
    const [openArrear, setOpenArrear] = useState(0)
    const [openInterest, setOpenInterest] = useState(0)
    const [openTotalToBeQuit, setOpenTotalToBeQuit] = useState(0)
    const [openTotalToPay, setOpenTotalToPay] = useState(0)
    const [newDueDate, setNewDueDate] = useState()
    const [validationStatus, setValidationStatus] = useState(true)
    const isNewMode = true;

    const interest = useRef();
    const capital = useRef();
    const arrear = useRef();
    const total = useRef();

    const initializeForm = (value) => {
        // interest.current.value = '0';
        interest.current.value = value ? calculateOpenInterest(loan) : '0';
        arrear.current.value = '0';
        capital.current.value = '0';
        // total.current.value    = '0';
        total.current.value = value ? calculateOpenInterest(loan) : '0';
    };

    const handleClick = () => {
        //add a validation if "loan" is empty
        initializeForm(true)
        calculateAmountsToPay()
        calculateNewDueDate()
        toggleDisplay(!display)
    };

    const closeModalView = () => {
        initializeForm();
        toggleDisplay();
    };

    const handleView = (string) => {
        const path = `${string}`;
        navigate(path);
    };

    const calculateAmountsToPay = () => {
        const tempOpenInterest = calculateOpenInterest(loan)
        const tempOpenArrear = calculateOpenArrear(loan)
        const tempOpenTotal = calculateOpenAmountTotal(loan)
        const tempOpenAmount = calculateOpenAmount(loan)
        setOpenInterest(tempOpenInterest)
        setOpenArrear(tempOpenArrear)
        setOpenTotalToBeQuit(tempOpenAmount)
        setOpenTotalToPay(tempOpenTotal + tempOpenArrear)
    };

    const handleChange = () => {
        calculateNewDueDate()
        setValidationStatus(validate())
    }

    const calculateNewDueDate = () => {
        const calcOpenArrear = calculateOpenArrear(loan);
        const tempInterest = parseFloat(interest.current.value);
        const tempArrear = parseInt(arrear.current.value);
        const tempCapital = parseInt(capital.current.value);
        total.current.value = tempInterest + tempArrear + tempCapital;
        const tempOpenAmount = loan.loan_remaining - tempCapital;
        const oldDueDate = parseISO(loan.loan_datedue)
        const minInterestValue = Math.round(loan.loan_remaining * 0.15)

        var tempWeeksChange, tempNewDueDate, tempOpenArrear;
        if (tempOpenAmount === 0) {
            tempWeeksChange = 0;
            tempNewDueDate = (((tempInterest % minInterestValue === 0) || (minInterestValue % tempInterest === 0)) && tempInterest > 0) ? recalculateDueDate(oldDueDate, tempWeeksChange) : oldDueDate;
            tempOpenArrear = calcOpenArrear - tempArrear;
        } else {
            tempWeeksChange = Math.round(tempInterest / minInterestValue);
            tempNewDueDate = (((tempInterest % minInterestValue === 0) || (minInterestValue % tempInterest === 0)) && tempInterest > 0) ? recalculateDueDate(oldDueDate, tempWeeksChange) : oldDueDate;
            tempOpenArrear = isPast(tempNewDueDate) ? loan.arrears + (tempWeeksChange * 120) - tempArrear : calcOpenArrear - tempArrear;
        }

        // console.log(isPast(tempNewDueDate),tempOpenArrear,(loan.arrears + (tempWeeksChange * 120) - tempArrear), (calcOpenArrear - tempArrear), tempNewDueDate)

        setOpenLoanAmount(tempOpenAmount)
        setOpenLoanArrear(tempOpenArrear >= 0 ? tempOpenArrear : 0)

        tempNewDueDate = setHours(tempNewDueDate, 13);
        tempNewDueDate = setMinutes(tempNewDueDate, 15);
        setNewDueDate(tempNewDueDate)
    }

    const validate = () => {
        const tempInterest = parseFloat(interest.current.value);
        const tempArrear = parseInt(arrear.current.value);
        const tempCapital = parseInt(capital.current.value);
        const tempTotal = parseFloat(total.current.value);
        const minInterestValue = Math.round(loan.loan_remaining * 0.15)
        var tempArrearExtra = 0;

        if (tempArrear > openArrear) {
            tempArrearExtra = tempArrear - openArrear
            //adding a limit so that the authenticatedUser can add a maximum of 40 pesos extra than the calculated
            if (tempArrearExtra > 40) {
                return false
            }
        }
        // console.log(tempCapital, tempArrear, tempInterest, tempTotal, openLoanAmount, openArrear, openInterest, openTotalToBeQuit, openTotalToPay, (tempInterest % openInterest), (openInterest % tempInterest))
        if (tempInterest > 0 || tempArrear > 0 || tempCapital > 0) {
            if ((tempTotal - tempArrearExtra) <= openTotalToPay) {
                if (hasAuditorAccess) {
                    return true
                } else {
                    if ((tempInterest > 0 && (tempInterest % minInterestValue === 0) && (tempInterest <= openInterest && tempInterest >= minInterestValue))) {
                        if (tempArrear >= 0) {
                            if (tempCapital >= 0 && tempCapital <= loan.loan_remaining)
                                return true
                        }
                    }
                    if (tempArrear > 0 && tempArrear <= openArrear && tempInterest === 0 && tempCapital === 0)
                        return true
                    if (tempCapital > 0 && tempInterest === 0 && tempArrear === 0)
                        return hasFreshPayment(loan)
                }
            }
        }
        return false
    }

    const handleCreate = async (e) => {
        e.preventDefault();
        if (lockStatus) {
            console.log('lockStatus is set - disable all activity');
        } else {
            setLockStatus(true);
            const validateCheck = validate();
            if (validateCheck) {
                const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
                // console.log(total.current.value, interest.current.value, arrear.current.value, capital.current.value, currentDate, openLoanAmount, newDueDate, openLoanArrear)
                console.log("current Date: ", currentDate);
                console.log("current date format: ", formatISO(currentDate));
                const loanId = loan.loan_id;
                const tempPayment =
                    {
                        "total": total.current.value,
                        "interest": interest.current.value,
                        "arrear": arrear.current.value,
                        "capital": capital.current.value,
                        "createdate": formatISO(currentDate),
                        "employeeId": authenticatedUser.employee_id,
                        "loanId": loanId,
                        "filialId": filial,
                        "openAmount": openLoanAmount,
                        "dateDue": formatISO(newDueDate),
                        "openArrear": openLoanArrear
                    }
                console.log(tempPayment);
                //add a process to store the Risk on the current Loan and aggregate it to the current Customer
                const risk = calculateRisk(loan)
                const body = {risk, loanId}
                const newRisk = await actions.addRiskToLoan(body);

                const newPayment = await actions.addPaymentToLoan(tempPayment);

                // console.log(newPayment);
                closeModalView();
                handleView(`/TicketPay/${newPayment.payment_id}`);
            }
            setLockStatus(false);
        }

    };

    return (
        <form onSubmit={handleCreate}>
            <Modal
                classNames={`modal ${display ? 'show' : ''}`}
                toggleModal={handleClick}
                buttonName={isNewMode ? 'Agregar pago' : 'Editar pago'}
                buttonClass={"btn mx-2 btn-primary btn-hover-light"}
                header={isNewMode ? 'Agregar pago' : 'Editar pago'}
                body={() => (
                    <Fragment>

                        {/* <div className="grid-pagecontainer"> */}
                        <div className="pageitemHeader">
                            <h5 className="text-left">{`Capital pendiente: ${loan.loan_remaining} pesos`}</h5>
                        </div>
                        {/* <div className="pageitemMain"> */}
                        <div className="grid-container-form">

                            <label>Refrendo
                                <input type="number"
                                    className="form-control text-right"
                                    ref={interest}
                                    min="0.00"
                                    step="0.5"
                                    presicion={2}
                                    onChange={(e) => handleChange(e)}
                                />
                            </label>

                            <label>Mora
                                <input type="number"
                                    className="form-control text-right"
                                    ref={arrear}
                                    onChange={(e) => handleChange(e)}
                                />
                            </label>

                            <label>Capital
                                <input type="number"
                                    className="form-control text-right"
                                    ref={capital}
                                    onChange={(e) => handleChange(e)}
                                />
                            </label>

                            <label>Total a pagar
                                <input type="number"
                                    className={`form-control text-right ${!validationStatus ? 'validation-error' : ''}`}
                                    ref={total}
                                    min="0.00"
                                    step="0.5"
                                    presicion={2}
                                    required
                                    onChange={(e) => handleChange(e)}
                                    readOnly={true}
                                />
                            </label>

                        </div>
                        {/* </div>
                                </div> */}
                    </Fragment>
                )}

                footer={() => (
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-success"
                            disabled={lockStatus ? true : false}
                        // onClick={(e) => handleCreate(e)}
                        >
                            Continuar
                        </button>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => closeModalView()}
                            disabled={lockStatus ? true : false}
                        >
                            Cancelar
                        </button>
                    </Fragment>
                )}
            />
        </form>
    )

}

export default New_Payment;