import { PAYMENTS as payments } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${payments}`;
const accessToken = getCookie(NOVACOOKIE);

export const getPayments = async (params) => {
    console.log(endpoint, params);
    
    return await
        sendRequest.request(
            `${endpoint}${params}`,
            GET,
            null,
            accessToken,
            null
        )
            .then((resp) => {
                return resp.data;
            })
            .catch(err => {
                console.log("api err: ", err)
                return err
            });
    // return this.validationStatusError(err);
}

// validation status error?
// return this.validationStatusError(err);

export const deletePayment = async (paymentId) => {
    return await
        sendRequest.request(
            `${endpoint}/${paymentId}`,
            DELETE,
            null,
            accessToken,
            null
        )
            .then((resp) => resp.data)
            // .catch(err => {
            //     console.log("api ", err)
            // });
            // need a better methodology for error handling 10.10.24
            .catch(err => {return {code: err.response.status, text: err.response.data}})
}