import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Grid2 as Grid } from "@mui/material";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";
import {
  RiskStatusLabel,
  RiskWithLabel,
  calculateAverageRisk,
  calculateRisk,
  formatRiskIconWithLabel,
} from "../../components/Helper/LoanHelpers";

const CustomerInfoBox = ({ customer, loansOnMeter, currentLoan }) => {
  const riskAverage = calculateAverageRisk(currentLoan, customer.riskaverage);

  const customerStatusTooltip = () => {
    const color =
      customer.status === "BLOCKED" || loansOnMeter.length > 0
        ? "error"
        : customer.status === "ACTIVE"
        ? "warning"
        : "success";

    let icon = "";
    if (loansOnMeter.length > 0) {
      icon = <InfoIcon color={color} />;
    } else {
      switch (customer.status) {
        case "ACTIVE":
          icon = <WarningIcon color={color} sx={{ fontSize: 65 }} />;
          break;
        case "INACTIVE":
          icon = <CheckCircleIcon color={color} sx={{ fontSize: 65 }} />;
          break;
        case "BLOCKED":
          icon = <InfoIcon color={color} sx={{ fontSize: 65 }} />;
          break;
        default:
          break;
      }
    }

    const text =
      customer.status === "BLOCKED"
        ? "Este cliente esta bloqueado."
        : loansOnMeter.length > 0
        ? "Ya existe un crédito activo usando el mismo #Medidor."
        : customer.status === "ACTIVE"
        ? "Este cliente tiene un crédito activo."
        : "Este cliente es inactivo";

    return (
      <Tooltip title={text} placement="left" arrow={true}>
        {icon}
      </Tooltip>
    );
  };

  return (
    <ItemBox sx={{}}>
      <Grid container spacing={0.5}>
        <Grid size={12}>
          <Grid container>
            <Grid size={10}>
              <Typography
                fontSize={{ xs: 20, md: 30 }}
                align="left"
                component="div"
                sx={{ ml: 1 }}
              >
                <Box sx={{}}>
                  {`${customer.customer_name} ${customer.customer_lastname}`}
                </Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              <Box>{customerStatusTooltip()}</Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Grid
            container
            spacing={1}
            padding={1}
            // margin={2}
            alignItems="flex-end"
          >
            <Grid container size={9} spacing={1}>
              <Grid size={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left" component="div">
                  <Box>#Medidor:</Box>
                </Typography>
                <Typography variant="h6" align="left" component="div">
                  <Box sx={{ ml: 2 }}>{customer.customer_meternumber}</Box>
                </Typography>
              </Grid>
              <Grid size={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left" component="div">
                  <Box>Riesgo promedio:</Box>
                </Typography>

                <Typography variant="h6" align="left" component="div">
                  <Box sx={{ ml: 2 }}>
                    {formatRiskIconWithLabel(riskAverage)}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
            <Grid size={3}>
              <Typography
                fontSize={{ xs: 32, md: 40 }}
                // variant="h3"
                align="right"
                component="div"
                // sx={{ ml: 1 }}
              >
                <Box sx={{}}>ID {customer.customer_idnumber}</Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ItemBox>
  );
};

export default CustomerInfoBox;
