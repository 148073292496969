import React, { useContext, useMemo, useState } from "react";
import { NovaContext } from "../../components/context/index";
import { Link } from "react-router-dom";

// Internal import
import { WeekDayDueToPayment } from "../../components/Helper/Enums";
import {
  calculateDelayedWeeks,
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
  calculateRisk,
  formatRiskIcon,
} from "../../components/Helper/LoanHelpers";
import {
  checkAccess,
  generalSortComparatorNumbers,
  getLoanStatusIcon,
} from "../../components/Helper/GlobalHelpers";

// Material UI import
import Box from "@mui/system/Box";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import {
  CustomGridToolbar,
  CustomDataGrid,
} from "../../components/Helper/MUIStyledComponents";

// Date-fns imports
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { IconButton, Typography } from "@mui/material";
import ModeComment from "../../components/common/ModeComment";

const LoanList = React.memo((props) => {
  const {
    loans,
    viewDetails,
    toggleDialog,
    hideableColumns,
    visibilityColumns,
  } = props;
  const { currentRole } = useContext(NovaContext);
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  // console.log(hideableColumns);
  const processedLoans = useMemo(() => {
    if (loans) {
      // console.log("Loans:", loans);
      return loans.map((item) => ({
        ...item,
        fullName: `${item.customer_name} ${item.customer_lastname}`,
      }));
    }
    return [];
  }, [loans]);

  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const getRiskValue = (dataArray, loanId) => {
    const risk = calculateRisk(
      dataArray.find((processedLoans) => processedLoans.loan_id === loanId)
    );
    return risk;
  };

  const RiskIcon = (dataArray, loanId) => {
    const risk = loanId ? getRiskValue(dataArray, loanId) : 0;
    return formatRiskIcon(risk);
  };

  const prepareColumns = (dataArray) => {
    const columns = [
      {
        field: "accionPago",
        headerName: "",
        align: "center",
        width: 10,
        sortable: false,
        hideable: false,
        disableExport: true,
        renderCell: (params) => {
          const viewPaymentDialog = (e) => {
            e.stopPropagation();
            toggleDialog(params?.row);
          };
          return (
            <Box display="flex" alignItems={"center"}>
              <Tooltip title="Agregar pago" placement="right" arrow>
                <IconButton
                  // autoFocus
                  color="primary"
                  variant="contained"
                  size="small"
                  data-target={`#id${params?.row.loan_id}`}
                  onClick={viewPaymentDialog}
                >
                  {""}
                  <RequestQuoteOutlinedIcon
                    variant="contained"
                    fontSize="large"
                  />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: "accionDetalles",
        headerName: "",
        align: "center",
        width: 10,
        sortable: false,
        hideable: false,
        disableExport: true,
        renderCell: (params) => {
          const viewLoanDetails = (e) => {
            e.stopPropagation();
            viewDetails(params?.row);
          };

          return (
            <Box display="flex" alignItems={"center"}>
              <Tooltip title="Lista de pagos" placement="right" arrow>
                <IconButton
                  // autoFocus
                  color="primary"
                  variant="contained"
                  size="small"
                  data-target={`#id${params?.row.loan_id}`}
                  onClick={viewLoanDetails}
                >
                  {""}
                  {/* removed 10.10 - ToDo: change backend query */}
                  {/* ToDo: on click of another row acivate that row */}
                  {/* <InfoOutlinedIcon fontSize="large" /> */}
                  <ModeComment count={params?.row.payments} size={12} />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        field: "customer_idnumber",
        headerName: "ID",
        align: "center",
        type: "number",
        width: "50",
        hideable: false,
        headerClassName: "GridHeader",
      },
      {
        field: "status",
        headerName: "ESTADO",
        headerClassName: "GridHeader",
        headerAlign: "center",
        width: "50",
        sortable: false,
        hideable: false,
        renderCell: (params) => {
          return getLoanStatusIcon(params?.row.loan_status);
          //   return params.row.loan_status === "CLOSE" ? (
          //     <CheckIcon color="success" />
          //   ) : (
          //     <WarningIcon color="warning" />
          //   );
        },
        valueGetter: (value) => value,
      },
      {
        field: "risk",
        headerName: "Riesgo",
        headerClassName: "GridHeader",
        // disableExport: true,
        headerAlign: "center",
        align: "center",
        width: "80",
        type: "number",
        renderCell: (params) => {
          return RiskIcon(dataArray, params?.id);
        },
        valueGetter: (value, row) => {
          return getRiskValue(dataArray, row.loan_id);
        },
        // sortComparator: generalSortComparatorNumbers(
        //   (params) => getRiskValue(dataArray, params?.id),
        //   (params) => getRiskValue(dataArray, params?.id)
        // ),
      },
      // extra column to show the payment progress of a customer
      // {
      //   field: "progress",
      //   headerName: "Estado",
      //   headerClassName: "GridHeader",
      //   disableExport: true,
      //   // renderEditCell: renderEditProgress,
      //   align: "center",
      //   width: "80",
      //   renderCell: (params) => {
      //     return RiskIcon(dataArray, params.id);
      //   },
      // },
      {
        field: "fullName",
        headerName: "Cliente",
        headerClassName: "GridHeader",
        type: "string",
        flex: 1,
        minWidth: 150,
        maxWidth: 300,
      },
      {
        field: "customer_telephone",
        headerName: "Telefono",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 100,
        maxWidth: 120,
      },
      {
        field: "loan_amount",
        headerName: "Capital inicial",
        headerClassName: "GridHeader",
        headerAlign: "right",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        valueFormatter: (value) => `$${value}`,
      },
      {
        field: "loan_dueday",
        headerName: "Dia de pago",
        headerClassName: "GridHeader",
        flex: 1,
        align: "center",
        type: "string",
        minWidth: 80,
        maxWidth: 120,
        valueFormatter: (value) => WeekDayDueToPayment[value - 1].label,
      },
      {
        field: "loan_remaining",
        headerName: "Capital pendiente",
        headerClassName: "GridHeader",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        valueFormatter: (value) => `$${value}`,
      },
      {
        field: "interest",
        headerName: "Refrendo",
        headerClassName: "GridHeader",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        renderCell: (params) => {
          return `$${calculateOpenInterest(params?.row)}`;
        },
        valueGetter: (value, row) => `${calculateOpenInterest(row)}`,
      },
      {
        field: "totalpending",
        headerName: "Total pendiente",
        headerClassName: "GridHeader",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        renderCell: (params) => {
          return `$${calculateOpenAmountTotal(params?.row)}`;
        },
        valueGetter: (value, row) => `${calculateOpenAmountTotal(row)}`,
      },
      {
        field: "arrears",
        headerName: "Mora",
        headerClassName: "GridHeader",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        renderCell: (params) => {
          return `$${calculateOpenArrear(params?.row)}`;
        },
        valueGetter: (value, row) => `${calculateOpenArrear(row)}`,
      },
      {
        field: "delayedWeeks",
        headerName: "Atraso",
        headerClassName: "GridHeader",
        align: "center",
        type: "number",
        flex: 1,
        minWidth: 80,
        maxWidth: 120,
        renderCell: (params) => {
          return calculateDelayedWeeks(params?.row);
        },
        valueGetter: (value, row) => `${calculateDelayedWeeks(row)}`,
      },
      {
        field: "loan_dateissue",
        headerName: "Fecha inicial",
        headerClassName: "GridHeader",
        type: "date",
        flex: 1,
        minWidth: 100,
        maxWidth: 120,
        valueFormatter: (value) => format(parseISO(value), "dd/MM/yyyy"),
      },
      {
        field: "loan_datedue",
        headerName: "Fecha de pago",
        headerClassName: "GridHeader",
        type: "date",
        flex: 1,
        minWidth: 100,
        maxWidth: 120,
        valueFormatter: (value) => format(parseISO(value), "dd/MM/yyyy"),
      },
      {
        field: "customer_location",
        headerName: "Localidad",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 120,
        maxWidth: 350,
      },
      {
        field: "customer_address",
        headerName: "Direccion",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 250,
        maxWidth: 400,
      },
      {
        field: "loan_employeeid",
        headerName: "Empleado",
        headerClassName: "GridHeader",
        flex: 1,
        minWidth: 250,
        maxWidth: 350,
        renderCell: (params) => {
          return (
            <Link to={`/Employees/${params?.row.loan_employeeid}`}>
              {params?.row.employee_fullname}
            </Link>
          );
        },
      },
    ];

    // Set default visibility for all columns
    columns.forEach((column) => {
      if (visibilityColumns.includes(column.field)) {
        columnVisibilityModel[column.field] = false; // or false, based on your default
      }
      // else {
      //   columnVisibilityModel[column.field] = true; // hide if not in hideableColumns
      // }
    });

    return [...columns];
  };

  const prepareRows = (dataArray) => {
    return dataArray && dataArray[0]
      ? dataArray?.map((item) => {
          return {
            ...item,
          };
        })
      : [];
  };

  const columnVisibilityModel = {};

  return (
    // <Box>
      <Box height="100%" width="100%">
      <CustomDataGrid
        columns={prepareColumns(processedLoans)}
        rows={prepareRows(processedLoans)}
        slots={{
          toolbar: CustomGridToolbar,
        }}
        getRowId={(row) => row.loan_id}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        filterModel={filterModel}
        initialState={{
          columns: {
            columnVisibilityModel,
          },
          filter: {
            filterModel: filterModel,
          },
        }}
      />
    </Box>
  );
});

export default LoanList;
