import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography, Box, InputAdornment } from "@mui/material";
import Controls from "../../components/controls/Controls";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid2/Grid2";
import FilialPaperCard from "./FilialPaperCardWithGoal";
import {
  calculateReportDates,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";
import { addHours } from "date-fns";

const useStyles = makeStyles((theme) => ({
  overviewText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "3rem !important",
  },
}));

const Index = () => {
  const { actions, filial, currentRole } = useContext(NovaContext);
  const [filials, setFilials] = useState([]);
  const [search, setSearch] = useState("");
  const [reportMode, setReportMode] = useState("WEEK");
  const [dataRange, setDataRange] = useState({});

  const classes = useStyles();

  let navigate = useNavigate();

  useEffect(() => {
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
    const fetchData = async () => {
      const filiallist = actions.getFilials().then((data) => {
        console.log(data);
        setFilials(data);
      });

      const DataRangeTemp = calculateReportDates(reportMode, currentDate);
      setDataRange(DataRangeTemp);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filials.length === 1) {
      handleView(filials[0].filial_id);
    }
  }, [filials]);

  const handleView = (filialId) => {
    actions.setActiveFilial(filialId);
    const path = `/Filial/${filialId}`;
    navigate(path);
    return null;
  };

  const filteredFilials = filials.filter((filial) =>
    filial.filial_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }} spacing={2}>
      {filials.length >= 1 ? (
        <Grid
          container
          size={12}
          display="flex"
          justifyContent="left"
          alignItems="left"
        >
          <Grid
            container
            size={12}
            display="flex"
            justifyContent="left"
            alignItems="left"
          >
            <Controls.Input
              label="Buscar"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
              slots={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            container size={12}
          >
            {filteredFilials.map((filial, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                <FilialPaperCard
                  filialObj={filial}
                  triggerPage={() => handleView(filial.filial_id)}
                  dataRange={dataRange}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Typography className={classes.overviewText}>
          Ninguna sucursal asignada a este usuario.
        </Typography>
      )}
    </Grid>
  );
};
export default Index;

{
  /* <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }}>
      {filials && filials.length === 1 ? (
        handleView(filials[0].filial_id)
      ) : filials && filials.length > 1 ? (
        <div>
          <Controls.Input
            label="Buscar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            slots={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Grid container spacing={2}>
            {filteredFilials.map((filial, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                <FilialPaperCard
                  filialObj={filial}
                  triggerPage={() => handleView(filial.filial_id)}
                  dataRange={dataRange}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : (
        <Typography className={classes.overviewText}>
          Ninguna sucursal asignada a este usuario.
        </Typography>
      )}
    </Grid> */
}
