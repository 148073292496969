import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Grid2 as Grid } from "@mui/material";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";
import {
  RiskStatusLabel,
  RiskWithLabel,
  calculateAverageRisk,
  calculateRisk,
  formatRiskIconWithLabel,
} from "../../components/Helper/LoanHelpers";

const EmployeeInfoBox = ({ employee }) => {
console.log(employee);

  return (
    <ItemBox sx={{}}>
      <Grid container spacing={0.5}>
        <Grid size={12}>
          <Grid container>
            <Grid size={10}>
              <Typography
                fontSize={{ xs: 20, md: 30 }}
                align="left"
                component="div"
                sx={{ ml: 1 }}
              >
                <Box sx={{}}>
                  {`${employee.employee_name}`}
                </Box>
              </Typography>
            </Grid>
            <Grid size={2} align="right">
              {/* <Box>{customerStatusTooltip()}</Box> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <Grid
            container
            spacing={1}
            padding={1}
            // margin={2}
            alignItems="flex-end"
          >
            <Grid container size={9} spacing={1}>
              <Grid size={12} sx={{ display: "flex", alignItems: "center" }}>
              ID: {employee.employee_id}
              </Grid>
            </Grid>
            <Grid size={3}>
              {/* <Typography
                fontSize={{ xs: 32, md: 40 }}
                // variant="h3"
                align="right"
                component="div"
                // sx={{ ml: 1 }}
              >
                <Box sx={{}}>ID {employee.employee_id}</Box>
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ItemBox>
  );
};

export default EmployeeInfoBox;
