export const DateRangeActions = {
    SET_DATE_RANGE_FILTER: 'SET_DATE_RANGE_FILTER'
}

export const DateRangeReducer = (state, action) => {
    switch (action.type) {
        case DateRangeActions.SET_DATE_RANGE_FILTER:
            return {
                ...state,
                start: action.payload.start,
                end: action.payload.end
            };
        default:
            return state;
    }
};