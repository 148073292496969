import { addHours, formatISO } from "date-fns";
import Address from "ipaddr.js";
import React, { useState, useRef, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../context";
import Dropdown from "../Dropdown";
import { GenderOptions } from "../Helper/Enums";
import Modal from "./Modal";
import ValidateCustomer from "../validation/validateCustomer";
import { checkAccess, checkRegionalManagerAccess, checkManagerAccess, getTimeDifferenceInHours } from "../Helper/GlobalHelpers";
import { format, parseISO } from "date-fns";
import { Button } from "@mui/material";

const NewEditCustomer = (props) => {

    const {
        selectedCustomer,
        modalClass,
        setLockStatus
    } = props

    const { actions, filial, currentRole } = useContext(NovaContext);

    const [genderError, setGenderError] = useState(false);
    const hasAdminAccess = checkAccess(currentRole,'Administrador');
    const hasManagerAccess = checkAccess(currentRole,'Gerente');
    const hasRegionalManagerAccess = checkAccess(currentRole,'Gerente Regional');

    const [display, toggleDisplay] = useState(false);
    const validateCustomer = new ValidateCustomer();
    const [gender, setGender] = useState('0');
    const [errors, setErrors] = useState([]);
    const isNewMode = !selectedCustomer;
    let navigate = useNavigate();

    // Set up refs for the react form -> refs are automatically updated when bound
    const firstName = useRef();
    const lastName = useRef();
    const dob = useRef();
    const location = useRef();
    const telephone = useRef();
    const password1 = useRef('');
    const password2 = useRef('');
    const meterNumber = useRef();
    const address = useRef();
    const comment = useRef();

    const initializeForm = (Object) => {
        // I don't think is needed anymore 14/9/2023
        const formClass = "form-control";
        // firstName.current.value = Object ? (Object.customer_name ? Object.customer_name : '') : '';
        // firstName.current.className = formClass;
        // lastName.current.value = Object ? (Object.customer_lastname ? Object.customer_lastname : '') : '';
        // lastName.current.className = formClass;
        // location.current.value = Object ? (Object.customer_location ? Object.customer_location : '') : '';
        // location.current.className = formClass;
        // dob.current.value = Object ? (Object.dob ? format(parseISO(Object.dob), 'yyyy-MM-dd') : '') : '';
        // dob.current.className = formClass;
        // telephone.current.value = Object ? (Object.customer_telephone ? Object.customer_telephone : '') : '';
        // telephone.current.className = formClass;
        // meterNumber.current.value = Object ? (Object.customer_meternumber ? Object.customer_meternumber : '') : '';
        // meterNumber.current.className = formClass;
        // address.current.value = Object ? (Object.customer_address ? Object.customer_address : '') : '';
        // address.current.className = formClass;
        // comment.current.value = Object ? (Object.customer_comment ? Object.customer_comment : '') : '';
        // comment.current.className = formClass;
        // setGender(Object ? (Object.customer_sex ? Object.customer_sex : '') : '');
    };

    const handleClick = async () => {
        if (!isNewMode) {
            await actions.getCustomer(selectedCustomer.customer_id)
                            .then((data) => {
                                initializeForm(data);
                            });
        }
        toggleDisplay(!display);
    }

    const closeModalView = () => {
        clearFields();
        toggleDisplay();
    }

    const clearFields = () => {
        setErrors([]);
        initializeForm();
    }

    const handleChange = (event, section) => {
        const value = event.target.value;
        switch (section) {
            case 'gender': setGender(value); break;
            default: break;
        }
        setGenderError(!validateCustomer.validateGender(event.target.value));
    };

    const validateData = () => {
        setErrors([]);
        const validClass = 'form-control is-valid';
        const invalidClass = 'form-control is-invalid';
        let isValid = true;

        const firstnamevalidateMsg = validateCustomer.validateName(firstName.current.value)
        if (firstnamevalidateMsg !== '') {
            setErrors(arr => [...arr, firstnamevalidateMsg]);
            firstName.current.className = invalidClass;
            isValid = false;
        } else {
            firstName.current.className = validClass;
        }

        const lastnamevalidateMsg = validateCustomer.validateName(lastName.current.value)
        if (lastnamevalidateMsg !== '') {
            setErrors(arr => [...arr, lastnamevalidateMsg]);
            lastName.current.className = invalidClass;
            isValid = false;
        } else {
            lastName.current.className = validClass;
        }

        // if the flag for gender is not empty then a class is being set on the drop down 
        if (genderError || !validateCustomer.validateGender(gender)) {
            const gendervalidateMsg = 'El Genero es requerido';
            setErrors(arr => [...arr, gendervalidateMsg]);
            isValid = false;
        }

        if (!validateCustomer.validateMeter(meterNumber.current.value)) {
            const metervalidateMsg = 'El # Medidor es requerido';
            setErrors(arr => [...arr, metervalidateMsg]);
            meterNumber.current.className = invalidClass;
            isValid = false;
        } else {
            meterNumber.current.className = validClass;
        }

        if (isNewMode) {
            const validatePassword1Msg = validateCustomer.validatePassword(password1.current.value);
            if (validatePassword1Msg !== '') {
                setErrors(arr => [...arr, validatePassword1Msg]);
                password1.current.className = invalidClass;
                isValid = false;
            } else {
                password1.current.className = validClass;
            }
            const validatePassword2Msg = validateCustomer.validatePassword(password2.current.value);
            if (validatePassword2Msg !== '') {
                setErrors(arr => [...arr, validatePassword2Msg]);
                password2.current.className = invalidClass;
                isValid = false;
            } else {
                password2.current.className = validClass;
                const validateBothPasswordMsg = validateCustomer.validateBothPasswords(password1.current.value, password2.current.value);
                if (validateBothPasswordMsg !== '') {
                    setErrors(arr => [...arr, validateBothPasswordMsg]);
                    password1.current.className = invalidClass;
                    password2.current.className = invalidClass;
                    isValid = false;
                } else {
                    password1.current.className = validClass;
                    password2.current.className = validClass;
                }
            }
        }

        if (!validateCustomer.validateAddress(address.current.value)) {
            const addressvalidateMsg = 'La dirección es requerida';
            setErrors(arr => [...arr, addressvalidateMsg]);
            address.current.className = invalidClass;
            isValid = false;
        } else {
            address.current.className = validClass;
        }
        
        return isValid;
    }

    const validateAttribute = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        const validClass = 'form-control is-valid';
        const invalidClass = 'form-control is-invalid';

        switch (id) {
            case "firstName":
                e.target.className = validateCustomer.validateName(value) === '' ? validClass : invalidClass;
                break;
            case "lastName":
                e.target.className = validateCustomer.validateName(value) === '' ? validClass : invalidClass;
                break;
            case "meter":
                e.target.className = validateCustomer.validateMeter(value) ? validClass : invalidClass;
                break;
            case "address":
                e.target.className = validateCustomer.validateAddress(value) ? validClass : invalidClass;
                break;
            case "passwordOne":
                e.target.className = validateCustomer.validatePassword(value) === '' ? validClass : invalidClass;
                break;
            case "passwordTwo":
                const isValid = validateCustomer.validateBothPasswords(value, password1.current.value) === '' ? true : false;
                e.target.className = isValid ? validClass : invalidClass;
                password1.current.className = isValid ? validClass : invalidClass;
                break;
        }

    }

    const handleCreate = async (e) => {
        e.preventDefault();
        setLockStatus(true);
        const currentDate = addHours(new Date(),+getTimeDifferenceInHours());
        const isEntryValid = validateData();
        if (isEntryValid) {
            const tempCustomer =
                {
                    "name": firstName.current.value,
                    "lastname": lastName.current.value,
                    "location": location.current.value,
                    "telephone": telephone.current.value,
                    "password": isNewMode ? password1.current.value : '',
                    "filialId": filial,
                    "gender": gender,
                    "address": address.current.value,
                    "meterNumber": meterNumber.current.value,
                    "comment": comment.current.value,
                    "createdDate": formatISO(currentDate),
                    "dob": dob.current.value ? formatISO(new Date(dob.current.value)) : "NULL"
                }

            const customer = isNewMode ?
                await actions.addCustomer(tempCustomer)
                : 
                await actions.updateCustomer(tempCustomer, selectedCustomer.customer_id);

            if (customer) {
                closeModalView();
                const path = `/Customers/${customer.customer_id}`;
                navigate(path);
            } else {
                console.log('Customer create/edit failed');
            }
        }
        setLockStatus(false);
    }

    return (
        (isNewMode || hasAdminAccess || hasManagerAccess) ?
            <form onSubmit={handleCreate}>
                <Modal
                    classNames={`${modalClass} ${display ? 'show' : ''}`}
                    toggleModal={handleClick}
                    buttonName={isNewMode ? 'Añadir' : 'Editar'}
                    buttonClass={"btn btn-primary btn-hover-light ml-2"}
                    header={`${isNewMode ? 'Añadir' : 'Editar'} cliente`}
                    body={() => (
                        <Fragment>
                            <div className="grid-container-form">

                                <label htmlFor="firstName" className="ItemFirstName">
                                    Nombre*:
                                    <input id="firstName" type="text" className="form-control" ref={firstName} onChange={validateAttribute} autoComplete="nope" disabled={!hasRegionalManagerAccess && !isNewMode}/>
                                </label>

                                <label htmlFor="lastName" className={`ItemLastName`} >
                                    Apellido*:
                                    <input id="lastName" type="text" className="form-control" ref={lastName} onChange={validateAttribute} autoComplete="nope" disabled={!hasRegionalManagerAccess && !isNewMode} />
                                </label>

                                <label htmlFor="dob">
                                    Fecha de nacimiento
                                    <input id="dob" type="date" className="form-control" ref={dob} />
                                </label>

                                <Dropdown
                                    className={`btn btn-outline-secondary align-left ${genderError ? 'error-input' : ''}`}
                                    label="Genero* "
                                    options={GenderOptions}
                                    value={gender}
                                    onChange={(e) => handleChange(e, 'gender')} 
                                    readOnly = {!hasRegionalManagerAccess && !isNewMode}/>

                                <label htmlFor="telephone" className="ItemTelephone">
                                    Telefono*:
                                    <input id="telephone" type="number" className="form-control" ref={telephone} autoComplete="nope" />
                                </label>

                                <label htmlFor="meter" className="ItemMeter">
                                    # Medidor*:
                                    <input id="meter" type="text" className="form-control" ref={meterNumber} onChange={validateAttribute} autoComplete="nope" disabled={!hasRegionalManagerAccess && !isNewMode} />
                                </label>

                                <label htmlFor="location" className="ItemLocation">
                                    Localidad*:
                                    <input id="location" type="text" className="form-control" ref={location} autoComplete="nope" disabled={!hasRegionalManagerAccess && !isNewMode} />
                                </label>

                                <label htmlFor="address" className="ItemAddress">
                                    Dirección*:
                                    <textarea id="address" type="text" className="form-control" ref={address} onChange={validateAttribute} autoComplete="nope" disabled={!hasRegionalManagerAccess && !isNewMode} />
                                </label>

                                <label htmlFor="comment" className="ItemComment">
                                    Comentario*:
                                    <textarea id="comment" type="text" className="form-control" ref={comment} autoComplete="nope" />
                                </label>

                                {isNewMode ?
                                    <label htmlFor="passwordOne" className="ItemPassword">
                                        Contraseña*:
                                        <input id="passwordOne" type="password" className="form-control" ref={password1} onChange={validateAttribute} />
                                    </label>
                                    : null}

                                {isNewMode ?
                                    <label htmlFor="passwordTwo" className="ItemPassword">
                                        Confirmar contraseña*:
                                        <input id="passwordTwo" type="password" className="form-control" ref={password2} onChange={validateAttribute} />
                                    </label>
                                    : null}

                                {errors && errors.length > 0 ?
                                    <ul>
                                        {errors.map((error, index) => {
                                            return <li
                                                key={index}
                                                className="error-text error-bullet-point">
                                                {error}
                                            </li>
                                        }
                                        )}
                                    </ul>
                                    :
                                    ''}
                            </div>
                        </Fragment>
                    )}
                    footer={() => (
                        <Fragment>
                            <Button type="submit" variant="contained" color="success" data-dismiss="modal" > Continuar </Button>
                            <Button type="button" variant="outlined" data-dismiss="modal" onClick={() => closeModalView()} > Cancelar </Button>
                        </Fragment>
                    )}

                />
            </form >
            :
            null
    )

};

export default NewEditCustomer;