import { LOANS as loans } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${loans}`;
const accessToken = getCookie(NOVACOOKIE);

export const getLoans = async (params) => {
    return await
        sendRequest.request(
            `${endpoint}?${params}`,
            GET,
            null,
            accessToken,
            null
        )
            .then(resp => resp.data)
            .catch(err => ({ "code": 401, "text": err }))
}

export const addLoan = async (loan) => {
    return await
        sendRequest.request(
            endpoint,
            POST,
            loan,
            accessToken,
            null
        )
            .then(resp => resp)
            .catch(err => console.log(`An error occured: ${err}`))
}

export const deleteLoan = async (id) => {
    return await
        sendRequest.request(
            `${endpoint}/${id}`,
            DELETE,
            null,
            accessToken,
            null
        )
            .then(resp => resp.data)
            .catch(err => err.response)
}