import { toast } from "react-toastify";

export const displayAlert = (type, content) => {
    switch (type) {
        case 'warning':
            return toast.warning(content);
        case 'error':
            return toast.error(content);
        case 'success':
            return toast.success(content);
        case 'info':
            return toast.info(content);
        default:
            return toast(content);
    }
}

export const alerts = {
    WARNING: "warning",
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info",
}
