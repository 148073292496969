
export const ExpensesActions = {
    SET_EXPENSES: 'SET_EXPENSES',
    CREATE_EXPENSE: 'CREATE_EXPENSE',
    REMOVE_EXPENSE: 'REMOVE_EXPENSE'
}

export const ExpensesReducer = (state, action) => {
    switch (action.type) {
        case ExpensesActions.SET_EXPENSES:
            return action.payload;
        case ExpensesActions.CREATE_EXPENSE:
            return [action.payload, ...state];
        case ExpensesActions.REMOVE_EXPENSE:
            return state.filter((expense) => expense.id !== action.payload);
        default:
            return state;
    }
}