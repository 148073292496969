// Utility import
import { format, parseISO } from "date-fns";
import React, { Fragment, useContext } from "react";
import { TiCancel } from "react-icons/ti";
import { NovaContext } from "../context";
import { checkAccess } from "../Helper/GlobalHelpers";
import Button from "./Button";

// Component import
const ListPayChecksBySearch = (props) => {

    const {
        paychecks,
        selectAction
    } = props;

    const { currentRole } = useContext(NovaContext);
    const hasAuditorAccess = checkAccess(currentRole, 'Auditor');

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                    <thead>
                        <tr>
                            <th>Responsable</th>
                            <th>Cantidad</th>
                            <th>Fecha de transacción</th>
                            <th>Descripcion</th>
                            {
                                hasAuditorAccess ?
                                    <th>Revocar</th> : null
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {paychecks && paychecks.filter((val) => { return val })
                            .map((paycheck, i) => (
                                <tr key={i}>
                                    <td>{paycheck.employee_name} {paycheck.employee_lastname}      </td>
                                    <td>{paycheck.amount}  </td>
                                    <td>{format(parseISO(paycheck.createdate), "dd/MM/yyyy HH:mm:ss")} hrs                            </td>
                                    <td>{paycheck.description}      </td>
                                    <td>
                                        {
                                            hasAuditorAccess ?
                                                <Button
                                                    type={"button"}
                                                    nameOfClass={"btn ml-2 btn-warning btn-hover-light icon-m alarm"}
                                                    clickBehaviour={() => selectAction(paycheck.id, 'RevokePayCheck')}
                                                    text={""}
                                                    icon={<TiCancel />}
                                                />
                                                : null
                                        }
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
};

export default ListPayChecksBySearch;