import React, { Fragment, useState, useContext, useEffect } from "react";
import ListPaymentsBySearch from "../components/Actions/ListPaymentsBySearch";
import ListExpenses from "../components/Actions/ListExpenses";
import { NovaContext } from "../components/context";
import Tabs from "../components/Tabs";
import ListLoansBySearch from "../components/Actions/ListLoanBySearch";
import ListCashFlowsBySearch from "../components/Actions/ListCashFlowsBySearch";
import ListPayChecksBySearch from "../components/Actions/ListPayChecksBySearch";
import ListAuditTrail from "../components/Actions/ListAuditTrailBySearch";
import { checkAccess } from "../components/Helper/GlobalHelpers";
import Confirmation from "../components/popups/Confirmation";
import Grid from "@mui/material/Grid2";
import {  ItemPaper } from "../components/Helper/MUIStyledComponents";

const Reports = () => {
  // Import Context-API‚
  const { actions, filial, currentRole } = useContext(NovaContext);
  const [loans, setLoans] = useState([]);
  const [payments, setPayments] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [paychecks, setPayChecks] = useState([]);
  const [cashflows, setCashFlows] = useState([]);
  const [audittrailList, setAudittrailList] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedLoan, setSelectedLoan] = useState("");
  const [selectedExpense, setSelectedExpense] = useState("");
  const [selectedPayCheck, setSelectedPayCheck] = useState("");
  const [selectedCashFlow, setSelectedCashFlow] = useState("");
  const [errors, setErrors] = useState([]);

  const filterStatus = "OPEN";
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasManagerAccess = checkAccess(currentRole, "Gerente");
  const loansSorting = "lo.loan_dateissue DESC ";

  // States for pop up types
  const [activePopUp, setActivePopUp] = useState(false);
  const [lockStatus, setLockStatus] = useState(false);

  const [confirmLoanRevoke, setConfirmLoanRevoke] = useState(false);
  const [confirmPaymentRevoke, setConfirmPaymentRevoke] = useState(false);
  const [confirmExpenseRevoke, setConfirmExpenseRevoke] = useState(false);
  const [confirmCashFlowRevoke, setConfirmCashFlowRevoke] = useState(false);
  const [confirmPayCheckRevoke, setConfirmPayCheckRevoke] = useState(false);

  useEffect(() => {
    actions.getPayments().then((data) => setPayments(data));
    actions.getExpenses().then((data) => setExpenses(data));
    actions.getLoansOpen(null, loansSorting).then((data) => setLoans(data));
    actions.getCashFlows().then((data) => setCashFlows(data));
    actions.getPayChecks().then((data) => setPayChecks(data));
    actions
      .getAuditTrail("filial", null)
      .then((data) => setAudittrailList(data));
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAction = (Id, action) => {
    switch (action) {
      case "RevokePayment":
        setSelectedPayment(Id);
        setConfirmPaymentRevoke(true);
        break;
      case "RevokeLoan":
        setSelectedLoan(Id);
        setConfirmLoanRevoke(true);
        break;
      case "RevokeExpense":
        setSelectedExpense(Id);
        setConfirmExpenseRevoke(true);
        break;
      case "RevokePayCheck":
        setSelectedPayCheck(Id);
        setConfirmPayCheckRevoke(true);
        break;
      case "RevokeCashFlow":
        setSelectedCashFlow(Id);
        setConfirmCashFlowRevoke(true);
        break;
      default:
        break;
    }
    setActivePopUp(true);
  };

  const deactivatePopUp = (windowName) => {
    setErrors([]);
    switch (windowName) {
      case "RevokePayment":
        setConfirmPaymentRevoke(false);
        break;
      case "RevokeLoan":
        setConfirmLoanRevoke(false);
        break;
      case "RevokeExpense":
        setConfirmExpenseRevoke(false);
        break;
      case "RevokePayCheck":
        setConfirmPayCheckRevoke(false);
        break;
      case "RevokeCashFlow":
        setConfirmCashFlowRevoke(false);
        break;
      default:
        break;
    }

    setActivePopUp(false);
  };

  const handlePaymentRevoke = (e) => {
    e.preventDefault();
    let tempErrors = [];
    actions
      .revokePayment(selectedPayment)
      .then((resp) => {
        if (resp.status === 200) {
          setPayments(
            payments.filter((payment) => payment.payment_id !== selectedPayment)
          );
          setSelectedPayment("");
          deactivatePopUp("RevokePayment");
        } else {
          tempErrors.push(resp.response.data);
          setErrors(tempErrors);
        }
      })
      .catch((err) => {
        errors.push(err);
      });
  };

  const handleLoanRevoke = (e) => {
    e.preventDefault();
    let tempErrors = [];
    actions
      .revokeLoan(selectedLoan)
      .then((resp) => {
        if (resp.status === 200) {
          setLoans(loans.filter((loan) => loan.loan_id !== selectedLoan));
          setSelectedLoan("");
          deactivatePopUp("RevokeLoan");
        } else {
          tempErrors.push(resp.response.data);
          setErrors(tempErrors);
        }
      })
      .catch((err) => {
        errors.push(err);
      });
  };

  const handleExpenseRevoke = (e) => {
    e.preventDefault();
    let tempErrors = [];
    actions
      .revokeExpense(selectedExpense)
      .then((resp) => {
        if (resp.status === 200) {
          setExpenses(
            expenses.filter((expense) => expense.id !== selectedExpense)
          );
          setSelectedExpense("");
          deactivatePopUp("RevokeExpense");
        } else {
          tempErrors.push(resp.response.data);
          setErrors(tempErrors);
        }
      })
      .catch((err) => {
        errors.push(err);
      });
  };

  const handlePayCheckRevoke = (e) => {
    e.preventDefault();
    let tempErrors = [];
    actions
      .revokePayCheck(selectedPayCheck)
      .then((resp) => {
        if (resp.status === 200) {
          setPayChecks(
            paychecks.filter((paycheck) => paycheck.id !== selectedPayCheck)
          );
          setSelectedPayCheck("");
          deactivatePopUp("RevokePayCheck");
        } else {
          tempErrors.push(resp.response.data);
          setErrors(tempErrors);
        }
      })
      .catch((err) => {
        errors.push(err);
      });
  };

  const handleCashFlowRevoke = (e) => {
    e.preventDefault();
    let tempErrors = [];
    actions
      .revokeCashFlow(selectedCashFlow)
      .then((resp) => {
        if (resp.status === 200) {
          setCashFlows(
            cashflows.filter((cashflow) => cashflow.id !== selectedCashFlow)
          );
          setSelectedCashFlow("");
          deactivatePopUp("RevokeCashFlow");
        } else {
          tempErrors.push(resp.response.data);
          setErrors(tempErrors);
        }
      })
      .catch((err) => {
        errors.push(err);
      });
  };

  return (
    // <Fragment>
    // {/* <div className="page-container"> */}
    <Grid container sx={{ padding: { xs: " 10px", md: "25px" } }}>
      <div
        className={`over-page-modal ${
          confirmPaymentRevoke ? "show-modal" : "hide-modal"
        }`}
      >
        <div className="over-page-modal-content">
          <Confirmation
            classNames={`modal-dropdown ${
              confirmPaymentRevoke ? "show-modal" : "hide-modal"
            }`}
            closeModal={() => deactivatePopUp("RevokePayment")}
            setLoading={setLockStatus}
            confirmAction={handlePaymentRevoke}
            cancelAction={deactivatePopUp}
            headerText={"Confirmar la revocación del pago"}
            bodyText={"¿Está seguro de que quiere revocar este pago?"}
            hasErrors={errors}
          />
        </div>
      </div>

      <div
        className={`over-page-modal ${
          confirmLoanRevoke ? "show-modal" : "hide-modal"
        }`}
      >
        <div className="over-page-modal-content">
          <Confirmation
            classNames={`modal-dropdown ${
              confirmLoanRevoke ? "show-modal" : "hide-modal"
            }`}
            closeModal={() => deactivatePopUp("RevokeLoan")}
            setLoading={setLockStatus}
            confirmAction={handleLoanRevoke}
            cancelAction={deactivatePopUp}
            headerText={"Confirmar la revocación del prestamo"}
            bodyText={"¿Está seguro de que quiere revocar este prestamo?"}
            hasErrors={errors}
          />
        </div>
      </div>

      <div
        className={`over-page-modal ${
          confirmExpenseRevoke ? "show-modal" : "hide-modal"
        }`}
      >
        <div className="over-page-modal-content">
          <Confirmation
            classNames={`modal-dropdown ${
              confirmExpenseRevoke ? "show-modal" : "hide-modal"
            }`}
            closeModal={() => deactivatePopUp("RevokeExpense")}
            setLoading={setLockStatus}
            confirmAction={handleExpenseRevoke}
            cancelAction={deactivatePopUp}
            headerText={"Confirmar la revocación del gasto"}
            bodyText={"¿Está seguro de que quiere revocar este gasto?"}
            hasErrors={errors}
          />
        </div>
      </div>

      <div
        className={`over-page-modal ${
          confirmPayCheckRevoke ? "show-modal" : "hide-modal"
        }`}
      >
        <div className="over-page-modal-content">
          <Confirmation
            classNames={`modal-dropdown ${
              confirmPayCheckRevoke ? "show-modal" : "hide-modal"
            }`}
            closeModal={() => deactivatePopUp("RevokePayCheck")}
            setLoading={setLockStatus}
            confirmAction={handlePayCheckRevoke}
            cancelAction={deactivatePopUp}
            headerText={"Confirmar la revocación de nómina"}
            bodyText={"¿Está seguro de que quiere revocar esta nómina?"}
            hasErrors={errors}
          />
        </div>
      </div>

      <div
        className={`over-page-modal ${
          confirmCashFlowRevoke ? "show-modal" : "hide-modal"
        }`}
      >
        <div className="over-page-modal-content">
          <Confirmation
            classNames={`modal-dropdown ${
              confirmCashFlowRevoke ? "show-modal" : "hide-modal"
            }`}
            closeModal={() => deactivatePopUp("RevokeCashFlow")}
            setLoading={setLockStatus}
            confirmAction={handleCashFlowRevoke}
            cancelAction={deactivatePopUp}
            headerText={"Confirmar la revocación de la transacción de caja"}
            bodyText={
              "¿Está seguro de que quiere revocar esta transacción de caja?"
            }
            hasErrors={errors}
          />
        </div>
      </div>

      {!filial || filial === "0" ? (
        <div>
          <div className="temp-header-space"></div>
          <div className="filial-missing">
            <h1 className="text-center ">Selecciona una Sucursal primero</h1>
          </div>
        </div>
      ) : (
          <Grid size={12}>
            <ItemPaper>
              <Grid container spacing={2}>

                <Grid size={12}>
                  <Tabs>
                    <div label="Historial de préstamos">
                      <ListLoansBySearch
                        loans={loans}
                        filterStatus={filterStatus}
                        pageReportMode={true}
                        selectAction={selectAction}
                      />
                    </div>
                    <div label="Pagos ingresados">
                      <ListPaymentsBySearch
                        payments={payments}
                        pageReportMode={true}
                        selectAction={selectAction}
                      />
                    </div>
                    <div label="Gastos">
                      <ListExpenses
                        expenses={expenses}
                        pageReportMode={true}
                        selectAction={selectAction}
                      />
                    </div>
                    {hasManagerAccess ? (
                      <div label="Nóminas">
                        <ListPayChecksBySearch
                          paychecks={paychecks}
                          selectAction={selectAction}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {hasRegionalManagerAccess ? (
                      <div label="Movimientos de Caja">
                        <ListCashFlowsBySearch
                          cashflows={cashflows}
                          selectAction={selectAction}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {/* {(hasAuditorAccess || hasRegionalManagerAccess )? */}
                    {hasRegionalManagerAccess || hasAuditorAccess ? (
                      <div label="Auditoría de movimientos">
                        <ListAuditTrail
                          audittrails={audittrailList}
                          searchBar={true}
                          reportMode={true}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Tabs>
                </Grid>
              </Grid>
            </ItemPaper>
          </Grid>
      )}

    </Grid>
  );
};

export default Reports;
