import React from 'react'
import { Button } from '@mui/material';


export default function ActionButton(props) {

    const { color, children, onClick } = props;

    return (
        <Button
            sx={{
                minWidth: 0,
                margin: '0.5rem',
                backgroundColor: (theme) => {
                    if (color === 'primary') {
                        return theme.palette.primary.light;
                    } else if (color === 'secondary') {
                        return theme.palette.secondary.light;
                    }
                },
                color: (theme) => {
                    if (color === 'primary') {
                        return theme.palette.primary.main;
                    } else if (color === 'secondary') {
                        return theme.palette.secondary.main;
                    }
                },
                '& .MuiButton-label': {
                    color: (theme) => {
                        if (color === 'primary') {
                            return theme.palette.primary.main;
                        } else if (color === 'secondary') {
                            return theme.palette.secondary.main;
                        }
                    },
                },
            }}
            onClick={onClick}>
            {children}
        </Button>
    )
}
