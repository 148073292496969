import React from "react";

// component
import CashflowListWrapper from "../../../components/Wrappers/CashflowListWrapper";

// mui
import Grid from "@mui/material/Grid2";
import { ItemPaper } from "../../../components/Helper/MUIStyledComponents";
import BreadcrumbNavigation from "../../Reports/BreadcrumbReports";

const CashflowContainer = () => {
  return (
      <Grid container sx={{ padding: { xs: " 10px", md: "25px" } }}>
        <Grid size={12} display="flex" justifyContent="left" alignItems="left">
        <BreadcrumbNavigation currentPage="Movimientos de Caja" />
          {/* <h1 className="text-center">Movimientos de caja</h1> */}
        </Grid>
        <Grid size={12}>
          <ItemPaper>
            <CashflowListWrapper />
          </ItemPaper>
        </Grid>
      </Grid>
  );
};

export default CashflowContainer;
