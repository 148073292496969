import AmountDisplay from "../../../common/AmountDisplay";
import { checkAccess } from "../../GlobalHelpers";

// misc
import { Link } from "react-router-dom";

//date-fns
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// mui
import Box from "@mui/material/Box";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Button from "@mui/material/Button";
import { differenceInDays } from "date-fns";

export const preparePaycheckColumns = (
  openConfirmation,
  setSelectedPaycheck
) => {
  const paycheckColumns = [
    {
      field: "createdate",
      headerName: "Fecha de transacción",
      headerClassName: "GridHeader",
      //   headerAlign: "right",
      //   type: "number",
      flex: 1,
      minWidth: 170,
      maxWidth: 250,
      valueFormatter: (value) => `${format(parseISO(value), "dd/MM/yyyy HH:mm")} hrs` ,
      sortComparator: (v1, v2) => v1.createdate - v2.createdate,
    },
    {
      field: "description",
      headerName: "Descripción",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 300,
      hideable: false,
    },
    {
      field: "employee_lastname",
      headerName: "Empleado",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 200,
      maxWidth: 350,
      renderCell: (params) => {
        return (
          <Link to={`/Employees/${params?.row.employeeid}`}>
            {params?.row.employee_fullname}
          </Link>
        );
      },
    },
    {
      field: "amount",
      headerName: "Cantidad",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      maxWidth: 120,
      renderCell: (params) => <AmountDisplay amount={params?.row.amount} />,
      sortComparator: (v1, v2) => v1.amount - v2.amount,
    },

    {
      field: "accionRevocar",
      headerName: "",
      hideable: false,
      sortable: false,
      align: "right",
      renderCell: (params) => {
        const triggerConfirmationPage = (e) => {
          e.stopPropagation();
          setSelectedPaycheck(params?.row.id);
          openConfirmation(true);
        };
        const isDisabled = differenceInDays(new Date(), parseISO(params?.row.createdate)) > 15;

        return (
          <Box>
            <Button
              color="warning"
              variant="contained"
              size="medium"
              onClick={triggerConfirmationPage}
              disabled={isDisabled}

            >
              <DoDisturbIcon fontSize="small" />
            </Button>
          </Box>
        );
      },
    },
  ];
  return paycheckColumns;
};

export const columnVisibilityModelPayCheckContainer = (currentRole) => {
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");

  return {
    accionRevocar: hasAuditorAccess || hasRegionalManagerAccess ,
  };
};

