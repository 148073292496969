import React, { useState, useContext } from "react";

// Internal imports
import { NovaContext } from "../../components/context";
import { checkAccess } from "../../components/Helper/GlobalHelpers";

// Material UI imports
import Popover from "@mui/material/Popover";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import HandshakeIcon from '@mui/icons-material/Handshake';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const FilialPopover = ( props ) => {

    const {
        setExpenseModal,
        setCashFlowModal,
        setPayCheckModal
    } = props;

    // Internal access variables
    const { currentRole } = useContext(NovaContext);
    const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
    const hasManagerAccess = checkAccess(currentRole, "Gerente");

    // Popover state/methods/variables for actions (expense, transaction, nominal payment)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button
                variant="contained"
                onClick={handleClick}
                size="large"
                sx={{ marginRight: "10px"}}
            >
                Acciones
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
            >
                <Box 
                  display="flex" padding="5px" sx={{'&:hover': {background: "lightgrey"}}}
                  onClick={() => {
                      setExpenseModal(true)
                      handlePopoverClose();
                      }
                  }
                >
                  <MonetizationOnIcon 
                      fontSize="large"
                      color="success"
                  />
                    <Typography
                        paddingLeft="5px"
                        fontSize="large"
                    >
                            Agregar gasto
                    </Typography>
                </Box>

            {
              hasRegionalManagerAccess ? (
                <Box 
                  display="flex" padding="5px" sx={{'&:hover': {background: "lightgrey"}}}
                  onClick={() => {
                    setCashFlowModal(true);
                    handlePopoverClose();
                }}
                >
                  <PointOfSaleIcon 
                    fontSize="large"
                    color="success"
                  />
                    <Typography
                      paddingLeft="5px"
                      fontSize="large"
                    >
                      Agregar transaccion de caja
                    </Typography>
                </Box>
                )
                : null
            }

            {
              hasManagerAccess ? (
                <Box 
                  display="flex" padding="5px" sx={{'&:hover': {background: "lightgrey"}}}
                  onClick={() => {
                    setPayCheckModal(true);
                    handlePopoverClose();
                }}
                >
                  <HandshakeIcon 
                    fontSize="large"
                    color="success"
                  />
                  <Typography
                    paddingLeft="5px"
                    fontSize="large"
                  >
                    Agregar pago de nomina
                  </Typography>
                </Box>
                )
                : null
            }

            </Popover>
        </>
    );
}

export default FilialPopover;