// import { max } from "date-fns";

export default class ValidateCustomer {

	validateName(name) {
		try {
			if (name === '') return 'El nombre/apellido es requerido';
			if (name === null) return 'El nombre/apellido es requerido';
			if (/[0-9]/.test(name)) return 'El nombre/apellido es incorrecto';
			if (/[!@#$%^&*(),.?":{}|<>]/.test(name)) return 'El nombre/apellido es incorrecto';
			return '';
		} catch (err) {
			return 'Se ha producido un error';
		}
	}

	validateUsername(username) {
		if (username === '') return false;
		if (username === null) return false;
		return true;
	}

	validateGender(gender) {
		if (gender === "0") return false;
		return true;
	}

	// Source for password regex:
	// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
	// current policy -> 4 numbers only
	validatePassword(password) {
		try {
			if (password === '') return 'La contraseña es requerida';
			if (password === null) return 'La contraseña es requerida';
			if (/^[0-9]{5,}$/.test(password)) return 'La Contraseña tiene que ser de 4 digitos';
			if (/^[0-9]{1,3}$/.test(password)) return 'La Contraseña tiene que ser de 4 digitos';
			return '';
		} catch (err) {
			return 'Se ha producido un error';
		}
	}


	validateBothPasswords(password1, password2) {
		try {
			if (password1 !== password2) return 'Las contraseñas no coinciden';
			return '';
		} catch (err) {
			return 'Se ha producido un error';
		}
	}

	//Ignored
	validateDOB(DOB) { }

	// validateEmail(email){
	// 	// /^\S+@\S+\.\S+$/.test(email);
	// 	return true;
	// }

	// Validates if value only contains digits and is between 1 and 12 digits long
	validateMeter(meter) {
		if (meter === null) return false;
		if (meter === '') return false;
		if (/\s/.test(meter)) return false; // checks for spaces
		if (/[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]/.test(meter)) return false; // check for symbols
		if (meter.length > 12) return false;
		return true;
	}

	//Ignored
	validateAddress(address) {
		if (address === null) return false;
		if (address === '') return false;
		return true;
	}

	validateComment(comment) {
		return true;
	}
}
