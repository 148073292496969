const storageName = 'nn-client';

const parseNnClient = ( payload ) => {
    if ( payload ) {
        return JSON.parse(payload);
    } else {
        return null;
    }
}

export default class NovaStorage {

    getNovaClient = ( key ) => {
        try {
            const nnClient = parseNnClient(localStorage.getItem(storageName));
            if ( nnClient ) {
                if ( key ) {
                    return nnClient[key];
                } else {
                    return nnClient;
                }
            } else {
                return null;
            }
        } catch (err) {
            console.log("Error adding session: ", err);
            console.log("Please contact your system admin!");
        }
    }

    setNovaClient = ( newState ) => {
        try {
            localStorage.setItem(storageName, JSON.stringify(newState));
        } catch (err) {
            console.log("Error setting session: ", err);
            console.log("Please contact your system admin!");
        }
    }

    removeNovaClient = () => {
        try {
            localStorage.removeItem(storageName);
        } catch (err) {
            console.log("Couldn't remove session: ", err);
            console.log("Please contact your system admin!");
        }
    }
}