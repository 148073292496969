import GroupsIcon from '@mui/icons-material/Groups';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import InventoryIcon from '@mui/icons-material/Inventory';
import TuneIcon from '@mui/icons-material/Tune';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const menuData = [
    {title: 'Clientes',         link: '/Customers'      , icon: 'GiPerson'              , icon: GroupsIcon},
    {title: 'Créditos',         link: '/Loans'          , icon: 'GiReceiveMoney'        , icon: MonetizationOnIcon},
    {title: 'Reportes',         link: '/Reports'        , icon: 'GiArchiveResearch'     , icon: InventoryIcon},
    {title: 'Administración',   link: '/Administration' , icon: 'GiSettingsKnobs'       , icon: TuneIcon}
];

export const employeeNavigation = [
    {title: 'Detalles',         link: '/details'    },
    {title: 'Comentarios',     link: '/comments'   },
    {title: 'Multimedia',       link: '/multimedia' },
    {title: 'Historial',        link: '/history'    }
];


export const menuSettings = [
    // {title: 'Profile',         link: '/Employees'      , icon: 'GiPerson'               , icon: ManageAccountsIcon},
];
