import React, { useMemo } from "react";
import { Grid2 as Grid, Button, Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Filial_New from "../Modal/Filial_New";
// import EditIcon from '@mui/icons-material/Edit';
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      {/* <Filial_New/> //activate only if the button should be in the DG*/}
    </GridToolbarContainer>
  );
}

const FilialList = (props) => {
  const {
    objectsArray,
    // handleNewClick,
    handleEditClick,
    rowId,
    setRowId,
    setRowForEdit,
    setOpenForm,
    cluster,
  } = props;

  const boxHeight = 600;

  const columns = useMemo(
    () => [
      {
        field: "filial_name",
        headerName: "Nombre",
        flex: 1,
      },
      {
        field: "filial_id",
        headerName: "Id",
        // editable: true,
        flex: 1,
      },
      {
        field: "role_name",
        headerName: "Permiso",
        flex: 1,
      },
      {
        field: "filial_cluster",
        headerName: "Cluster",
        editable: true,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        width: 100,
        renderCell: (params) => [
          // <ActionOpenNewEdit
          //   {...{ params, setRowForEdit, setOpenForm }}
          // />,
          // <ActionChanges {...{ params, rowId, setRowId }} />,
        ],
      },
    ],
    []
  );

  return (
    <Box
      sx={{
        width: "auto",
        height: "100%",
        overflowX: "auto",
      }}
    >
      <Grid size={12}>
        <Box height={boxHeight} width="100%">
          <CustomDataGrid
            slots={{
              toolbar: CustomGridToolbar,
            }}
            rows={objectsArray}
            columns={columns}
            getRowId={(row) => row.filial_id}
            // sx={{
            //   [`& .${gridClasses.row}`]: {
            //     bgcolor: (theme) =>
            //       theme.palette.mode === "light" ? grey[200] : grey[900],
            //   },
            // }}
            // onCellEditStop={(params) => {
            //   console.log("edit ", params);
            //   setRowId(params.id);
            // }}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default FilialList;
