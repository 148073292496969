import React from "react";

// internal import
import {
  RiskWithLabel,
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
  formatRiskIconWithLabel,
  formatRiskWithLabel,
  loanPercentagePaid,
} from "../../components/Helper/LoanHelpers";
import { Box, Grid2 as Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { format, parseISO } from "date-fns";
import ProgressBar from "../../components/ProgressBar";

const Item = styled(Paper)(({ theme }) => ({
  // margin: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: "center",
  height: "100%",
  backgroundColor: "#e4e6ef",
  boxShadow: 3,
  borderRadius: 20,
}));

const LoanDetailBoxes = ({ loanItem }) => {
  // console.log(loanItem);
  // const parseLoan = (loanItem) => {
  return (
    <Box>
      {loanItem ? (
        <Grid container spacing={0.5}>
          <Grid size={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography
                  variant="h6"
                  align="left"
                  component="div"
                  sx={{ ml: 2 }}
                >
                  <Box sx={{ fontWeight: "bold" }}>{`Crédito Actual`}</Box>
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography
                  sx={{ ml: { xs: 0, md: 1 } }}
                  variant="body1"
                  align="center"
                  component="div"
                >
                  <ProgressBar
                    percentage={loanItem ? loanPercentagePaid(loanItem) : "---"}
                  />
                  {/* <Box>
                    {loanItem ? loanPercentagePaid(loanItem) : "---"} pagado
                  </Box> */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 6, md: 3 }}>
                <Item>
                  <Typography
                    variant="h2"
                    display="flex"
                    justifyContent="center"
                  >
                    {loanItem.loan_remaining}
                  </Typography>
                  Captial Pendiente
                </Item>
              </Grid>
              <Grid size={{ xs: 6, md: 3 }}>
                <Item>
                  <Typography variant="h2" justifyContent="center">
                    {calculateOpenInterest(loanItem)}
                  </Typography>
                  Total de Refrendos
                </Item>
              </Grid>
              <Grid size={{ xs: 6, md: 3 }}>
                <Item>
                  <Typography variant="h2" justifyContent="center">
                    {calculateOpenAmountTotal(loanItem)}
                  </Typography>
                  Total de Adeudo
                </Item>
              </Grid>
              <Grid size={{ xs: 6, md: 3 }}>
                <Item>
                  <Typography variant="h2" justifyContent="center">
                    {calculateOpenArrear(loanItem)}
                  </Typography>
                  Moras
                </Item>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={12}>
            <Grid container spacing={1.5}>
              <Grid size={{ xs: 6 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "left", md: "center" },
                    flexDirection: { xs:"column", md:'row'},
                    // justifyContent: "left",
                  }}
                >
                  <Typography variant="body1" align="left" component="div">
                    <Box sx={{ ml: 2 }}>Riesgo actual:</Box>
                  </Typography>
                  <Typography variant="body1" align="left" component="div">
                    <Box sx={{ fontWeight: "bold", ml: 2 }}>
                      {RiskWithLabel(loanItem)}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <Box
                  sx={{
                    mr: 2,
                    display: "flex",
                    alignItems: { xs: "right", md: "center" },
                    flexDirection: { xs:"column", md:'row'},
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="body1" align="right" component="div">
                    <Box sx={{}}>Fecha de pago:</Box>
                  </Typography>
                  <Typography variant="body1" align="right" component="div">
                    <Box sx={{ fontWeight: "bold", ml: 2 }}>
                      {format(parseISO(loanItem.loan_datedue), "dd/MM/yyyy ")}{" "}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

// return loanItem
//   ? parseLoan(loanItem)
//   : "No existe ningun préstamo para este cliente.";
// };

export default LoanDetailBoxes;
