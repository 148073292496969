import React, { useContext, useState } from 'react';

// internal
import { CustomDataGrid, CustomGridToolbar } from '../../components/Helper/MUIStyledComponents';
import Confirmation from '../../components/common/Confirmation';
import CustomLoadingOverlay from '../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay';
import { renderNoRowDataOverlay } from '../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay';
import { deleteMedia } from '../../utils/api/api_document';
import { prepareDocumentColumns } from '../../components/Helper/DataGrid/ColumnDefinitions/Columns_Documents';
import { alerts, displayAlert } from '../../components/Helper/AlertToastify';
import { NovaContext } from '../../components/context';

// mui 
import { Grid2 as Grid } from '@mui/material';

const EmployeeDocuments = (props) => {
    const { documentList, currentRole, updateMedia } = props;
    const { filial } = useContext(NovaContext);
    const [selectedDocument, setSelectedDocument] = useState("");
    const [confirmation, setConfirmation] = useState(false);

    // what does filter model do?
    const [filterModel, setFilterModel] = React.useState({
        items: [],
        quickFilterExcludeHiddenColumns: true,
        quickFilterValues: [""],
    });

    const handleRemoveDocument = async () => {
        const params = `objectKey=${selectedDocument.key}&filialId=${filial}&forEmployeeId=${selectedDocument.media_foremployeeid}`;
        deleteMedia(params)
            .then(resp => {
                setConfirmation(false);
                setSelectedDocument("")
                displayAlert(alerts.SUCCESS, "Documento eliminado");
                updateMedia(selectedDocument, null)
            })
            .catch(err => {
                displayAlert(alerts.ERROR, "No se ha podido eliminar el documento");
            });
    }

    return (
        <Grid container height="inherit">
            <Confirmation
                open={confirmation}
                setOpen={setConfirmation}
                textContent={"Está seguro de que quieres revocar este documento?"}
                confirmationMethod={handleRemoveDocument}
            />
            <Grid size={12} height="inherit">
                <CustomDataGrid
                    columns={prepareDocumentColumns(
                        setSelectedDocument,
                        setConfirmation,
                        currentRole
                    )}
                    rows={documentList}
                    getRowId={(row) => row.media_id}
                    slots={{
                        toolbar: CustomGridToolbar,
                        loadingOverlay: CustomLoadingOverlay,
                        noRowsOverlay: () => renderNoRowDataOverlay("No hay documentos para este empleado")
                    }}
                    onFilterModelChange={(newModel) => setFilterModel(newModel)}
                    filterModel={filterModel}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // columnXTohide: hasAdminAccess ? true : false,
                            },
                        },
                        filter: {
                            filterModel: filterModel,
                        },
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default EmployeeDocuments;