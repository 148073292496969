
// send back error message as text to display below text fields

export default class ValidateLoan {

    validateAmount(amount,maxloanvalue){
        try {
            const newAmount = parseInt(amount);
            if (newAmount <= 0) return 'La cantidad no es válida';
            else if (newAmount % 100 !== 0) return 'La cantidad debe ser un múltiplo de 100';
            else if (newAmount > maxloanvalue) return 'La cantidad no puede ser mayor a $' +maxloanvalue;
            return '';
        } catch (err) {
            return 'Se ha producido un error';
        }
    }

    isValidFirstLoan(amount, amountOfLoans, initialloanvalue) {
        try {
            if (amount > initialloanvalue && amountOfLoans === 0) {
                return 'Un primer préstamo no puede ser superior a $' +initialloanvalue;
            } else {
                return '';
            }
        } catch (err) {
            return '';
        }
    }

    // validateDayOfPayment() {

    // }

    // validateStatusOfLoan() {

    // }
}