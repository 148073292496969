import { addHours, format } from 'date-fns';
import { getTimeDifferenceInHours } from './GlobalHelpers';

export const mediaFormats = [
    ".pdf",
    ".csv",
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    "text/plain",
    "image/png", 
    "image/gif", 
    "image/jpeg"
];

export const documentFormats = [
    ".pdf",
    ".csv",
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    "text/plain"
];

export const getAcceptedMediaFormats = () => {
    return mediaFormats.map(String).join(',');
}

export const getAcceptedDocumentFormats = () => {
    return documentFormats.map(String).join(',');
}

export const imageFormats = [
    ".png",
    ".jpg",
    ".jpeg"
];

export const getAcceptedImageFormats = () => {
    return imageFormats.map(String).join(',');
}

// this determines the endpoint that will be used for the s3 bucket
export const uploadDestination = {
    CUSTOMER: "CUSTOMER",
    EMPLOYEE: "EMPLOYEE",
    FILIAL: "FILIAL"
}

export const determineMediaType = (mediaPath) => {
    const fileExtension = mediaPath.substring(mediaPath.lastIndexOf('.'));

    if (imageFormats.find(format => format === fileExtension)) {
        return "IMAGE";
    }

    if (documentFormats.find(format => format === fileExtension)) {
        return "DOCUMENT";
    }

    return null;
}

export const validateMediaUpload = (mediaPath) => {
    const errors = [];
    // check if media has been uploaded
    if (mediaPath === undefined) {
        errors.push("No se ha detectado ningún archivo de la captura de los medios");
        return errors;
    }

    return errors;
}

export const createMediaObject = (selectedObject, objectUuid, employeeId, filialId, isProfile, destination) => {
    const mediaType = determineMediaType(selectedObject.name);
    
    let mediaObject = {
        mediaName: selectedObject.name,
        employeeId,
        filialId,
        isProfile,
        mediaType,
        dateCreated: format(addHours(new Date(), +getTimeDifferenceInHours()), "yyyy-MM-dd HH:mm:ss")
    }

    // ToDo (ATC): add documentation
    switch (destination) {
        case uploadDestination.CUSTOMER:
            mediaObject = { ...mediaObject, customerId: objectUuid }
        case uploadDestination.EMPLOYEE:
            mediaObject = { ...mediaObject, forEmployeeId: objectUuid }
    }

    const newMedia = {
        media: selectedObject,
        otherinfo: JSON.stringify(mediaObject)
    }

    return newMedia;
}