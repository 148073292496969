import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Grid2 as Grid, Typography } from "@mui/material";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";

import LoanDetailBoxes from "./LoanDetailBoxes";
import CustomerInfoBox from "./CustomerInfoBox";

const CustomerHeader = ({ customer, loansOnMeter, loan }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <ItemBox>
            <CustomerInfoBox
              customer={customer}
              loansOnMeter={loansOnMeter}
              currentLoan={loan}
            />
          </ItemBox>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <ItemBox>
            {Object.keys(loan).length > 0 ? (
              <LoanDetailBoxes loanItem={loan} />
            ) : (
              <Typography
                variant="h4"
                align="center"
                component="div"
                sx={{ ml: 1 }}
              >
                <Box
                  sx={{ mt: 5 }}
                >{`Este cliente no tiene ningún crédito.`}</Box>
              </Typography>
            )}
          </ItemBox>
        </Grid>
      </Grid>
    </Box>
  );
};

CustomerHeader.propTypes = {
  customer: PropTypes.object.isRequired,
  loansOnMeter: PropTypes.array.isRequired,
};

export default CustomerHeader;
