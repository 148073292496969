import React from "react";

// component
import PaymentsListWrapper from "../../../components/Wrappers/PaymentsListWrapper";

// mui
import Grid from "@mui/material/Grid2";
import { ItemPaper } from "../../../components/Helper/MUIStyledComponents";
import { columnVisibilityModelPaymentContainer } from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Payments";
import BreadcrumbNavigation from "../../Reports/BreadcrumbReports";

const PaymentsContainer = () => {
  
  return (
    <Grid container sx={{ padding: { xs: " 10px", md: "25px" } }}>
      <Grid size={12} display="flex" justifyContent="left" alignItems="left">
      <BreadcrumbNavigation currentPage="Pagos ingresados" />
        {/* <h1 className="text-center">Pagos ingresados</h1> */}
      </Grid>
      <Grid size={12}>
        <ItemPaper>
            <PaymentsListWrapper columnVisibilityModel={columnVisibilityModelPaymentContainer} />
        </ItemPaper>
      </Grid>
    </Grid>
  );
};

export default PaymentsContainer;
