import React from "react";
import { Link } from "react-router-dom";

// mui
import Tooltip from "@mui/material/Tooltip";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";

// Date-fns imports
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// internal
import { checkAccess } from "../../GlobalHelpers";


export const prepareAuditColumns = () => {
  const auditColumns = [
    {
      field: "audittype",
      headerClassName: "GridHeader",
      headerName: "Tipo",
      width: 60,
      hideable: false,
      resizable: false,
      renderCell: (params) => {
        const auditType =
          params.row.audittype === "CASH" ? "DINERO" : "PROCESO";
        return (
          <Tooltip title={auditType} placement="right" arrow>
            {auditType === "DINERO" ? (
              <MonetizationOnIcon />
            ) : (
              <SettingsIcon />
            )}
          </Tooltip>
        );
      },
    },
    {
      field: "timestamptz",
      headerClassName: "GridHeader",
      headerName: "Fecha",
      flex: 1,
      minWidth: 180,
      maxWidth:250,
      valueFormatter: (params) =>
        `${format(parseISO(params), "dd-MM-yyyy hh:mm")} hrs`     
    },
    {
      field: "description",
      headerClassName: "GridHeader",
      headerName: "Descripción",
      flex: 1,
      minWidth: 200,
      // width: 400,
    },
    {
      field: "customer_name",
      headerClassName: "GridHeader",
      headerName: "Cliente",
      flex: 1,
      minWidth: 180,
      maxWidth: 250,
      renderCell: (params) => {
        return (
          <Link to={`/Customers/${params.row.customer}`}>
            {params.row.customer_name}
          </Link>
        );
      },
    },
    {
      field: "employee_name",
      headerClassName: "GridHeader",
      headerName: "Empleado",
      flex: 1,
      minWidth: 180,
      maxWidth: 250,
      renderCell: (params) => {
        return (
          <Link to={`/Employees/${params.row.employee}`}>
            {params.row.employee_name}
          </Link>
        );
      },
    },
    {
      field: "activeuserrole",
      headerClassName: "GridHeader",
      headerName: "Rol",
      // flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        let roleName = params.row.activeuserrole;
        switch (roleName) {
          case "Administrator":
            return "Administrador";
          case "Employee":
            return "Empleado";
          case "RegionalManager":
            return "Gerente Regional";
          case "Auditor":
            return "Auditor";
          case "Manager":
            return "Gerente";
          default:
            return roleName;
        }
      },
    },
    {
      field: "filial_name",
      headerClassName: "GridHeader",
      headerName: "Sucursal",
      // flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Link to={`/filial/${params.row.filial}`}>
            {params.row.filial_name}
          </Link>
        );
      },
    }
  ];
  return auditColumns;
}

export const getHideableColumns = (currentRole) => {
  const hasAdminAccess = checkAccess(currentRole, "Administrador");
  return [
    hasAdminAccess ? "" : "loan_employeeid",
    hasAdminAccess ? "" : "accionPago",
    "customer_idnumber",
    "fullName",
    "customer_telephone",
    "customer_location",
    "customer_address",
  ];
}

export const getVisibilityColumns = () => {
  return [
    "customer_idnumber",
    "fullName",
    "customer_telephone",
    "customer_location",
    "customer_address",
  ]
}

// 16.10.2024 - Not sure if actually has an impact
export const getAuditAutoSizeOptions = () => {
  return {
    columns: ["employee_name", "activeuserrole", "filial_name", "description", "timestamptz"],
    includeHeaders: true,
    includeOutliers: false,
    expand: true
  }
}