import { formatISO } from "date-fns";
import React, { useContext, useRef, useState, Fragment } from "react";
import { NovaContext } from "../context";
import Modal from "./Modal";
import ModalV2 from "./ModalV2";
import { Button } from "@mui/material";

const CashRegisterControl = (props) => {
  const { cashRegisterTotal } = props;

  // const {  } = useContext( NovaContext );
  const [display, toggleDisplay] = useState(false);
  const [validationStatus, setValidationStatus] = useState(false);
  const [total, setTotal] = useState(0);

  const amount1 = useRef();
  const amount2 = useRef();
  const amount3 = useRef();
  const amount4 = useRef();
  const amount5 = useRef();
  const amount6 = useRef();
  const amount7 = useRef();
  const amount8 = useRef();
  const amount9 = useRef();
  const amount10 = useRef();

  const initializeForm = () => {
    amount1.current.value = "0";
    amount2.current.value = "0";
    amount3.current.value = "0";
    amount4.current.value = "0";
    amount5.current.value = "0";
    amount6.current.value = "0";
    amount7.current.value = "0";
    amount8.current.value = "0";
    amount9.current.value = "0";
    amount10.current.value = "0";
  };

  const handleClick = () => {
    initializeForm();
    toggleDisplay(!display);
  };

  const closeModalView = () => {
    clearFields();
    toggleDisplay();
    window.location.reload();
  };

  const clearFields = () => {
    initializeForm();
  };

  const handleChange = (event) => {
    const tempTotal =
      parseInt(amount1.current.value) * 500 +
      parseInt(amount2.current.value) * 200 +
      parseInt(amount3.current.value) * 100 +
      parseInt(amount4.current.value) * 50 +
      parseInt(amount5.current.value) * 20 +
      parseInt(amount6.current.value) * 10 +
      parseInt(amount7.current.value) * 5 +
      parseInt(amount8.current.value) * 2 +
      parseInt(amount9.current.value) * 1 +
      parseInt(amount10.current.value) * 0.5;
    console.log(tempTotal, cashRegisterTotal);
    setTotal(tempTotal);
    setValidationStatus(validate(tempTotal));
  };

  const validate = (value) => {
    if (value === cashRegisterTotal) {
      return true;
    }
    return false;
  };

  return (
    <form>
      <ModalV2
        classNames={`modal ${display ? "show" : ""}`}
        toggleModal={handleClick}
        button={() => (
          <Button
            type="button"
            onClick={() => handleClick()}
            sx={{height: "8px"}}
          >
            {"Checar"}
          </Button>
        )}
        header="Checar caja"
        body={() => (
          <Fragment>
            <div className="grid-container-form">
              <label>
                Billetes de 500
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount1}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Billetes de 200
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount2}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Billetes de 100
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount3}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Billetes de 50
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount4}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Billetes de 20
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount5}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Monedas de 10
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount6}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Monedas de 5
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount7}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Monedas de 2
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount8}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Monedas de 1
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount9}
                  onChange={(e) => handleChange(e)}
                />
              </label>

              <label>
                Monedas de 0,50
                <input
                  type="number"
                  className={`form-control text-right`}
                  ref={amount10}
                  onChange={(e) => handleChange(e)}
                />
              </label>
            </div>
          </Fragment>
        )}
        footer={() => (
          <Fragment>
            <div className="text-center">
              <h5 className={`${!validationStatus ? "validation-error" : ""}`}>
                Total:{total} de {cashRegisterTotal}
              </h5>
            </div>
          </Fragment>
        )}
      />
    </form>
  );
};

export default CashRegisterControl;
