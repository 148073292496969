import React from 'react';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: "2.5em"
  },
  count: {
    position: "absolute",
    lineHeight: 1,
    top: "0.7em",
    fontSize: "1em",
    fontWeight: "bold"
  }
});

const ModeComment = ({ count = 0, size }) => {
  const classes = useStyles();
  const textColor = count < 7 ? "primary" : count < 12 ? "warning" : "error";

  return (
    <div className={classes.root} style={{ fontSize: size }}>
      <PanoramaFishEyeIcon color={textColor} className={classes.icon} />
      <Typography comment="span" className={classes.count} color={textColor}>
        {count ? count : '?'}
      </Typography>
    </div>
  )
}
  ;

export default ModeComment;