import React, { Fragment, useState, useContext, useEffect } from "react";
import ListEmployeeMatrix from "../components/Actions/ListEmployeeMatrix";
import Loading from "../components/Actions/Loading";
import { NovaContext } from "../components/context";
import {
  checkMinAccess,
  filterFilialsWithAccess,
  noAccessMessage,
} from "../components/Helper/GlobalHelpers";
import { Grid } from "@mui/material";

const EmployeeMatrix = () => {
  const { actions, filials } = useContext(NovaContext);
  const hasMinRegionalManagerAccess = checkMinAccess(
    filials,
    "Gerente Regional"
  );
  const hasMinAuditorAccess = checkMinAccess(filials, "Auditor");
  const [tempEmployeeData, setTempEmployeeData] = useState([]);
  const [filialsListData, setFilialsListData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEmployeesData = async (filialsList) => {
    var tempEmployeeData = [];
    var tempEmployeeList = [];
    var tempEmployeeListComplete = [];
    var tempFilialId;

    filialsList.sort((a, b) =>
      a.filial_name > b.filial_name ? 1 : b.filial_name > a.filial_name ? -1 : 0
    );

    for (var i = 0; i < filialsList.length; i++) {
      tempFilialId = filialsList[i].filial_id;
      tempEmployeeList = await actions.getEmployees(tempFilialId);
      tempEmployeeListComplete = tempEmployeeListComplete.concat(
        tempEmployeeList
      );
    }

    tempEmployeeListComplete.sort((a, b) =>
      a.employee_id > b.employee_id ? 1 : b.employee_id > a.employee_id ? -1 : 0
    );


    var lastId = 0;
    var roleName, roleId, filialId;
    var employeeObj;
    var rightsRow = [];
    //add new row
    for (var j = 0; j < tempEmployeeListComplete.length; j++) {
      //validate first if it is a different employee (id)
      if (tempEmployeeListComplete[j].employee_id !== lastId) {
        //add new column (validate first if is a new employee)
        for (var k = 0; k < filialsList.length; k++) {
          // subarray
          var rightsList = tempEmployeeListComplete.filter(
            (emp) =>
              emp.worksin_filialid === filialsList[k].filial_id &&
              emp.employee_id === tempEmployeeListComplete[j].employee_id
          );

          roleName = rightsList[0] ? rightsList[0].role_name : null;
          roleId = rightsList[0] ? rightsList[0].worksin_id : null;
          filialId = rightsList[0]
            ? rightsList[0].worksin_filialid
            : filialsList[k].filial_id;
          rightsRow.push({ roleName, roleId, filialId });
        }
        lastId = tempEmployeeListComplete[j].employee_id;
        // employeeObj = tempEmployeeListComplete[j].employee_name;
        employeeObj = tempEmployeeListComplete[j];
        tempEmployeeData.push({ employeeObj, rightsRow });
        rightsRow = [];
      }
    }
    setTempEmployeeData(tempEmployeeData);
    setFilialsListData(filialsList);
    setLoading(false);
  };

  useEffect(() => {
    if (hasMinAuditorAccess || hasMinRegionalManagerAccess) {
      setLoading(true);
      const tempFilials = [...filials];
      filterFilialsWithAccess(tempFilials);
      getEmployeesData(tempFilials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container sx={{ padding: { xs: " 10px", md: "25px" } }}>
      <div
        className={`over-page-loading ${
          loading ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${
              loading ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>

      {loading ? (
        <div>Cargando...</div>
      ) : !(hasMinAuditorAccess || hasMinRegionalManagerAccess) ? (
        <div>
          <div className="temp-header-space"></div>
          <div className="filial-missing">
            <h1 className="text-center ">{noAccessMessage()}</h1>
          </div>
        </div>
      ) : (
        <ListEmployeeMatrix
          dataList={tempEmployeeData}
          headersList={filialsListData}
        />
      )}
    </Grid>
  );
};

export default EmployeeMatrix;
