import { format, parseISO } from "date-fns";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NovaContext } from "../components/context/index";
// import LogoLanita from "../images/LOGO LANITA SOLIDARIA NEGRO.png";
import { Box } from "@mui/material";
import "../styles/logo.css"

const Ticket2 = () => {
  const { actions, filial } = useContext(NovaContext);
  const params = useParams();
  const loanId = params.id;
  const [loan, setLoan] = useState([]);
  const [name, setName] = useState("");
  const [DueDate, setDueDate] = useState();
  const [lastName, setLastName] = useState("");
  const [currentFilial, setFilial] = useState("");

  useEffect(() => {
    actions.getLoan(loanId).then((data) => {
      setLoan(data);
      setDueDate(format(parseISO(data.loan_datedue), "dd/MM/yyyy "));
      const tempName = data.customer_name.toUpperCase();
      const tempLastName = data.customer_lastname.toUpperCase();
      setName(tempName);
      setLastName(tempLastName);
    });
    actions.getFilial(filial).then((data) => setFilial(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Box>
        <div className="ticket-page">
          <div className="mt-5">
            <div className="text-center text-dark p-7 position-relative">
              <h6>Tel.- {currentFilial.telephone}</h6>
              <div className="mb-2">
                <a href="http://lanitasolidaria.com:3000">
                  {/* <img src={LogoLanita} className="max-h-100px" alt=""></img> */}
                  <div className="custom-background-svg-black"></div>
                  {/* <div className="custom-background-svg-black-christmas"></div> */}
                  {/* <div className="custom-background-svg-black-valentin"></div> */}
                </a>
              </div>
              <h6 className="mb-5">¡UN CRÉDITO NUNCA HABIA SIDO TAN FACIL!</h6>
              <h6 className="">CASA DE CRÉDITO LANITA SOLIDARIA</h6>
              <h6 className="mb-5"> SAS DE CV</h6>
              <h6 className="">{currentFilial.filial_address}</h6>
              <hr className="ticket-hr my-8"></hr>
              <h6 className="mb-6 text-left">FOLIO.-{loan.loan_id}</h6>
              <h3 className="mb-3 text-underline-style">NOMBRE:</h3>
              <h3 className=""> {name}</h3>
              <h3 className="mb-12"> {lastName}</h3>
              <h3 className="mb-3 text-underline-style">SU CRÉDITO FUÉ DE: </h3>
              <h3 className="mb-12">{loan.loan_amount} </h3>
              <h3 className="mb-3 text-underline-style">FECHA DE PAGO: </h3>
              <h3 className="">{DueDate} </h3>
              <hr></hr>
              <h4 className="mb-5">DUDAS O ACLARACIONES:</h4>
              <h4 className="mb-5">Tel.- {currentFilial.telephone}</h4>
              <h4 className="mb-7">FB: LANITA SOLIDARIA</h4>
              <h4 className="mb-8">
                *LA FALTA DE PAGO EN OFICINA O BIEN PAGO CON ATRASO (DESPUÉS DE
                LA 1PM), GENERARÁ UN CARGO MORATORIO EN SU PAGO EL CUAL ES
                EQUIVALENTE A 20 PESOS POR DÍA*
              </h4>
              <h3 className="text-underline-style">¡RECUERDE PAGAR</h3>
              <h3 className="mb-8 text-underline-style">PUNTUALMENTE!</h3>
              <h4 className="mb-5">*ESTE NO ES UN COMPROBANTE FISCAL*</h4>
            </div>
          </div>
        </div>
      </Box>
    </Fragment>
  );
};

export default Ticket2;
