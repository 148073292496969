import AmountDisplay from "../../../common/AmountDisplay";
import { checkAccess } from "../../GlobalHelpers";

// misc
import { Link } from "react-router-dom";

//date-fns
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

// mui
import Box from "@mui/material/Box";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Button from "@mui/material/Button";
import { differenceInDays } from "date-fns";

export const prepareCashflowColumns = (
  openConfirmation,
  setSelectedPaycheck,
  navigate
) => {
  const cashflowColumns = [
    {
      field: "createdate",
      headerName: "Fecha",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 170,
      maxWidth: 250,
      valueFormatter: (value) =>
        `${format(parseISO(value), "dd/MM/yyyy HH:mm")} hrs`,
      sortComparator: (v1, v2) => v1.createdate - v2.createdate,
    },
    {
      field: "description",
      headerName: "Descripción",
      headerClassName: "GridHeader",
      align: "left",
      flex: 1,
      minWidth: 200,
      //   width: 400,
      hideable: false,
    },
    {
      field: "employee_fullname",
      headerName: "Empleado",
      headerClassName: "GridHeader",
      flex: 1,
      minWidth: 200,
      maxWidth: 350,
      renderCell: (params) => {
        return (
          <Link to={`/Employees/${params?.row.employeeid}`}>
            {params?.row.employee_fullname}
          </Link>
        );
      },
    },
    {
      field: "amount",
      headerName: "Cantidad",
      headerClassName: "GridHeader",
      headerAlign: "right",
      type: "number",
      flex: 1,
      maxWidth: 120,
      renderCell: (params) => <AmountDisplay amount={-params?.row.amount} />,
      sortComparator: (v1, v2) => v1.amount - v2.amount,
    },

    {
      field: "accionRevocar",
      headerName: "",
      width: 80,
      align: "right",
      hideable: false,
      sortable: false,
      renderCell: (params) => {
        const triggerConfirmationPage = (e) => {
          e.stopPropagation();
          setSelectedPaycheck(params?.row.id);
          openConfirmation(true);
        };
        const isDisabled = differenceInDays(new Date(), parseISO(params?.row.createdate)) > 15;
        return (
          <Box>
            <Button
              color="warning"
              variant="contained"
              size="medium"
              onClick={triggerConfirmationPage}
              disabled={isDisabled}
            >
              <DoDisturbIcon fontSize="small" />
            </Button>
          </Box>
        );
      },
    },
  ];
  return cashflowColumns;
};

export const getCashflowVisibleColumns = (currentRole) => {
  const hasAuditorAccess = checkAccess(currentRole, currentRole);
  return {
    accionRevocar: hasAuditorAccess,
  };
};

// 17.10 - not sure if this actually does something..
export const getCashflowAutoSizeOptions = () => {
  return {
    columns: [
      "employee_lastname",
      "amount",
      "createdate",
      "description",
      "accionRevocar",
    ],
    includeHeaders: true,
    includeOutliers: false,
    expand: true,
  };
};

// export const columnVisibilityModelPaymentFilialPage = {
//   employee_name: false,
//   accionRevocar: false,
// };
