export const AuditActions = {
    SET_AUDITS: 'SET_AUDITS'
};

export const AuditReducer = (state, action) => {
    switch (action.type) {
        case AuditActions.SET_AUDITS:
            return action.payload;
        default:
            return state;
    }
}