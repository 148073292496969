import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import Controls from "./controls/Controls";
import CloseIcon from "@mui/icons-material/Close";

export default function Popup(props) {
  const { title, children, actions, openPopup, setOpenPopup } = props;

  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      sx={{ p: 2, position: "absolute", top: 5 }}
    >
      <DialogTitle sx={{ pr: 0 }}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Controls.ActionButton
            color="seconprimaryary"
            onClick={() => setOpenPopup(false)}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
