import React from "react";
import { LoansProvider } from "../context/LoansContext";

// component
import LoansList from "../../pages/LoansHistory/components/LoansList";

const LoansListWrapper = ({
  columnVisibilityModel,
  sortingFilter,
  customerId,
  employeeId,
  statusFilter,
}) => {
  return (
    <LoansProvider
      statusFilter={statusFilter}
      sortingFilter={sortingFilter}
      // customerIdFilter={customerId}
      // employeeIdFilter={employeeId}
    >
      <LoansList columnVisibilityModel={columnVisibilityModel} />
    </LoansProvider>
  );
};

export default LoansListWrapper;
