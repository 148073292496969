import React from "react";
import { AuditProvider } from "../../components/context/AuditContext";

// component
import AuditList from "../../pages/AuditOverview/components/AuditList";

const AuditListWrapper = ({ columnVisibilityModel }) => {
  return (
    <AuditProvider>
      <AuditList columnVisibilityModel={columnVisibilityModel} />
    </AuditProvider>
  );
};

export default AuditListWrapper;
