import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Divider, Grid2 as Grid } from "@mui/material";
import {
  calculateReportDates,
  checkAccess,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";
import {
  CircularProgressWithLabel,
  ItemPaper,
  ItemPaperHeader,
} from "../../components/Helper/MUIStyledComponents";
import { calculateGoalPercentage } from "../../components/Helper/ReportsHelpers";
import { addHours } from "date-fns";
import { NovaContext } from "../../components/context";

// somehow the styles are not really used (e.g. the elevation or borders are not working)
const useStyles = makeStyles((theme) => ({
  paper: {
    elevation: 3,
    borderRadius: "25px", // add fallback value
    minHeight: "100px",
  },
  card: {
    cursor: "pointer",
    borderRadius: "25px", // add fallback value

    "&:hover": {
      backgroundColor: "lightgrey",
      color: "blue",
    },
  },
  icon: {
    fontSize: "2rem",
    marginBottom: "2px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "2.5rem !important",
    margin: "0px 0px 0.35em",
  },
  regionLabel: {
    display: "flex",
    justifyContent: "right",
    textAlign: "right",
    color: "gray",
  },
  subtitleBox: {
    minHeight: "20px",
  },
  subtitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: "gray",
  },
  extras: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
}));

const FilialPaperCard = React.memo((props) => {
  const { filialObj, triggerPage, icon, dataRange } = props;

  const { actions, currentRole } = useContext(NovaContext);
  const [CashRegisterReport, setCashRegisterReport] = useState(0);
  const [reportTotalData, setReportTotalData] = useState({});
  const [reportMode, setReportMode] = useState("WEEK");

  // const classes = useStyles();
  // const hasMinRegionalManagerAccess = checkAccess(
  //   filialObj.role_name,
  //   "Gerente Regional"
  // );
  const hasAdminAccess = checkAccess(filialObj.role_name, "Administrador");

  useEffect(() => {
    // setCurrentDateTime(format(new Date(), "dd/MM/yyyy HH:mm:ss") + " hrs");
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());

    const fetchData = async () => {
      // const filialData = await actions.getFilial(filialId);
      // setFilial(filialData);
      const filialId = filialObj.filial_id;

      const cashRegisterReport = await actions.getCurrentCashRegisterReport(filialId);
      setCashRegisterReport(cashRegisterReport);

      const DataRange = dataRange;
      DataRange.filialId = filialId;
      const reportData = await actions.getReports(DataRange);
      setReportTotalData(reportData);
      // console.log("fetching meta for:", filialId);
    };

    fetchData();
  }, [filialObj]);

  // sx={{minHeight:'100px'}}
  return (
    <ItemPaper
      onClick={triggerPage}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "lightgrey",
        },
      }}
    >
      <ItemPaperHeader
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "2.5rem !important",
        }}
      >
        {filialObj.filial_name}
      </ItemPaperHeader>
      <Divider />
      {/* // <Box 
      //   sx={{
      //     display: "flex",
      //     justifyContent: "center",
      //     textAlign: "center",
      //     color: "gray",
      //   }}
      // >
      //   <Box> */}
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h6">{filialObj.filial_address}</Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h5" sx={{ mt: 1 }}>
            {" "}
            Tel.- {filialObj.telephone}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          {hasAdminAccess ? (
            <CircularProgressWithLabel
              value={Math.floor(
                calculateGoalPercentage(CashRegisterReport, reportTotalData)
              )}
              sx={{
                color: (theme) =>
                  calculateGoalPercentage(CashRegisterReport, reportTotalData) >
                  79.999
                    ? calculateGoalPercentage(
                        CashRegisterReport,
                        reportTotalData
                      ) > 89.999
                      ? calculateGoalPercentage(
                          CashRegisterReport,
                          reportTotalData
                        ) > 94.999
                        ? theme.palette.success.dark
                        : theme.palette.warning.main
                      : theme.palette.error.light
                    : theme.palette.error.dark,
              }}
              size={100}
              thickness={6}
              typographyVariant={"h4"}
            />
          ) : null}
        </Grid>
      </Grid>
      {/* </Box>
      </Box> */}
    </ItemPaper>
  );
});

export default FilialPaperCard;
