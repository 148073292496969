// Utility import
import React, { Fragment, useState, useContext } from "react";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../context";
import { canEditDeleteEmployee, checkAccess } from "../Helper/GlobalHelpers";

// Component import
// import EditEmployee from "./EditEmployee";
import NewEditEmployee from "../popups/NewEditEmployee";
import Loading from "./Loading";
import EditEmployeePassword from "../popups/EditEmployeePassword";
import Employee_Password_Edit from "../../pages/Modal/Employee_Password_Edit";

const ListEmployeesBySearch = ({ employees }) => {
  const { actions, currentRole } = useContext(NovaContext);
  const [lockStatus, setLockStatus] = useState(false);
  const [searchText, setSearchText] = useState("");
  let navigate = useNavigate();

  // const hasAdminAccess = checkAccess(currentRole,'Administrador');
  const handleView = (Id) => {
    const path = `${Id}`;
    navigate(path);
  };

  const handleDelete = async (employee) => {
    //Add a validation TBD
    const employeeDeleted = await actions.deleteEmployee(employee);
    console.log("checar borrado:", employeeDeleted);
    if (employeeDeleted === true) {
      window.location.reload();
    } else {
      console.log("deleteEmployee failed");
    }
  };

  return (
    <Fragment>
      <div
        className={`over-page-loading ${
          lockStatus ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${
              lockStatus ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>
      <div className="d-flex quick-search-form">
        <div className="input-group">
          <span className="input-group-text">
            <RiSearch2Line />
          </span>
          <input
            type="text"
            name="searchField"
            className="form-control mr-2"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <NewEditEmployee setLockStatus={setLockStatus} />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Tipo de usuario</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {employees &&
              employees
                .filter((val) => {
                  if (currentRole !== "Root" && val.role_name === "Root") {
                    return null;
                  }
                  if (searchText === "") {
                    return val;
                  } else if (
                    val.employee_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.employee_lastname
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return val;
                  } else if (
                    val.employee_email
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return val;
                  }

                  return null;
                })
                .map((employee, i) => (
                  <tr key={i}>
                    <td>{employee.employee_name} </td>
                    <td>{employee.employee_lastname} </td>
                    <td>{employee.employee_email} </td>
                    <td>{employee.role_name} </td>
                    <td>
                      {canEditDeleteEmployee(
                        currentRole,
                        employee.role_name
                      ) ? (
                        <div className="d-flex align-items-stretch">
                          <NewEditEmployee
                            selectedEmployee={employee}
                            setLockStatus={setLockStatus}
                          />
                          <Employee_Password_Edit
                            employee={employee}
                          />

                          <button
                            type="button"
                            className="btn btn-secondary ml-2"
                            data-toggle="modal"
                            data-target={`#id${employee.employee_id}`}
                            onClick={() => handleView(employee.employee_id)}
                          >
                            Detalles
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default ListEmployeesBySearch;
