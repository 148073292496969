import React, { Fragment, useState, useContext, useEffect } from "react";
import ListEmployeesBySearch from "../components/Actions/ListEmployeeBySearch";
import { NovaContext } from '../components/context';
import { checkAccess } from "../components/Helper/GlobalHelpers";
import Grid from "@mui/material/Grid2";
import {  ItemPaper } from "../components/Helper/MUIStyledComponents";
import { useLocation, useParams } from "react-router-dom";

const Employees = () => {

    // Import Context-API‚
    const { actions, filial, currentRole } = useContext( NovaContext );
    const [employees, setEmployees] = useState([]);
    const hasAuditorAccess = checkAccess(currentRole, 'Auditor');
    const hasRegionalManagerAccess = checkAccess(currentRole, 'Gerente Regional');

    let currentPath = useLocation();

    useEffect(() => {
        actions.getEmployees().then((data) => setEmployees(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPath]);

    return (
        <Fragment>
            <div className="page-container">
                {!filial || filial === "0" ?
                    <div>
                        <div className="temp-header-space"></div>
                        <div className="filial-missing">
                            <h1 className="text-center ">Selecciona una Sucursal primero</h1>
                        </div>
                    </div>
                :
                !(hasAuditorAccess || hasRegionalManagerAccess) ?
                        <div>
                            <div className="temp-header-space"></div>
                            <div className="filial-missing">
                                <h1 className="text-center ">No tienes acceso a esta area!</h1>
                            </div>
                        </div>
                        :
                        <Grid container>
                        <Grid size={12}>
                          <ItemPaper>
                        <ListEmployeesBySearch employees={ employees } />
                        </ItemPaper>
                        </Grid>
                        </Grid>
                }
            </div>
        </Fragment>
    );
};

export default Employees;
