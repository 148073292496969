import React, { createContext, useContext, useState, useReducer } from 'react';
import { NovaContext } from '.';

// internal
import PaymentsController from '../controllers/PaymentsController';

import { PaymentReducer } from '../../reducers/PaymentReducer';
import { DateRangeReducer } from '../common/DateRange/DateRangeReducer';
import { LoadingReducer } from '../../reducers/LoadingReducer';

// date-fns
import subMonths from 'date-fns/subMonths';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

// states
const initialPaymentState = [];
const initialDateRangeState = {
    start: startOfDay(subMonths(new Date(), 1)),
    end: endOfDay(new Date())
}
const initialLoadingReducer = true;

const PaymentsContext = createContext();

const PaymentsProvider = ({ children }) => {
    const { filial, currentRole } = useContext(NovaContext);
    const [dateRange, dispatchDateRange] = useReducer(DateRangeReducer, initialDateRangeState);
    const [loading, dispatchLoading] = useReducer(LoadingReducer, initialLoadingReducer);
    const [paymentList, dispatchPaymentList] = useReducer(PaymentReducer, initialPaymentState);

    return (
        <PaymentsContext.Provider
            value={{
                filial,
                currentRole,
                dateRange,
                dispatchDateRange,
                loading,
                dispatchLoading,
                paymentList, 
                dispatchPaymentList,
            }}
        >
            {children}
            <PaymentsController />
        </PaymentsContext.Provider>
    )
}

export { PaymentsContext, PaymentsProvider };


export const usePaymentsContext = () => {
    return useContext(PaymentsContext);
}