import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles"; // deprecated warning
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

// import Box from '@mui/system/Box';

// Material UI
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { NovaContext } from "../../components/context";
import { checkAccess } from "../../components/Helper/GlobalHelpers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// export default function CustomizedDialogs() {
export const Cluster_NewEdit = (props) => {
  // initialise props
  const { cluster, setLockStatus, company } = props;

  // Misc variables
  let navigate = useNavigate();

  // context data
  const { actions, currentRole } = React.useContext(NovaContext);
  const isNewMode = !cluster;

  const [open, setOpen] = React.useState(false);

  const clusterInfo = "clusterInfo";

  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  const [errors, setErrors] = useState([]);

  // Set up refs for the react form -> refs are automatically updated when bound
  const Name = useRef("");

  // triggers the form's object initialisation as a result of React's multi load rendering
  const initializeForm = (Object) => {
    console.log(Object);
    Name.current.value = Object ? (Object.name ? Object.name : "") : "";
  };

  const handleClick = async () => {
    if (!isNewMode) {
      //   await actions.getCluster(cluster.id).then((data) => {
      //     initializeForm(data);
      //   });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleClick();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setErrors([]);
    initializeForm();
  };

  // reverts form data to initial state. Necessary for the shrink attribute of TextField
  const revertChange = () => {
    // setSelectedArea("");
    clearFields();
    handleClose();
  };

  const validateData = () => {
    setErrors([]);
    let isValid = true;
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    const isEntryValid = true;
    const defaultCompany = "b69e278d-bb47-4d1b-bde3-8af043abe4e2";
    if (isEntryValid) {
      const tempCluster = {
        name: Name.current.value,
        company: company ? company : defaultCompany,
        // company: company
      };

      // console.log("TC:", isNewMode, tempCluster);

      const cluster = isNewMode
        ? await await actions.addCluster(tempCluster)
        : await actions.updateCluster(tempCluster, cluster.id);

      if (cluster) {
        revertChange();
        const path = `/Company`;
        navigate(path);
      } else {
        console.log("Cluster create/edit failed");
      }
    }

    handleClose();
  };

  return isNewMode || hasAdminAccess ? (
    <Box paddingLeft={1}>
      <Button size="small" onClick={handleClickOpen}>
        <AddIcon />
        CREAR GRUPO
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Crear Grupo
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid container padding="10px">
            <Box
              id="clusterInfo"
              component="form"
              display={"block"}
              marginBottom={"10px"}
              width={"100%"}
              padding={1}
              borderRadius={"5px"}
            >
              <Box
                component={"span"}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                marginBottom={1}
              >
                <TextField
                  variant="outlined"
                  label="Nombre"
                  id="name"
                  fullWidth
                  inputRef={Name}
                />
              </Box>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="success"
            variant="contained"
            onClick={handleCreate}
          >
            Continuar
          </Button>
          <Button
            autoFocus
            color="secondary"
            variant="contained"
            onClick={() => revertChange()}
          >
            Cancelar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  ) : null;
};

export default Cluster_NewEdit;

// Form components
