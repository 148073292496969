import React from "react";
import { NavLink } from "react-router-dom";

const CustomNavLink = ({ activeStyle, ...props }) => {
  return (
    <NavLink
      {...props}
      // style={({ isActive }) => (isActive ? activeStyle : undefined)}
      style={({ isActive }) =>
        isActive ? activeStyle : { textDecoration: "none" }
      }
    />
  );
};

export default CustomNavLink;
