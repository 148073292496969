import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useContext, useMemo, useState } from "react";
import { NovaContext } from "../context";

export const MUIWrapperContext = createContext({
  toggleColorMode: () => {},
});

export default function MUIWrapper({ children }) {
  const { actions, themeMode } = useContext(NovaContext);

  const [mode, setMode] = useState(themeMode ? themeMode : "light" );
  const muiWrapperUtils = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        actions.changeThemeState(mode === "light" ? "dark" : "light");
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
        ? {
            // palette values for light mode
            primary: {
              main: "#1976d2",
            },
            secondary: {
              main: "#e30d7d",
            },
            background: {
              default: "#c6c6c67d",
              paper: "#fff",
            },
          }
        : {
            // palette values for dark mode
            primary: {
              main: "#1976d2",
            },
            background: {
                // default: "#c6c6c6",
                // paper: "#c6c6c6",
              },
          }),
        },
        
      }),
    [mode]
  );

  return (
    <MUIWrapperContext.Provider value={muiWrapperUtils}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MUIWrapperContext.Provider>
  );
}
