import React, { useState } from "react";

// internal misc
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../../components/Helper/MUIStyledComponents";
import { useExpensesContext } from "../../../components/context/ExpensesContext";
import { alerts, displayAlert } from "../../../components/Helper/AlertToastify";
import { addExpense, deleteExpense } from "../../../utils/api/api_expenses";

// components
import DateRange from "../../../components/common/DateRange/DateRange";
import Confirmation from "../../../components/common/Confirmation";
import { renderNoRowDataOverlay } from "../../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";
import CustomLoadingOverlay from "../../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";

// actions
import { ExpensesActions } from "../../../reducers/ExpensesReducer";
import { DateRangeActions } from "../../../components/common/DateRange/DateRangeReducer";

// helper
import { prepareExpenseColumns } from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Expenses";

import NewExpense from "../../../components/popups/NewExpenseV3";

// mui
import Box from "@mui/material/Box";
import { checkAccess } from "../../../components/Helper/GlobalHelpers";

const ExpensesList = ({ columnVisibilityModel }) => {
  const [selectedExpense, setSelectedExpense] = useState("");
  const {
    currentRole,
    loading,
    expenseList,
    dateRange,
    dispatchDateRange,
    dispatchExpenses,
  } = useExpensesContext();
  
  // modal states
  const [expenseModal, setExpenseModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });
  // const hasAdminAccess = checkAccess(currentRole, "Administrador");
  // const hasManagerAccess = checkAccess(currentRole, "Gerente");
  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  const changeDateRange = (updatedDates) => {
    dispatchDateRange({
      type: DateRangeActions.SET_DATE_RANGE_FILTER,
      payload: updatedDates,
    });
  };

  const handleCreateExpense = async (newExpense) => {
    await addExpense(newExpense)
      .then((resp) => {
        dispatchExpenses({
          type: ExpensesActions.CREATE_EXPENSE,
          payload: resp,
        });
      })
      .catch((err) => {
        console.log("An error occurred while creating an expense")
      });
  };

  const handleRemoveExpense = async () => {
    await deleteExpense(selectedExpense)
      .then(() => {
        setConfirmation(false);
        setSelectedExpense("");
        dispatchExpenses({
          type: ExpensesActions.REMOVE_EXPENSE,
          payload: selectedExpense,
        });
      })
      .catch((err) =>
        displayAlert(alerts.ERROR, `No se ha podido eliminar el gasto: ${err}`)
      );
  };
  const visibilityModel = columnVisibilityModel(currentRole);

  return (
    <div>
      <Confirmation
        open={confirmation}
        setOpen={setConfirmation}
        textContent={"Estás seguro de que quieres revocar este gasto?"}
        confirmationMethod={handleRemoveExpense}
      />
      <Box height="85vh" width="100%">
        <CustomDataGrid
          columns={prepareExpenseColumns(setConfirmation, setSelectedExpense)}
          rows={expenseList}
          getRowId={(row) => row.id}
          slots={{
            toolbar: CustomGridToolbar,
            loadingOverlay: CustomLoadingOverlay,
            noRowsOverlay: () =>
              renderNoRowDataOverlay("No hay gastos en este periodo"),
          }}
          slotProps={{
            toolbar: {
              component1:
                hasAuditorAccess || hasRegionalManagerAccess ? (
                  <DateRange
                    dateObject={dateRange}
                    changeMethod={changeDateRange}
                  />
                ) : null,
              component2: (
                <NewExpense
                  open={expenseModal}
                  setOpen={setExpenseModal}
                  updateExpenses={handleCreateExpense}
                  showButton={true}
                />
              ),
            },
          }}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          filterModel={filterModel}
          initialState={{
            columns: {
              columnVisibilityModel: visibilityModel,
            },
            filter: {
              filterModel: filterModel,
            },
          }}
          loading={loading}
        />
      </Box>
    </div>
  );
};

export default ExpensesList;
