import { addHours, format, parseISO } from "date-fns";
import React, { Fragment, useState, useEffect } from "react";
import { RiSearch2Line, RiFileUserFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { getTimeDifferenceInHours } from "../Helper/GlobalHelpers";


const ListAuditTrail = (props) => {

    const {
        audittrails,
        searchBar,
        reportMode
    } = props;

    const [searchText, setSearchText] = useState("");
    
    let navigate = useNavigate();
    const redirectToCustomer = ( customerId ) => {
        const path = `/Customers/${customerId}`;
        navigate(path);
    }
    
    // let navigate = useNavigate();
    const redirectToEmployee = ( employeeId ) => {
        const path = `/Employees/${employeeId}`;
        navigate(path);
    }

    return (
        <Fragment>
            {
                searchBar ?
                    <div className="d-flex quick-search-form">
                        <div className="input-group">
                            <span className="input-group-text">
                                <RiSearch2Line />
                            </span>
                            <input type="text"
                                name="searchField"
                                className="form-control mx-2"
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                            />
                        </div>
                    </div>
                    :
                    null
            }

            <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                    <thead>
                        <tr>
                            <th>Tipo</th>
                            {reportMode ?
                                <th>Subtipo</th>
                                : null
                            }
                            <th>Márca de tiempo</th>
                            <th>Empleado</th>
                            {reportMode ?
                                <th>Cliente</th>
                                : null
                            }
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {audittrails && audittrails.filter((value) => {
                            if (searchText === "") {
                                return value
                            }
                            else if (value.audittype.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            else if (value.subtype.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            else if (value.audittype.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            else if (value.customer && value.customer.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            else if (value.description && value.description.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            else if (value.customer_name && value.customer_name.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            else if (value.employee_name && value.employee_name.toLowerCase().includes(searchText.toLowerCase())) {
                                return value
                            }
                            return null;
                        }).map((AuditTrail, i) => (
                            <tr key={i}>
                                {reportMode ?
                                    <td>{AuditTrail.audittype}</td>
                                    : null
                                }
                                <td>{AuditTrail.subtype}</td>
                                <td>{format(parseISO(AuditTrail.timestamptz), "dd/MM/yyyy HH:mm:ss")} hrs                            </td>
                                <td>{AuditTrail.employee_name} <RiFileUserFill onClick={() => redirectToEmployee(AuditTrail.employee)} /></td>
                                {reportMode ?
                                    <td className={ AuditTrail.customer_name === '' ? 'display-hidden' : '' }>
                                        {AuditTrail.customer_name} <RiFileUserFill onClick={() => redirectToCustomer(AuditTrail.customer)} />
                                    </td>
                                    : null
                                }
                                <td>{AuditTrail.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default ListAuditTrail;