
export const CashflowActions = {
    SET_CASHFLOW: 'SET_CASHFLOW',
    CREATE_CASHFLOW: 'CREATE_CASHFLOW',
    REMOVE_CASHFLOW: 'REMOVE_CASHFLOW'
}

export const CashflowReducer = (state, action) => {
    switch (action.type) {
        case CashflowActions.SET_CASHFLOW:
            return action.payload;
        case CashflowActions.CREATE_CASHFLOW:
            return [action.payload, ...state];
        case CashflowActions.REMOVE_CASHFLOW:
            return state.filter((cashflow) => cashflow.id !== action.payload);
        default:
            return state;
    }
}