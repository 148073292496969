import React, { createContext, useContext, useState, useReducer } from 'react';
import { NovaContext } from '.';

// misc
import AuditController from '../controllers/AuditController';
import { DateRangeReducer } from '../common/DateRange/DateRangeReducer';
import { AuditReducer } from '../../reducers/AuditReducer';
import { LoadingReducer } from '../../reducers/LoadingReducer';

// date-fns
import subMonths from 'date-fns/subMonths';
import startOfDay from 'date-fns/startOfDay';

// context states
const initialAuditState = [];
const initialDateRangeState = {
    start: startOfDay(subMonths(new Date(), 1)),
    end: new Date()
}
const initialLoadingState = true;

const AuditContext = createContext();

const AuditProvider = ({ children }) => {
    const { filial, currentRole } = useContext(NovaContext);
    const [audits, dispatchAudit] = useReducer(AuditReducer, initialAuditState);
    const [dateRange, dispatchDateRange] = useReducer(DateRangeReducer, initialDateRangeState);
    // const [loading, setLoading] = useState(true);
    const [loading, dispatchLoading] = useReducer(LoadingReducer, initialLoadingState);

    return (
        <AuditContext.Provider
            value={{
                filial,
                currentRole,
                loading,
                dispatchLoading,
                audits,
                dispatchAudit,
                dateRange,
                dispatchDateRange
            }}
        >
            {children}
            <AuditController />
        </AuditContext.Provider>
    )
}

export { AuditContext, AuditProvider };

export const useAuditContext = () => {
    return useContext(AuditContext);
}