import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ItemCaja } from "./Helper/MUIStyledComponents";
import CashRegisterControl from "./popups/CashRegisterControlCheck";
import { NovaContext } from "./context";

function CashRegisterTotal() {
    const { actions, filial } = useContext(NovaContext);

    const [cashRegisterTotal, setCashRegisterTotal] = useState("");


  useEffect(() => {
    actions.getCashRegister().then((data) => setCashRegisterTotal(data.total));
    let interval = setInterval(() => {
      actions.getCashRegister().then((data) => setCashRegisterTotal(data.total));
    }, 5000);

    return () => {
		clearInterval(interval);
	};
  }, []);

  return (
    <ItemCaja>
      <Typography sx={{ textAlign: "right", fontSize: { xs: 18,sm:18, md: 22 } }} >
        Total en Caja:{" "}
      </Typography>
      <Typography variant="h4" sx={{ textAlign: "right" }}>
        ${cashRegisterTotal}
      </Typography>
      <CashRegisterControl cashRegisterTotal={cashRegisterTotal} />
    </ItemCaja>
  );
}

export default CashRegisterTotal;
