import React from "react";

// component
import LoansListWrapper from "../../components/Wrappers/LoansListWrapper";

// mui
import Grid from "@mui/material/Grid2";
import { ItemPaper } from "../../components/Helper/MUIStyledComponents";
import BreadcrumbNavigation from "../Reports/BreadcrumbReports";
import { visibilityModelLoanOverview } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Loans";
import { Typography } from "@mui/material";

const Index = () => {
  return (
    <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }}>
      <Grid size={12} display="flex" justifyContent="left" alignItems="left">
        <Typography variant="h4" color="textPrimary">
          Créditos
        </Typography>
      </Grid>
      <Grid size={12}>
        <ItemPaper>
          <LoansListWrapper
            columnVisibilityModel={visibilityModelLoanOverview}
            statusFilter={"OPEN"}
            sortingFilter={"cus.customer_idnumber ASC"}
          />
        </ItemPaper>
      </Grid>
    </Grid>
  );
};

export default Index;
