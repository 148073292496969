
//declare the object structure
export const generateParameterTrapezoidal = (bottomLeft, topLeft, topRight, bottomRight, minValue, maxValue, step) => {
    return {
        bottomLeft,
        topLeft,
        topRight,
        bottomRight,
        minValue,
        maxValue,
        step,
    }
}

export const generateParameterTriangular = (left, center, right, minValue, maxValue, step) => {
    return {
        left,
        center,
        right,
        minValue,
        maxValue,
        step,
    }
}

//*****/ declaration of the parameters based on cases
// const generateParameter = (membership) => {
//     switch (membership) {
//         case 'riskNormal':
//             return {
//                 bottomLeft: 0,
//                 topLeft: 0,
//                 topRight: 20,
//                 bottomRight: 30,
//                 minValue: 0,
//                 maxValue: 100,
//                 step: 10,
//             }
//     }
// }
