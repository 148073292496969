import React, { useContext, useState } from 'react';

// mui 
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Grid2 as Grid } from '@mui/material';

// date-fns
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';

// internal 
// import CustomButton from '../../components/common/CustomButton';
import { NovaContext } from '../../components/context';
import { getTimeDifferenceInHours } from '../../components/Helper/GlobalHelpers';
import { createMedia } from '../../utils/api/api_document';
import { getAcceptedDocumentFormats } from '../../components/Helper/MediaHelpers';
import { alerts, displayAlert } from '../../components/Helper/AlertToastify';
import { uploadDestination } from '../../components/Helper/MediaHelpers';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const NewDocument = (props) => {
    const { objectUuid, afterAddFunction, destination } = props;
    const { authenticatedUser, filial } = useContext(NovaContext);
    const [open, setOpen] = React.useState(false);
    const [documentPath, setDocumentPath] = useState();
    const [selectedDocument, setSelectedDocument] = useState('');
    const [lock, setLock] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setDocumentPath('');
        setSelectedDocument('');
        setOpen(false);
    };

    const handleSelection = (e) => {
        e.preventDefault();
        setDocumentPath(e.target.value);
        const uploadedDocument = e.target.files[0];
        setSelectedDocument(uploadedDocument);
    }

    const validateUpload = () => {
        setErrors([]);
        if (documentPath === undefined) {
            errors.push("No se ha detectado ningún archivo de la captura de imágenes");
            return false;
        }

        const fileExtension = documentPath.substring(documentPath.lastIndexOf('.'));
        if (fileExtension === ".doc" ||
            fileExtension === ".docx" ||
            fileExtension === ".pdf" ||
            fileExtension === ".xls" ||
            fileExtension === ".xlsx"
        ) {
            return true;
        } else {
            errors.push("Tipo de archivo no válido. Sólo se permiten doc, docx o pdf");
            return false;
        }
    }

    const handleDocumentSubmit = async (e) => {
        e.preventDefault();
        setLock(true);

        if (validateUpload()) {
            let documentObject = {
                documentName: selectedDocument.name,
                employeeId: authenticatedUser.employee_id,
                filialId: filial,
                isProfile: false,
                mediaType: "DOCUMENT",
                dateCreated: format(addHours(new Date(), +getTimeDifferenceInHours()), "yyyy-MM-dd HH:mm:ss")
            };

            switch (destination) {
                case uploadDestination.CUSTOMER:
                    documentObject = {...documentObject, customerId: objectUuid}
                case uploadDestination.EMPLOYEE:
                    // add a new column -> foremployee_id
                    // change this so it isn't confusion
                    // add documentation to highlight what objectUuid can be
                    documentObject = {...documentObject, forEmployeeId: objectUuid}
            }

            const newDocument = {
                document: selectedDocument,
                otherinfo: JSON.stringify(documentObject)
            }

            await createMedia("documents", newDocument)
                .then(resp => {
                    afterAddFunction(resp);
                    displayAlert(alerts.SUCCESS, "Documento cargada correctamente");
                    setLock(false);
                    handleClose();
                })
                .catch(err => {
                    setLock(false);
                    displayAlert(alerts.ERROR, `Algo ha fallado en la solicitud: ${err}`);
                });
        }
    }

    return (
        <Box
            sx={{ ml: { xs: 0.5, md: 0.5 } }}
        >
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                size='small'
            >
                Añadir Documento
            </Button>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Añadir Documento
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container>
                        <Grid xs={8} md={8} paddingRight={"5px"}>
                            <TextField
                                label="Documento"
                                name="document-upload"
                                value={documentPath}
                                disabled
                                size='small'
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} md={4} display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                variant="contained"
                                component="label"
                                sx={{ marginRight: "1rem" }}
                                disabled={lock}
                            >
                                Cargar documento
                                <input
                                    type="file"
                                    accept={getAcceptedDocumentFormats()}
                                    hidden
                                    // ref={fileRef}
                                    onChange={handleSelection}

                                />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        color='success'
                        variant='contained'
                        disabled={lock}
                        onClick={(e) => handleDocumentSubmit(e)}>
                        Guardar
                    </Button>
                    <Button
                        autoFocus
                        variant="outlined"
                        disabled={lock}
                        onClick={handleClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}

export default NewDocument;