// Utility import
import React, { Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import Carousel from "react-elastic-carousel";
import { NovaContext } from "../context";

// Component import
import EditFilial from "./EditFilial";
import NewFilial from '../popups/NewFilial';
import { RiSearch2Line } from "react-icons/ri";


const ListFilialsBySearch = ( props ) => {
    
    const {
        filials,
        setLockStatus
    } = props;

    // State variables
    const { actions, currentRole } = useContext(NovaContext);
    const [searchText, setSearchText] = useState("");
    let navigate = useNavigate();

    const Breakpoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 }
    ]

    const handleView = (filialId) => {
        actions.setActiveFilial(filialId);
        const path = `Filial/${filialId}`;
        navigate(path);
        //window.location.reload();
    };

    return (
        <Fragment>
            {/* <NewFilial /> */}
            <div className="d-flex quick-search-form">
                <div className="input-group">
                    <span className="input-group-text">
                        <RiSearch2Line />
                    </span>
                    <input type="text"
                        name="searchField"
                        className="form-control mx-2"
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                    />
                    {currentRole === 'Root'
                        ?
                        <NewFilial setLockStatus={setLockStatus}/>
                        : null}
                </div>
            </div>
            <div>
                {filials && filials.filter((value) => {
                    if (searchText === "") {
                        return value
                    }
                    else if (value.filial_name && value.filial_name.toLowerCase().includes(searchText.toLowerCase())) {
                        return value
                    }
                    return null;
                }).map((filial, i) => (
                    <div className="carusel-item-card mt-3" key={i}>
                        <div key={`caruselitemid ${i}`}>
                            <div className="carusel-item-title">{filial.filial_name}</div>
                            <div className="carusel-item-subtitle">{filial.filial_address}</div>
                            <div className="carusel-item-subtitle">Tel.- {filial.telephone}</div>
                            <div className="carusel-item-buttons">
                                {/* { filial.role_name === 'Administrador' ?
                                        <EditFilial filial={filial} />
                                    : null } */}
                                <button type="button"
                                    className="btn btn-warning ml-2"
                                    data-toggle="modal"
                                    data-target={`#id${filial.filial_id}`}
                                    onClick={() => handleView(filial.filial_id)}>
                                    Seleccionar
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
};

export default ListFilialsBySearch;