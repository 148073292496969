import React, { useState } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles"; // deprecated warning
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PatternIcon from "@mui/icons-material/Pattern";

// import Box from '@mui/system/Box';

// Material UI
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { NovaContext } from "../../components/context";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// export default function CustomizedDialogs() {
export const Employee_Password_Edit = (props) => {
  // initialise props
  const { setIsOpen, employee } = props;

  // context data
  const { actions, currentRole } = React.useContext(NovaContext);

  const [open, setOpen] = React.useState(false);

  const [errors, setErrors] = useState([]);

  // Set up refs for the react form -> refs are automatically updated when bound
  const [EmployeeId, setEmployeeId] = useState("");
  const [EmployeeName, setEmployeeName] = useState("");
  const [EmployeePassword, setEmployeePassword] = useState("");

  // triggers the form's object initialisation as a result of React's multi load rendering
  const initializeForm = (Object) => {
    // console.log(Object,EmployeeName);
    setEmployeeId(Object ? (Object.employee_id ? Object.employee_id : "") : "");
    setEmployeeName(
      Object ? (Object.employee_name ? Object.employee_name : "") : ""
    );
    setEmployeePassword(
      Object ? (Object.employee_address ? Object.employee_address : "") : ""
    );
  };

  const handleClick = async () => {
    initializeForm(employee);
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleClick();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setErrors([]);
    initializeForm(employee);
  };

  // reverts form data to initial state. Necessary for the shrink attribute of TextField
  const revertChange = () => {
    // setSelectedArea("");
    clearFields();
    handleClose();
  };

  const validateData = () => {
    setErrors([]);
    let isValid = true;
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    const isEntryValid = true;
    console.log(EmployeeId, EmployeeName, EmployeePassword);
    if (isEntryValid) {
      const tempEmployee = {
        id: EmployeeId,
        // name: EmployeeName,
        password: EmployeePassword,
      };

      // const employeeResp = {};
      const employeeResp = await actions.updateEmployeePassword(tempEmployee);

      if (employeeResp) {
        revertChange();
      } else {
        console.log("Employee change password failed");
      }
    }

    handleClose();
  };

  return (
    <Box paddingLeft={1}>
      <Button startIcon={<PatternIcon />} onClick={handleClickOpen}>
        Cambiar contraseña
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {"Asignar nueva contraseña"}
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <form onSubmit={handleCreate}>
            <Grid container padding="10px">
              <Box
                component={"span"}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                marginBottom={1}
              >
                <TextField
                  variant="outlined"
                  label="Nombre"
                  id="name"
                  fullWidth
                  value={EmployeeName}
                  // onChange={(e) => setEmployeeName(e.target.value)}
                  disabled
                />
              </Box>

              <Box
                component={"span"}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                marginBottom={1}
              >
                <TextField
                  label="Contraseña"
                  id="password"
                  type="password"
                  fullWidth
                  // inputRef={EmployeePassword}
                  value={EmployeePassword}
                  onChange={(e) => setEmployeePassword(e.target.value)}
                  // placeholder='Apellido'
                />
              </Box>
            </Grid>
            <DialogActions>
              <Button
                type="submit"
                autoFocus
                color="success"
                variant="contained"
              >
                Continuar
              </Button>
              <Button
                autoFocus
                color="secondary"
                variant="contained"
                onClick={() => revertChange()}
              >
                Cancelar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

export default Employee_Password_Edit;
