import React, { useState, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { Consumer } from '../context/index';

const NewFilial = (props) => {

    const {
        setLockStatus
    } = props

    // Use state to toggle add filial window
    const [display, toggleDisplay] = useState(false);

    // Set up refs for the react form -> refs are automatically updated when bound
    const Name = createRef('');
    const Address = createRef('');
    const Telephone = createRef('');

    let navigate = useNavigate();

    const handleClick = () => {
        toggleDisplay(!display);
    }

    const closeModalView = () => {
        // clearFields();
        toggleDisplay();
    }

    // const clearFields = () => {
    //     Name.current.value = '';
    //     Address.current.value = '';
    //     Telephone.current.value = '';
    // }

    return (
        <Consumer>
            { ({ actions }) => {
                const handleCreate = async (e) => {
                    e.preventDefault();
                    setLockStatus(true);
    
                    const tempFilial = 
                        {
                            "name": Name.current.value,
                            "address": Address.current.value,
                            "telephone": Telephone.current.value
                        }
                    const filial = await actions.addFilial(tempFilial);
                    console.log(`New filial: ${filial.filial_id}`);
                        if (filial !== undefined){
                            //redirect only if filial added -> return full filial object                            
                            closeModalView();          
                            // console.log(`AnswerFilial: ${filial.filial_id}`);             
                            const path = `/`;
                            navigate(path);
                        } else {
                            console.log('AddFilial Failed');
                        }
                    setLockStatus(false);

                }

                return (
                    <form onSubmit={handleCreate}>
                        <button type="button" 
                                className="btn btn-primary btn-hover-light ml-2" 
                                data-toggle="modal"
                                onClick={handleClick}
                                >
                            Nueva
                        </button>

                        <div className={`modal ${display ? 'show' : ''}`}>
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h4 className="modal-title">
                                            Crear nueva sucursal
                                        </h4>
                                        <button type="button" 
                                                className="close" 
                                                data-dismiss="modal" 
                                                onClick={() => handleClick()}>&times;
                                        </button>
                                    </div>

                                    <div className="modal-body">
                                        <label>Nombre: 
                                            <input  type="text" 
                                                    className="form-control"
                                                    ref={Name}
                                                    required
                                            />
                                        </label>

                                        <label>Dirección:
                                            <input  type="text" 
                                                    className="form-control"
                                                    ref={Address}
                                                    required
                                            />
                                        </label>

                                        <label>Teléfono:
                                            <input  type="text" 
                                                    className="form-control"
                                                    ref={Telephone}
                                                    required
                                            />
                                        </label>

                                    </div>

                                    <div className="modal-footer">
                                        <button type="submit" 
                                                className="btn btn-success" 
                                                data-dismiss="modal"
                                        >
                                                    Crear
                                        </button>
                                        <button type="button" 
                                                className="btn btn-default" 
                                                data-dismiss="modal"
                                                onClick={() => closeModalView()}
                                        >
                                                    Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Consumer>
    )
    
    
};

export default NewFilial;