import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Grid2 as Grid, Typography } from "@mui/material";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";

import EmployeeInfoBox from "./EmployeeInfoBox";

const EmployeeHeader = ({ employee}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <ItemBox>
            <EmployeeInfoBox
              employee={employee}
              // loansOnMeter={loansOnMeter}
              // currentLoan={loan}
            />
          </ItemBox>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <ItemBox>
            <Typography
              variant="h4"
              align="center"
              component="div"
              sx={{ ml: 1 }}
            >
              <Box
                sx={{ mt: 5 }}
              >{`Detalles pendientes de definir.`}</Box>
            </Typography>
          </ItemBox>
        </Grid>
      </Grid>
    </Box>
  );
};

EmployeeHeader.propTypes = {
  employee: PropTypes.object.isRequired,
  // loansOnMeter: PropTypes.array.isRequired,
};

export default EmployeeHeader;
