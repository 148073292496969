import React from 'react';
import { Card, CardActionArea, CardContent, Tooltip, Typography } from '@mui/material';

const CardBlockItem = ({ item, handleView }) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: "15px",
      }}
    >
      <CardActionArea
        onClick={() => handleView(item.path)}
        sx={{ flex: 1 }}
      >
        <CardContent>
          <Tooltip title={item.header} placement="top" arrow>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {item.icon}
              <Typography
                variant="h5"
                color="textSecondary"
                align="center"
              >
                {item.header}
              </Typography>
            </div>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardBlockItem;
