import { addHours, formatISO } from 'date-fns';
import React, { useContext, useRef, useState, Fragment, useEffect } from 'react';
import { NovaContext } from '../context';
import { checkAccess, getTimeDifferenceInHours } from '../Helper/GlobalHelpers';
import Modal from './Modal';

const NewPayCheck = (props) => {

    const { buttonClass } = props
    const { actions, filial, authenticatedUser, currentRole } = useContext(NovaContext);
    const [display, toggleDisplay] = useState(false);
    const [validationStatus, setValidationStatus] = useState(true)

    const hasAdminAccess = checkAccess(currentRole, 'Administrador');
    const description = useRef();
    const amount = useRef();

    const initializeForm = () => {
        amount.current.value = '0';
        description.current.value = '';
    };

    const handleClick = () => {
        initializeForm()
        toggleDisplay(!display)
    };

    const closeModalView = () => {
        initializeForm();
        toggleDisplay();
    };

    const handleChange = (event) => {
        //TBD add a validation for the onChange process
        setValidationStatus(validate())
    }

    const validate = () => {
        //TBD add a validation 
        return true
    }

    const handleCreate = async (e) => {
        e.preventDefault();

        const validateCheck = validate()
        if (validateCheck) {
            const currentDate = addHours(new Date(),+getTimeDifferenceInHours());
            const tempPayCheck =
            {
                "amount": amount.current.value,
                "description": description.current.value,
                "createdate": formatISO(currentDate),
                "employeeId": authenticatedUser.employee_id,
                "filialId": filial
            }
            console.log(tempPayCheck);
            const newPayCheck = await actions.addPayCheck(tempPayCheck);
            console.log(newPayCheck);
            closeModalView();
            window.location.reload();
        }
    };

    return (
        hasAdminAccess?
            <form onSubmit={handleCreate}>
                <Modal
                    classNames={`modal-dropdown ${display ? 'show' : ''}`}
                    toggleModal={handleClick}
                    buttonName="Agregar pago de Nomina"
                    buttonClass={`${buttonClass ? buttonClass : 'btn btn-white font-weight-bold hide-button'}`}
                    header="Agregar pago de Nomina"
                    body={() => (
                        <Fragment>
                            <div className="grid-container-form">

                                <label>Cantidad en efectivo
                                    <input type="number"
                                        className={`form-control text-right`}
                                        ref={amount}
                                        required
                                        onChange={(e) => handleChange(e)}
                                    />
                                </label>

                                <label>Detalles del pago de Nomina
                                    <textarea type="text"
                                        className="form-control"
                                        ref={description}
                                        required
                                    />
                                </label>

                            </div>
                        </Fragment>
                    )}

                    footer={() => (
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={() => handleCreate()}
                            >
                                Continuar
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={() => closeModalView()}
                            >
                                Cancelar
                            </button>
                        </Fragment>
                    )}
                />
            </form>
            :
            null
    )

}

export default NewPayCheck;