import React, { useEffect } from "react";

// internal
import { getExpenses } from "../../utils/api/api_expenses";
import { useExpensesContext } from "../context/ExpensesContext";

//reducers
import { ExpensesActions } from "../../reducers/ExpensesReducer";
import { LoadingActions } from "../../reducers/LoadingReducer";

// api library
import { alerts, displayAlert } from "../Helper/AlertToastify";

const ExpensesController = ({
  sortingFilter,
  customerIdFilter,
  employeeIdFilter,
}) => {
  const {
    filial,
    dateRange,
    dispatchExpenses,
    dispatchLoading,
  } = useExpensesContext();
  const start = dateRange.start.toISOString();
  const end = dateRange.end.toISOString();

  useEffect(() => {
    // Construct the query parameters
    let queryParams = `filial=${filial}`;

    if (start) {
      queryParams += `&start=${start}`;
    }
    if (end) {
      queryParams += `&end=${end}`;
    }
    if (sortingFilter) {
      queryParams += `&sorting=${sortingFilter}`;
    }
    console.log(queryParams);

    getExpenses(
        queryParams
        // `filial=${filial}&start=${start}&end=${end}`
    )
      .then((resp) =>
        dispatchExpenses({ type: ExpensesActions.SET_EXPENSES, payload: resp })
      )
      .catch((err) =>
        displayAlert(alerts.ERROR, `No se han podido cargar los gastos: ${err}`)
      )
      .finally(() =>
        dispatchLoading({ type: LoadingActions.SET_LOADING, payload: false })
      );
  }, [filial, dateRange]);

  return null;
};

export default ExpensesController;
