import {
  calculateDelayedWeeks,
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
} from "../Helper/LoanHelpers";
import { addHours, format, formatISO, parseISO } from "date-fns";
import React, { Fragment, useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../context";
import NewPayment from "../popups/NewPayment";
import { loanListMode } from "../Helper/Enums";
import Dropdown from "../Dropdown";
import { WeekDayDueToPayment } from "../Helper/Enums";
import { RiSearch2Line } from "react-icons/ri";
import { checkAccess, getTimeDifferenceInHours } from "../Helper/GlobalHelpers";
import Button from "./Button";
import { TiCancel } from "react-icons/ti";

const ListLoansBySearch = React.memo((props) => {
  const {
    loans,
    filterStatus,
    pageReportMode,
    lockStatus,
    setLockStatus,
    selectAction,
  } = props;

  // State variables
  const { currentRole } = useContext(NovaContext);
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");

  const [searchText, setSearchText] = useState("");
  const [filterLoanListMode, setFilterListMode] = useState("ALL");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const processedLoans = useMemo(() => {
    if (loans) {
          console.log('Loans:',loans);
      return loans.map((item) => ({
        ...item,
        fullName: `${item.customer_name} ${item.customer_lastname}`,
      }));
    }
    return [];
  }, [loans]);

  const calculateFilterDates = (value) => {
    // const currentDate = new Date();
    const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
    var startDate = null;
    var endDate = null;
    if (value === "OVERDUE") {
      endDate = currentDate;
    } else if (value === "ONTIME") {
      startDate = currentDate;
    }
    console.log(startDate, endDate);
    setFilterStartDate(startDate);
    setFilterEndDate(endDate);
  };

  const handleChange = (event, section) => {
    const value = event.target.value;
    switch (section) {
      case "filterLoanListMode":
        setFilterListMode(value);
        calculateFilterDates(value);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div className="d-flex quick-search-form">
        <div className="input-group">
          <span className="input-group-text">
            <RiSearch2Line />
          </span>
          <input
            type="text"
            name="searchField"
            className="form-control mx-2"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {/* <Dropdown className="btn btn-outline-info align-left" options={tempEnumLoanStatus} value={filterStatus}      onChange={(e) => handleChange(e,'filterStatus')} /> */}
          <Dropdown
            className="btn btn-outline-info align-left"
            options={loanListMode}
            value={filterLoanListMode}
            onChange={(e) => handleChange(e, "filterLoanListMode")}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-head-custom table-vertical-center">
          <thead>
            <tr>
              <th>ID</th>
              <th>Cliente</th>
              <th>Teléfono</th>
              <th>Capital inicial</th>
              <th>Día de pago</th>
              <th>Fecha de prestamo</th>
              <th>Fecha de pago</th>
              <th>Capital pendiente</th>
              <th>Refrendo</th>
              <th className="table-title">
                Total
                <br></br>
                <span className="text-small-info">(mora independiente)</span>
                {/* <span data-toggle="tooltip" title="Some amazing content!">(mora independiente)</span> */}
              </th>
              <th>Mora</th>
              <th>Atraso</th>
              {pageReportMode ? (
                <>
                  <th>Hora de prestamo</th>
                  <th>Uuid</th>
                  {hasAuditorAccess ? <th>Revocar</th> : null}
                </>
              ) : (
                <>
                  <th>Localidad</th>
                  <th>Dirección</th>
                  <th>Acciones</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {processedLoans
              .filter((value) => {
                if (value.loan_status === filterStatus) {
                  if (
                    (filterStartDate &&
                      value.loan_datedue >= formatISO(filterStartDate)) ||
                    (filterEndDate &&
                      value.loan_datedue <= formatISO(filterEndDate)) ||
                    (!filterStartDate && !filterEndDate)
                  ) {
                    if (searchText === "") {
                      return value;
                    } else if (value.loan_customerid === searchText) {
                      return value;
                    } else if (
                      value.fullName
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    ) {
                      return value;
                    } else if (
                      value.customer_idnumber === parseInt(searchText)
                    ) {
                      return value;
                    } else if (
                      value.customer_location &&
                      value.customer_location
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    ) {
                      return value;
                    }
                  }
                }
                return null;
              })
              .map((loan, i) => (
                <tr key={loan.loan_id}>
                  <td>{loan.customer_idnumber} </td>
                  <td>{loan.fullName} </td>
                  <td>{loan.customer_telephone} </td>
                  <td>{loan.loan_amount} </td>
                  {/* <td>{getValueFromEnum('WeekDayDueToPayment', loan.loan_dueday, loan.loan_id)}         </td> */}
                  <td>{WeekDayDueToPayment[loan.loan_dueday - 1].label} </td>
                  <td>
                    {format(parseISO(loan.loan_dateissue), "dd/MM/yyyy ")}{" "}
                  </td>
                  <td>{format(parseISO(loan.loan_datedue), "dd/MM/yyyy ")} </td>
                  <td>{loan.loan_remaining} </td>
                  <td>{calculateOpenInterest(loan)} </td>
                  <td>{calculateOpenAmountTotal(loan)} </td>
                  <td>{calculateOpenArrear(loan)} </td>
                  <td>{calculateDelayedWeeks(loan)} </td>
                  {pageReportMode ? (
                    <>
                      <td>
                        {format(parseISO(loan.loan_dateissue), "HH:mm:ss")} hrs{" "}
                      </td>
                      <td>
                        <a href={`/Ticket/${loan.loan_id}`}>{loan.loan_id}</a>
                      </td>
                      <td>
                        {hasAuditorAccess ? (
                          <Button
                            type={"button"}
                            nameOfClass={
                              "btn ml-2 btn-warning btn-hover-light icon-m alarm"
                            }
                            clickBehaviour={() =>
                              selectAction(loan.loan_id, "RevokeLoan")
                            }
                            text={""}
                            icon={<TiCancel />}
                          />
                        ) : null}
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{loan.customer_location} </td>
                      <td>{loan.customer_address} </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {loan.loan_status === "OPEN" ? (
                            <NewPayment
                              loan={loan}
                              loanId={loan.loan_id}
                              loanAmount={loan.loan_remaining}
                              lockStatus={lockStatus}
                              setLockStatus={setLockStatus}
                            />
                          ) : null}
                        </div>
                      </td>
                    </>
                  )}
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
});

export default ListLoansBySearch;
