import React, { useState, useRef, useContext } from "react";

import {
    Box,
    IconButton,
    useTheme,   
  } from "@mui/material";
import { MUIWrapperContext } from "./ThemeWrapper";


export const ThemeModeSwitch = (props) => {

    const theme = useTheme();
    const muiUtils = React.useContext(MUIWrapperContext);

  return (
    <Box paddingLeft={1}>
         <IconButton
            sx={{ fontSize: "1rem" }}
            onClick={muiUtils.toggleColorMode}
            color="inherit"
            disableTouchRipple
            disableRipple
          >
            {theme.palette.mode === "dark" ? (
              <span role="img" aria-label="sun">
                ☀️ GO LIGHT
              </span>
            ) : (
              <span role="img" aria-label="moon">
                🌚 GO DARK
              </span>
            )}
          </IconButton>
    </Box>
  );
};

export default ThemeModeSwitch;
