import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../../components/context";
import { checkMinAccess } from "../../components/Helper/GlobalHelpers";
import AdminCardItem from "./AdminCardItem";
import Grid from "@mui/material/Grid2";
import { PeopleOutline, Settings } from "@mui/icons-material";
import CardBlockItem from "../../components/CardBlockItem";

const Index = () => {
  const { filials, currentRole } = useContext(NovaContext);

  const hasMinManagerAccess = checkMinAccess(filials, "Gerente");
  const hasMinRegionalManagerAccess = checkMinAccess(
    filials,
    "Gerente Regional"
  );
  const hasMinAuditorAccess = checkMinAccess(filials, "Auditor");
  const hasMinAdminAccess = checkMinAccess(filials, "Administrador");
  const hasMinRootAccess = checkMinAccess(filials, "Root");

  let navigate = useNavigate();

  const handleView = (string) => {
    const path = `${string}`;
    navigate(path);
  };

  const cardItems = [
    { header: "Empleados", path: "/Employees", access: hasMinRegionalManagerAccess || hasMinAuditorAccess, icon: <PeopleOutline fontSize="large" /> },
    { header: "Empleados (Matriz)", path: "/EmployeeMatrix", access: hasMinRegionalManagerAccess || hasMinAuditorAccess, icon:null },
    { header: "Empleados (todos)", path: "/EmployeeOverview", access: hasMinAdminAccess, icon: null },
    { header: "Corte de Caja", path: "/RegisterCut", access: hasMinManagerAccess, icon: null },
    { header: "Corte de Sucursales", path: "/ControllingPerCluster", access: hasMinRegionalManagerAccess || hasMinAuditorAccess, icon: null },
    { header: "Corte de Sucursales (old)", path: "/RegisterCutMultiFilial", access: hasMinAdminAccess, icon: null },
    { header: "Sucursales", path: "/", access: true, icon: null },
    { header: "Indicadores de Riesgo", path: "/RiskIndicators", access: hasMinManagerAccess, icon: null },
    { header: "Gastos", path: "/ExpensesMultiFilial", access: hasMinManagerAccess, icon: null },
    { header: "Nominas", path: "/PayRollMultiFilial", access: hasMinRegionalManagerAccess || hasMinAuditorAccess, icon: null },
    { header: "Configuración", path: "/Configuration", access: hasMinAdminAccess, icon: <Settings fontSize="large" /> },
    { header: "Company", path: "/Company", access: hasMinRootAccess, icon: null },
  ];

  return (
    <Grid container sx={{ padding: { xs: "10px", md: "25px" } }} spacing={2}>
      <Grid size={12} display="flex" justifyContent="left" alignItems="left">
        <h1 className="text-center">Administración</h1>
      </Grid>
      {cardItems.map((item, index) => (
        item.access && (
          <Grid size={{ xs: 6, sm: 4, md: 3, lg: 2 }} key={index}>
            {/* <AdminCardItem
              header={item.header}
              triggerPage={() => handleView(item.path)}
              icon={item.icon}
            /> */}
                <CardBlockItem item={item} handleView={handleView} />

          </Grid>
        )
      ))}
    </Grid>
  );
};

export default Index;
