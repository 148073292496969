import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import LoanIncrementForm from "./LoanIncrementForm";
import LoanIncrementList from "./LoanIncrementList";
import Controls from "../../components/controls/Controls";
import Popup from "../../components/Popup";
import { checkAccess } from "../../components/Helper/GlobalHelpers";
import FilialForm from "./FilialForm";
import Filial_NewEdit from "../Modal/Filial_NewEdit";
import FilialDocuments from "./FilialDocuments";
import { getMedia } from "../../utils/api/api_document";

// import Box from '@mui/system/Box';
import { Typography, Box, TextField, Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import Grid from "@mui/material/Grid2";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";

const Index = () => {
  const { actions, filial, currentRole } = useContext(NovaContext);
  const [selectedLoanIncrement, setSelectedLoanIncrement] = useState(null);
  const [loanIncrements, setLoanIncrements] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filialInfo, setFilial] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupFilial, setOpenPopupFilial] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const hasDirectorAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAdminAccess = checkAccess(currentRole, "Administrador");
  const [lockStatus, setLockStatus] = useState(false);
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    const initiateConfiguration = async () => {
      actions.getLoanIncrements().then((data) => setLoanIncrements(data));
      actions.getFilial(filial).then((data) => setFilial(data));
      await getMedia(`filialId=${filial}`).then((data) => setDocumentList(data));
    }

    // get media is causing an infinite loop. why?
    // ToDo: get document list from filial
    // actions.getFilials().then((data) => setFilials(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedLoanIncrement, filialInfo]);
    initiateConfiguration();
  }, []);

  const handleEditClick = () => {
    // console.log("clicked", selectedLoanIncrement);
    setIsDialogOpen(true);
  };

  const handleNewClick = () => {
    setSelectedLoanIncrement(null);
    setIsDialogOpen(true);
  };

  const addOrEditLoanIncrement = async (selectedLoanIncrement, resetForm) => {
    if (selectedLoanIncrement.id === 0) {
      await actions.addLoanIncrement(itemForEdit);
      console.log("new");
    } else {
      console.log("cambio");
      await actions.updateLoanIncrement(selectedLoanIncrement);
    }
    resetForm();
    setItemForEdit(null);
    setOpenPopup(false);
  };

  const loanIncrementDefaults = [
    { filialid: filial, value: 100, renewals: 1 },
    { filialid: filial, value: 200, renewals: 1 },
    { filialid: filial, value: 300, renewals: 2 },
    { filialid: filial, value: 400, renewals: 2 },
    { filialid: filial, value: 500, renewals: 3 },
    { filialid: filial, value: 600, renewals: 3 },
    { filialid: filial, value: 700, renewals: 3 },
    { filialid: filial, value: 800, renewals: 3 },
    { filialid: filial, value: 900, renewals: 3 },
    { filialid: filial, value: 1000, renewals: 3 },
    { filialid: filial, value: 1100, renewals: 3 },
    { filialid: filial, value: 1200, renewals: 3 },
    { filialid: filial, value: 1300, renewals: 3 },
    { filialid: filial, value: 1400, renewals: 3 },
    { filialid: filial, value: 1500, renewals: 3 },
    { filialid: filial, value: 1600, renewals: 3 },
    { filialid: filial, value: 1700, renewals: 3 },
    { filialid: filial, value: 1800, renewals: 3 },
    { filialid: filial, value: 1900, renewals: 3 },
    { filialid: filial, value: 2000, renewals: 5 },
    { filialid: filial, value: 2500, renewals: 5 },
    { filialid: filial, value: 3000, renewals: 5 },
    { filialid: filial, value: 3500, renewals: 5 },
  ];

  const handleDefaultValues = async () => {
    for (var i = 0; i < loanIncrementDefaults.length; i++) {
      await actions.addLoanIncrement(
        loanIncrementDefaults[i],
        filial.filial_id
      );
    }
    // }
    setSelectedLoanIncrement(loanIncrementDefaults[i]);
  };

  const updateDocumentList = (media, action) => {
    if (action === "ADD") {
      setDocumentList((prevState) => {
        return [media, ...prevState];
      });
    } else {
      setDocumentList((prevState) => {
        return prevState.filter((existingMedia) => existingMedia.media_id !== media.media_id);
      });
    }
  }

  return (
    <Box sx={{ padding: { xs: "10px", md: " 25px" } }}>
      <Typography
        variant="h3"
        component="h3"
        display="flex"
        justifyContent="center"
        sx={{ textAlign: "center", mt: 3, mb: 3 }}
      >
        Configuración
      </Typography>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ textAlign: "center", p: 0, mb: 0 }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 20, pl: 2 }}>
            Datos de generales de la sucursal {filialInfo.filial_name}
          </Typography>
        </AccordionSummary>
        <Divider />

        <AccordionDetails>
          <Grid container padding={1} spacing={{ xs: 1.55, md: 2 }}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box
                sx={{ gap: { xs: 1.55, md: 2 } }}
                display="flex"
                flexDirection="column"
              >
                <TextField
                  label="Dirección"
                  name="address"
                  value={filialInfo.filial_address}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
                <TextField
                  label="Teléfono"
                  name="phone"
                  value={filialInfo.telephone}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box
                sx={{ gap: { xs: 1.55, md: 2 } }}
                display="flex"
                flexDirection="column"
              >
                <TextField
                  label="Máximo valor inicial"
                  name="maxinitialloanvalue"
                  value={filialInfo.initialloanvalue}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
                <TextField
                  label="Máximo valor de prestamo"
                  name="maxloanvalue"
                  value={filialInfo.maxloanvalue}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {hasAdminAccess ? (
            <Filial_NewEdit
              filial={filialInfo !== null ? filialInfo : null}
              setLockStatus={setLockStatus}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ textAlign: "center", p: 0, mb: 0 }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 20, pl: 2 }}>
            Reglas de incremento de préstamo
          </Typography>
        </AccordionSummary>
        <Divider />

        <AccordionDetails>
          {hasDirectorAccess ? (
            <Controls.Button
              text="Crear nuevo"
              variant="outlined"
              startIcon={<AddIcon />}
              // className={""}
              onClick={() => {
                setItemForEdit(null);
                setOpenPopup(true);
              }}
            />
          ) : null}

          {currentRole === "Root" ? (
            <Controls.Button
              text="Generar valores default"
              variant="outlined"
              startIcon={<AddIcon />}
              // className={""}
              onClick={() => {
                handleDefaultValues();
              }}
            />
          ) : null}

          <LoanIncrementList
            loanIncrements={loanIncrements}
            handleEditClick={handleEditClick}
            // handleNewClick={handleNewClick}
            // rowId={rowId}
            // setRowId={setRowId}
            setItemForEdit={setItemForEdit}
            setOpenForm={setOpenPopup}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ textAlign: "center", p: 0, mb: 0 }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 20, pl: 2 }}>
            Documentos
          </Typography>
        </AccordionSummary>
        <Divider />

        <AccordionDetails>
          <FilialDocuments
            documentList={documentList}
            updateDocumentList={updateDocumentList}
          />
        </AccordionDetails>
      </Accordion>

      <Popup
        title="Formulario de Incremento de préstamo"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <LoanIncrementForm
          itemForEdit={itemForEdit}
          addOrEdit={addOrEditLoanIncrement}
          setIsOpen={setOpenPopup}
        />
      </Popup>
    </Box>
  );
};

export default Index;
