import { EXPENSES as expenses } from "../config/routes";
import SendRequest from "../../lib/api_request/api_request";
import { GET, POST, DELETE } from "./enum_apitypes";
import { ServerURI, ServerPORT } from "../config/uri";
import { NOVACOOKIE } from "../config/novacookies";
import { getCookie } from "../../lib/cookiesjs/cookiesjs";
import { parseApiError } from "./api_errorhandler";

const sendRequest = new SendRequest();
const endpoint = `${ServerURI}:${ServerPORT}/${expenses}`;
const accessToken = getCookie(NOVACOOKIE);

export const getExpenses = async (params) => {
    return await
        sendRequest.request(
            `${endpoint}?${params}`,
            GET,
            null,
            accessToken,
            null
        )
            .then(resp => resp.data)
            .catch(err => ({ "code": 401, "text": err }))
    // ideal solution - ToDo - look at structure in parseApiError
    // .catch(err => parseApiError(err))
}

export const addExpense = async (expense) => {
    return await
        sendRequest.request(
            endpoint,
            POST,
            expense,
            accessToken,
            null
        )
            .then(resp => resp.data)
            .catch(err => console.log(`An error occured: ${err}`))
}

export const deleteExpense = async (id) => {
    return await
        sendRequest.request(
            `${endpoint}/${id}`,
            DELETE,
            null,
            accessToken,
            null
        )
            .then(resp => resp)
            .catch(err => console.log(`An error occured: ${err}`))
}