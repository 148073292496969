export const LoadingActions = {
    SET_LOADING: 'SET_LOADING'
}

export const LoadingReducer = (state, action) => {
    switch (action.type) {
        case LoadingActions.SET_LOADING:
            return action.payload;
        default:
            return state;
    }
}