import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Material UI import
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Internal import
import { NovaContext } from './context';

// Date-fns
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { TableBody, Typography } from '@mui/material';


const MediaGallery = ( props ) => {

    const {
        mediaItems,
        width,
        height,
        columnNumber,
        rowHeight,
        loadingType,
        updateMedia
    } = props;

    const { actions, authenticatedUser } = useContext(NovaContext);
    const location = useLocation();

    // state/methods concerning content of dialog
    const [selectedItem, setSelectedItem] = useState(null);
    const [employeeName, setEmployeeName] = useState(null);

    // state/methods concerning dialog
    const [open, setOpen] = useState(false);

    const handleClickOpen = ( item ) => {
        setSelectedItem(item);

        // set employee name for this component (if it's not the same as the auth user)
        if ( item.media_employeeid === authenticatedUser.employee_id) {
            setEmployeeName(authenticatedUser.employee_name + ' ' + authenticatedUser.employee_lastname);
        } else {
            actions.getEmployee(item.media_employeeid)
                .then(resp => {
                    setEmployeeName(resp.employee_name + ' ' + resp.employee_lastname);
                })
                .catch(err => {
                    console.log("Error occured: ", err);
                    setEmployeeName("No disponible");
                })
        }
        setOpen(true);
      };
    
    const handleClose = () => {
        setSelectedItem(null);
        setOpen(false);
    };

    const deleteItem = ( ) => {
        const customerId = location.pathname.split("/")[2];
        actions.deleteMedia(selectedItem.key, customerId)
            .then(resp => {
                updateMedia(selectedItem, "DELETE");
                setSelectedItem(null);
                setOpen(false);
            })
            .catch(resp => console.log("An error occurred"));
    }


  return (
    <Grid container>
        <ImageList 
          sx={{ width: width, height: height }} 
          cols={columnNumber} 
          rowHeight={rowHeight}
        >
            {mediaItems && mediaItems.map(( item, index ) => {
                return (
                    <ImageListItem key={index}>
                        <img 
                          src={item.location}
                          alt={item.media_name}
                          loading={loadingType}
                          onClick={() => handleClickOpen(item)}
                        />
                    </ImageListItem>
                )
            })}
        </ImageList>

        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={open}
          onClose={handleClose}
        >
        <DialogTitle>
            Detalles de los medios
        </DialogTitle>
        <DialogContent dividers>
            { selectedItem ?
                <Grid container spacing={2}>
                    <Grid xs={12} md={8} lg={8}>
                        <Box 
                            component="img"
                            src={selectedItem.location}
                            alt={selectedItem.media_name}
                            width="100%"
                        />
                    </Grid>
                    <Grid xs={12} md={4} lg={4}>
                        <Paper>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Nombre:</TableCell>
                                        <TableCell>{selectedItem.media_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Cargado por:</TableCell>
                                        {/* <TableCell>{getEmployee(selectedItem.media_employeeid)}</TableCell> */}
                                        <TableCell>{employeeName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Cargado el:</TableCell>
                                        <TableCell>{format(parseISO(selectedItem.media_datecreated), "dd-MM-yyyy HH:mm")}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            :
            <Typography variant='h4'>Ningún medio seleccionado</Typography>
            }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={deleteItem}>
            Borrar
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default MediaGallery