import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { menuData, menuSettings } from "./MenuData";
// import { Button } from '../Button';

import * as IconsGi from "react-icons/gi";

// import { MenuBars, NavMenu, NavBtn } from "./NavBarMuiElements";
import { NovaContext } from "../context/index";
// import Dropdown from "../Dropdown";
// import LogoLanita from "../../images/LOGO LANITA SOLIDARIA BLANCO.png";
// import LogoLanita from "../../images/LANITA SOLIDARIA COLORES.png"; //logo Normal
// import LogoLanita from "../../images/lanita_1200_Muertos.png";
import "../../styles/logo.css";

import { AppBar, Container, IconButton, TextField } from "@mui/material";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavBarMenuList from "./NavBarMenuList";
import NavBarMenuProfile from "./NavBarMenuProfile";
import Clock from "../Clock";

const NavBarMui = () => {
  const {
    actions,
    authenticatedUser,
    filials,
    filial,
    currentRole,
  } = useContext(NovaContext);

  let navigate = useNavigate();

  var IconComponent = IconsGi["GiPerson"];

  const [mappedFilial, setFilials] = useState([]);
  const [selectedFilial, setSelectedFilial] = useState("0");

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    // console.log("user:", authenticatedUser);
    const filialId = actions.getActiveFilial()
      ? actions.getActiveFilial()
      : "0";
    // mapFilials(filials);
    setFilials(filials);
    setSelectedFilial(filialId);
  }, [filials, filial]);

  // const [uiLocation, setUiLocation] = useState(history.location.pathname);

  const handleOpenNavigationItem = (path) => {
    // setUiLocation(path);
    navigate(path);
  };

  const currentPath = useLocation();

  const handleChangeFilial = async (valueSelected) => {
    await actions.setActiveFilial(valueSelected);
    setSelectedFilial(valueSelected);

    if (!valueSelected || valueSelected === "0") {
      const path = `/`;
      navigate(path);
    } else {
      const fullPath = currentPath.pathname;
      const firstSegment = currentPath.pathname
        .split("/")
        .slice(0, 2)
        .join("/");

      if (fullPath.includes("Customers") || fullPath.includes("Employees")) {
        console.log(firstSegment);

        navigate(firstSegment, { replace: true });
      } else if (fullPath.includes("Filial") || fullPath.includes("filial")) {
        const filialSegment = firstSegment + "/" + valueSelected;
        navigate(filialSegment, { replace: true });
      } else {
        navigate(fullPath, { replace: true });
      }
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const initials = authenticatedUser
    ? authenticatedUser.employee_name.substring(0, 1) +
      authenticatedUser.employee_lastname.substring(0, 1)
    : "XX";

  return authenticatedUser ? (
    // <Container maxWidth="xl">
      <AppBar position="static" sx={{ displayPrint: "none",  px: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
        <Toolbar disableGutters>
          <Box
            onClick={() =>
              handleOpenNavigationItem(
                filial && filial !== "0" ? `/Filial/${filial}` : "/"
              )
            }
            component="div"
            className="custom-background-svg-color"
            // className="custom-background-svg-color-christmas"
            // className="custom-background-svg-color-valentin"
            sx={{
              height: 40,
              mr: { xs: "none", sm: 1, md: 3 },
              cursor: "pointer",
            }}
            alt="Lanita Solidaria"
          />

          {/* Mobile - Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <NavBarMenuList />
            </Menu>
          </Box>

          {/* Desktop - Menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <NavBarMenuList />
          </Box>
          <Box sx={{ p: 0.1, mr: 2, flexGrow: 0 }}>
            <Clock></Clock>
          </Box>
          {/* Desktop/Mobile - Filial Selector **CHANGE COLOR TO WHITE*/}
          <Box sx={{ p: 0.1, mr: 2, flexGrow: 0 }}>
            <TextField
              id="filialDropdown"
              label="Sucursal"
              sx={{
                textAlign: "left",
                padding: 0.1,
                "& .MuiInputBase-root": {
                  color: "white",
                  fontWeight: "bold",
                  fontSize: { xs: 10, md: 14 },
                  borderRadius: 2,
                },
                "& .MuiInputBase-input": {
                  padding: 1,
                },
                "& .MuiFormLabel-root": {
                  color: "white",
                },
                "& .MuiButtonBase-root-MuiButton-root:hover": {
                  background: "secondary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "secondary.main",
                },
              }}
              value={selectedFilial}
              onChange={(e) => handleChangeFilial(e.target.value)}
              slotProps={{ shrink: true }}
              size="small"
              select
            >
              <MenuItem name="filial_Default" value="0">
                -- Seleccionar --
              </MenuItem>
              {/* {console.log(mappedFilial)} */}
              {mappedFilial &&
                mappedFilial.map((item) => {
                  return (
                    <MenuItem
                      key={item.filial_id}
                      name="filial_name"
                      value={item.filial_id}
                    >
                      {item.filial_name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Box>
          {/* Desktop/Mobile - Profile Menu  */}
          <Box sx={{ flexGrow: 0 }}>
            <NavBarMenuProfile />
          </Box>
        </Toolbar>
    </Box>
      </AppBar>
      // {/* </Container> */}
  ) : null;
};
export default NavBarMui;
