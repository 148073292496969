import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles"; // deprecated warning
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

// import Box from '@mui/system/Box';

// Material UI
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "../../components/controls/Select";
import Person from "@mui/icons-material/Person";
import { NovaContext } from "../../components/context";
import { checkAccess } from "../../components/Helper/GlobalHelpers";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// export default function CustomizedDialogs() {
export const Filial_New = (props) => {
  // initialise props
  const { filial, setLockStatus, cluster } = props;

  // Misc variables
  let navigate = useNavigate();

  // context data
  const { actions, currentRole } = React.useContext(NovaContext);
  const isNewMode = !filial;

  const [open, setOpen] = React.useState(false);

  const filialInfo = "filialInfo";

  const hasAdminAccess = checkAccess(currentRole, "Administrador");

  const [errors, setErrors] = useState([]);

  // Set up refs for the react form -> refs are automatically updated when bound
  const Name = useRef("");
  const Address = useRef("");
  const Telephone = useRef("");

  // triggers the form's object initialisation as a result of React's multi load rendering
  const initializeForm = (Object) => {
    console.log(Object);
    Name.current.value = Object ? (Object.name ? Object.name : "") : "";
    Address.current.value = Object
      ? Object.address
        ? Object.address
        : ""
      : "";
    Telephone.current.value = Object
      ? Object.telephone
        ? Object.telephone
        : ""
      : "";
  };

  const handleClick = async () => {
    if (!isNewMode) {
      await actions.getFilial(filial.filial_id).then((data) => {
        initializeForm(data);
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleClick();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearFields = () => {
    setErrors([]);
    initializeForm();
  };

  // reverts form data to initial state. Necessary for the shrink attribute of TextField
  const revertChange = () => {
    // setSelectedArea("");
    clearFields();
    handleClose();
  };

  const validateData = () => {
    setErrors([]);
    let isValid = true;
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    // setLockStatus(true);
    // const isEntryValid = validateData();
    const isEntryValid = true;

    if (isEntryValid) {
      const tempFilial = {
        name: Name.current.value,
        address: Address.current.value,
        telephone: Telephone.current.value,
        cluster: cluster,
      };
      // console.log("TF:", tempFilial);
      const filial = isNewMode
        ? await await actions.addFilial(tempFilial)
        : await actions.updateFilial(tempFilial, filial.filial_id);

      if (filial) {
        revertChange();
        const path = `/filial/${filial.filial_id}`;
        navigate(path);
      } else {
        console.log("Filial create/edit failed");
      }
    }

    handleClose();
  };

  return isNewMode || hasAdminAccess ? (
    <Box paddingLeft={1}>
      <Button
        // variant="contained"
        size="small"
        // color='primary'
        onClick={handleClickOpen}
      >
        <AddIcon />
        CREAR SUCURSAL
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Crear Sucursal
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Grid container padding="10px">
            <Box
              component={"span"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={1}
            >
              <TextField
                variant="outlined"
                label="Nombre"
                id="name"
                fullWidth
                inputRef={Name}

                // placeholder='Nombre'
              />
            </Box>
            <Box
              component={"span"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={1}
            >
              <TextField
                variant="outlined"
                label="Dirección"
                id="address"
                fullWidth
                inputRef={Address}
                // placeholder='Apellido'
              />
            </Box>
            <Box
              component={"span"}
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              marginBottom={1}
            >
              <TextField
                variant="outlined"
                label="Teléfono"
                id="telephone"
                fullWidth
                inputRef={Telephone}
                placeholder="xxx xxx xxxx"
              />
            </Box>
            {/* </Box> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="success"
            variant="contained"
            // onClick={handleClose}
            onClick={handleCreate}
          >
            Continuar
          </Button>
          <Button
            autoFocus
            color="secondary"
            variant="contained"
            onClick={() => revertChange()}
          >
            Cancelar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  ) : null;
};

export default Filial_New;

// Form components
