import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../../components/context";
import {
  recalculateDueDate,
  calculateOpenAmount,
  calculateOpenAmountTotal,
  calculateOpenArrear,
  calculateOpenInterest,
  hasFreshPayment,
  calculateRisk,
  loanPercentagePaid,
  RiskIcon,
  calculateInterest,
} from "../../components/Helper/LoanHelpers";
import {
  addHours,
  format,
  formatISO,
  isPast,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import {
  checkAccess,
  getTimeDifferenceInHours,
} from "../../components/Helper/GlobalHelpers";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import {
  CustomDialog,
  ItemPaper,
} from "../../components/Helper/MUIStyledComponents";
import ProgressBar from "../../components/ProgressBar";
import { alerts, displayAlert } from "../../components/Helper/AlertToastify";

const Payment_New = (props) => {
  const { loan, open, toggleDialog } = props;

  const { actions, filial, authenticatedUser, currentRole } = useContext(
    NovaContext
  );
  const navigate = useNavigate();
  const isNewMode = true;

  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const [lockStatus, setLockStatus] = useState(false);
  const [errors, setErrors] = useState([]);
  const checkForErrors = (fieldId) => {
    const nameError = errors.find((error) => error.label === fieldId);
    return nameError !== undefined ? nameError.message : "";
  };

  const [openTotalToBeQuit, setOpenTotalToBeQuit] = useState(0);
  const [openTotalToPayAll, setOpenTotalToPayAll] = useState(0);
  const [openTotalDebit, setOpenTotalDebit] = useState(0);
  const [openArrear, setOpenArrear] = useState(0);
  const [openInterest, setOpenInterest] = useState(0);
  const [interest, setInterest] = useState(0);
  const [arrear, setArrear] = useState(0);
  const [capital, setCapital] = useState(0);

  const [validationStatus, setValidationStatus] = useState(true);

  // Values calculated based on the Loan info and the customer input.
  const total = interest + arrear + capital;
  const remainingInterest = openInterest - interest;
  const remainingArrear = openArrear - arrear;
  const remainingCapital = loan ? loan.loan_remaining - capital : capital;

  const oldDueDate = loan ? parseISO(loan.loan_datedue) : 0;
  const minInterestValue = loan ? Math.round(loan.loan_remaining * 0.15) : 0;

  var tempWeeksChange, tempNewDueDate, tempOpenArrear;

  if (remainingCapital === 0) {
    tempWeeksChange = 0;
    tempNewDueDate =
      (interest % minInterestValue === 0 ||
        minInterestValue % interest === 0) &&
      interest > 0
        ? recalculateDueDate(oldDueDate, tempWeeksChange)
        : oldDueDate;
    tempOpenArrear = openArrear - arrear;
  } else {
    tempWeeksChange = Math.round(interest / minInterestValue);
    tempNewDueDate =
      (interest % minInterestValue === 0 ||
        minInterestValue % interest === 0) &&
      interest > 0
        ? recalculateDueDate(oldDueDate, tempWeeksChange)
        : oldDueDate;
    tempOpenArrear = isPast(tempNewDueDate)
      ? loan.arrears + tempWeeksChange * 120 - arrear
      : openArrear - arrear;
  }
  //value of the Accumulated Arreas that would be store to the DB (only up to the point of the newDueDate)
  const accumulatedArrears = tempOpenArrear >= 0 ? tempOpenArrear : 0;
  //value of the new "DueDate" once the payment is triggered
  tempNewDueDate = setHours(tempNewDueDate, 13);
  tempNewDueDate = setMinutes(tempNewDueDate, 15);
  const newDueDate = tempNewDueDate;
  const dateIssue = loan ? parseISO(loan.loan_dateissue) : 0;
  const calculatedInterest = loan
    ? calculateInterest(remainingCapital, dateIssue, newDueDate)
    : 0;
  const remainingTotal = calculatedInterest + remainingCapital;
  const refrendoCheck =
    interest >= minInterestValue && interest <= openInterest;
  const capitalCheck = loan
    ? capital >= 0 && capital <= loan.loan_remaining
    : true;

  const resetForm = (value) => {
    if (loan) {
      initializeForm(loan);
    } else {
      setInterest(0);
      setArrear(0);
      setCapital(0);
      setOpenInterest(0);
      setOpenArrear(0);
      setOpenTotalDebit(0);
      setOpenTotalToBeQuit(0);
      setOpenTotalToPayAll(0);
    }
    setErrors([]);
  };

  const initializeForm = (value) => {
    const tempOpenInterest = calculateOpenInterest(value);
    const tempOpenArrear = calculateOpenArrear(value);
    const tempOpenAmount = calculateOpenAmount(value);
    const tempOpenTotal = calculateOpenAmountTotal(value);
    setInterest(tempOpenInterest);
    setArrear(0);
    setCapital(0);
    setOpenInterest(tempOpenInterest);
    setOpenArrear(tempOpenArrear);
    setOpenTotalDebit(tempOpenTotal);
    setOpenTotalToBeQuit(tempOpenAmount);
    setOpenTotalToPayAll(tempOpenTotal + tempOpenArrear);
  };

  //All the validations
  const validate = () => {
    // reset error fields
    setErrors([]);
    // let isValid = true;

    var tempArrearExtra = 0;
    tempArrearExtra = arrear - openArrear;

    //Validation Main.-
    if (
      interest < 0 ||
      arrear < 0 ||
      capital < 0 ||
      calculatedInterest < 0 ||
      remainingCapital < 0
      // remainingInterest < 0 ||
      // remainingArrear < 0 ||
    ) {
      //Validation 1.- check that none of the values is less than 0
      console.log("Validation1");
      const negativeValues = "Todos los valores deben de ser positivos.";
      setErrors((arr) => [
        ...arr,
        { label: "interest", message: negativeValues },
        { label: "arrear", message: negativeValues },
        { label: "capital", message: negativeValues },
        { label: "calculatedInterest", message: negativeValues },
        { label: "remainingCapital", message: negativeValues },
        // { label: "remainingArrear", message: negativeValues },
      ]);
      return false;
      // isValid = false;
    } else if (interest > 0 || arrear > 0 || capital > 0) {
      // if (arrear > openArrear) {
      if (tempArrearExtra > 40) {
        //Validation 2.- the Arrear (Mora) could be max 40 bigger that the openArrear (mora pendiente)
        console.log("Validation2");
        const arrearOvercharge = "La mora no debe pasarse de un máximo de 40.";
        setErrors((arr) => [
          ...arr,
          { label: "arrear", message: arrearOvercharge },
        ]);
        // isValid = false;
        return false;
        // }
      }
      if (total - tempArrearExtra <= openTotalToPayAll) {
        //Validation 3.- check if the total (minus the extra arrears) is less or equal to the total amout to pay
        console.log("Validation3");
        if (hasAuditorAccess) {
          //Validation 4.- up to this point if everything is correct (then if the user is at least Auditor, it goes through) ( NOT the RegionalManager or less)
          console.log("Validation4");
          return true;
        } else {
          //Validation 5.- check if the total (minus the extra arrears) is less or equal to the total amout to pay
          console.log("Validation5");
          if (capital > 0 && interest === 0 && arrear === 0) {
            if (!hasFreshPayment(loan)) {
              // Validation 7.- check if the capital is correct (for a payment of only capital, but the response is only true if there was a normal payment shortly before)
              console.log("Validation8");
              const freshPayment = "Pago a capital  invalido.";
              setErrors((arr) => [
                ...arr,
                { label: "capital", message: freshPayment },
              ]);
              return false;
            } else {
              return true;
            }
          }
          if (
            interest > 0 &&
            interest % minInterestValue === 0 &&
            interest <= openInterest &&
            interest >= minInterestValue &&
            remainingInterest >= 0
          ) {
            //Validation 6.- check if the interest is correct
            console.log("Validation6");
            return true;
          } else {
            const interestIncorrect = "El refrendo es incorrecto.";
            setErrors((arr) => [
              ...arr,
              { label: "interest", message: interestIncorrect },
            ]);
          }
          if (
            arrear > 0 &&
            arrear <= openArrear &&
            interest === 0 &&
            capital === 0
          ) {
            //Validation 7.- check if the arrear is correct (for a payment of only arrears)
            console.log("Validation7");
            return true;
          }
        }
      }
    }
    const invalidValues = "Las cantidades son incorrectas.";
    setErrors((arr) => [...arr, { label: "total", message: invalidValues }]);
    //if No validation was triggered, than any other value goes out ouf the consideration and therefore is incorrect
    return false;
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    if (lockStatus) {
      alert("El pago se está procesando. Por favor, espere");
    } else {
      setLockStatus(true);
      // if (true) {
      if (validate()) {
        setValidationStatus(true);

        const currentDate = addHours(new Date(), +getTimeDifferenceInHours());
        const tempPayment = {
          total: total,
          interest: interest,
          arrear: arrear,
          capital: capital,
          createdate: formatISO(currentDate),
          employeeId: authenticatedUser.employee_id,
          loanId: loan.loan_id,
          filialId: filial,
          openAmount: remainingCapital,
          dateDue: formatISO(newDueDate),
          openArrear: accumulatedArrears,
        };
        console.log("Payment:", tempPayment);
        const newPayment = await actions.addPaymentToLoan(tempPayment);
        if (newPayment) {
          displayAlert(alerts.SUCCESS, "Pago enviado correctamente");
        } else {
          displayAlert(alerts.ERROR, "Algo ha fallado en la solicitud");
        }
        closeDialog();
        handleView(`/TicketPay/${newPayment.payment_id}`);
      } else {
        setValidationStatus(false);
      }

      setLockStatus(false);
    }
  };

  const handleChange = () => {
    console.log("Changed");
    // setValidationStatus(validate());
  };

  const closeDialog = () => {
    toggleDialog(false);
    // setValidationStatus(true);
    resetForm();
  };
  const handleView = (string) => {
    // const navigate = useNavigate();
    const path = `${string}`;
    navigate(path);
  };

  useEffect(() => {
    if (loan) {
      initializeForm(loan);
    } else {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan]);

  return (
    <form onSubmit={handleCreate}>
      {loan ? (
        <CustomDialog
          open={open}
          onClose={closeDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container alignItems={"center"}>
              <Grid size={10} align="left">
                <Typography variant="h5" align="left">
                  <Box sx={{ fontWeight: "bold" }}>Agregar pago</Box>
                </Typography>
              </Grid>
              <Grid size={2} align="right">
                <IconButton onClick={() => closeDialog()}>
                  <CloseIcon fontSize="medium" color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={0.5}>
                {/* grid with the current Custoner/Loan information */}
                <Grid size={12} align="left">
                  <Typography variant="h5" align="left" component="div">
                    <Box sx={{ fontWeight: "bold" }}>
                      Cliente: {loan.fullName} (ID:{loan.customer_idnumber})
                    </Box>
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <ItemPaper>
                    <Grid container>
                      <Grid size={{ xs: 6, md: 4 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          component="div"
                        >
                          <Box sx={{ m: 0.8 }}>Capital Inicial</Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="div"
                        >
                          <Box sx={{ m: 0.8 }}>Capital Pendiente</Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="div"
                        >
                          <Box sx={{ m: 0.8 }}>Refrendo</Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="div"
                        >
                          <Box sx={{ m: 0.8 }}>Total de Adeudo</Box>
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 6, md: 2 }}>
                        <Typography
                          variant="body1"
                          align="right"
                          component="div"
                        >
                          <Box sx={{ m: 0.8 }}>${loan.loan_amount}</Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="right"
                          component="div"
                        >
                          <Box sx={{ m: 0.8 }}>${loan.loan_remaining}</Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="right"
                          component="div"
                        >
                          <Box sx={{ m: 0.8 }}>
                            ${calculateOpenInterest(loan)}
                          </Box>
                        </Typography>
                        <Typography variant="h6" align="right" component="div">
                          <Box sx={{ fontWeight: "bold", m: 0.8 }}>
                            ${calculateOpenAmountTotal(loan)}
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid container size={{ xs: 12, md: 6 }}>
                      {/* <Grid container> */}
                      <Grid size={{ xs: 12 }}>
                        <Typography
                          variant="body1"
                          align="center"
                          component="div"
                        >
                          <ProgressBar percentage={loanPercentagePaid(loan)} />
                          {/* <Box>{loanPercentagePaid(loan)} pagado</Box> */}
                        </Typography>
                      </Grid>

                      <Grid size={{ xs: 6 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          component="div"
                        >
                          <Box sx={{ m: 0.7 }}>Fecha de pago</Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="div"
                        >
                          <Box sx={{ m: 0.5 }}>Mora</Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="div"
                        >
                          <Box sx={{ m: 0.5 }}>Riesgo</Box>
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 6 }}>
                        <Typography
                          variant="body1"
                          align="right"
                          component="div"
                        >
                          <Box sx={{ fontWeight: "bold", m: 0.7 }}>
                            {format(parseISO(loan.loan_datedue), "dd/MM/yyyy ")}{" "}
                          </Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="right"
                          component="div"
                        >
                          <Box sx={{ m: 0.5 }}>
                            ${calculateOpenArrear(loan)}
                          </Box>
                        </Typography>
                        <Typography
                          variant="body1"
                          component="div"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Box sx={{ m: 0.5 }}>{RiskIcon(loan)}</Box>
                        </Typography>
                      </Grid>
                    </Grid>
                    </Grid>
                    {/* </Grid> */}
                  </ItemPaper>
                </Grid>
                {/* <Grid size={12}> */}
                <Grid container spacing={1}>
                  <Grid size={{ xs: 6, md: 7 }}>
                    <Grid size={12} sx={{ m: 2, ml: 2 }}>
                      <Typography variant="h5" align="left" component="div">
                        <Box sx={{ fontWeight: "bold", m: 0.5 }}>
                          {`Cantidades a pagar`}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid size={12} sx={{ ml: 4 }}>
                      <Grid container spacing={2}>
                        <Grid size={12}>
                          <TextField
                            label="Refrendo"
                            name="Refrendo"
                            value={interest}
                            onChange={(e) => {
                              setInterest(parseFloat(e.target.value));
                              handleChange();
                            }}
                            // fullWidth
                            slotProps={{ shrink: true }}
                            size="medium"
                            type="number"
                            slots={{
                              min: 0.0,
                              step: minInterestValue,
                              readOnly: openTotalDebit <= 0,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            helperText={checkForErrors("interest")}
                            error={checkForErrors("interest") ? true : false}
                          />
                        </Grid>
                        <Grid size={12}>
                          <TextField
                            label="Mora"
                            name="Mora"
                            value={arrear}
                            onChange={(e) => {
                              setArrear(parseFloat(e.target.value));
                              handleChange();
                            }}
                            // fullWidth
                            slotProps={{ shrink: true }}
                            size="medium"
                            type="number"
                            slots={{
                              min: 0.0,
                              step: 5,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            helperText={checkForErrors("arrear")}
                            error={checkForErrors("arrear") ? true : false}
                          />
                        </Grid>
                        <Grid size={12}>
                          <TextField
                            label="Capital"
                            name="Capital"
                            value={capital}
                            onChange={(e) => {
                              setCapital(parseFloat(e.target.value));
                              handleChange();
                            }}
                            // fullWidth
                            slotProps={{ shrink: true }}
                            size="medium"
                            type="number"
                            slots={{
                              min: 0.0,
                              step: 5,
                              readOnly: openTotalDebit <= 0,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            helperText={checkForErrors("capital")}
                            error={checkForErrors("capital") ? true : false}
                          />
                        </Grid>
                        <Grid size={12}>
                          <TextField
                            label="Total a pagar"
                            name="Total a pagar"
                            value={total.toFixed(2)}
                            slotProps={{ shrink: true }}
                            size="medium"
                            slots={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            helperText={checkForErrors("total")}
                            error={checkForErrors("total") ? true : false}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: 6, md: 5 }} sx={{ mt: 2 }}>
                    <ItemPaper>
                      <Grid container spacing={2}>
                        <Grid size={12}>
                          <Typography
                            variant="h6"
                            align="left"
                            component="div"
                          >
                            <Box sx={{}}>Próximo pago</Box>
                          </Typography>
                        </Grid>
                        <Grid size={12} sx={{}}>
                          <TextField
                            label="Refrendo"
                            name="Refrendo"
                            value={calculatedInterest}
                            slotProps={{ shrink: true }}
                            size="medium"
                            slots={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            helperText={checkForErrors("calculatedInterest")}
                            error={
                              checkForErrors("calculatedInterest")
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        <Grid size={12} sx={{}}>
                          <TextField
                            label="Capital"
                            name="Capital"
                            value={remainingCapital}
                            slotProps={{ shrink: true }}
                            size="medium"
                            slots={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            helperText={checkForErrors("remainingCapital")}
                            error={
                              checkForErrors("remainingCapital") ? true : false
                            }
                          />
                        </Grid>
                        <Grid size={12} sx={{}}>
                          <TextField
                            label="Total pendiente"
                            name="Total pendiente"
                            value={remainingTotal}
                            slotProps={{ shrink: true }}
                            size="medium"
                            slots={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            helperText={checkForErrors("remainingTotal")}
                            error={
                              checkForErrors("remainingTotal") ? true : false
                            }
                          />
                        </Grid>
                        <Grid size={12} sx={{}}>
                          <Typography
                            variant="body1"
                            align="right"
                            component="div"
                          >
                            <Box
                              sx={{
                                fontSize: { xs: 10 },
                              }}
                            >
                              {`Nueva Fecha de pago:`}
                            </Box>
                          </Typography>
                          <Typography
                            variant="body1"
                            align="right"
                            component="div"
                          >
                            <Box sx={{}}>
                              {newDueDate
                                ? format(newDueDate, "dd/MM/yyyy")
                                : "---"}
                            </Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ItemPaper>
                  </Grid>
                </Grid>
                {/* </Grid> */}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <Divider />

          <DialogActions>
            <Button
              autoFocus
              onClick={handleCreate}
              color="success"
              variant="contained"
              disabled={lockStatus}
            >
              Pagar
            </Button>
            <Button
              autoFocus
              onClick={closeDialog}
              variant="outlined"
              disabled={lockStatus}
            >
              Cancelar
            </Button>
          </DialogActions>
        </CustomDialog>
      ) : null}
    </form>
  );
};
export default Payment_New;
