// Utility import
import React, { useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";

// UI styling
import "./App.css";
import "./styles/main.scss"; // new naming for Dart-Sass
import "react-toastify/dist/ReactToastify.css";

import ThemeWrapper from "./components/Theme/ThemeWrapper";
import PrivateRoute from "./components/PrivateRoute";

import { CssBaseline, Switch as SwitchButton } from "@mui/material";
import { ToastContainer } from "react-toastify";

import withContext, { NovaContext } from "./components/context/index";

import Administration from "./pages/Administration/"; // new Administration page
import Audit from './pages/AuditOverview/index';
import Configuration from "./pages/Configuration"; // new Configuration page
import Company from "./pages/Company"; // new Configuration page
import ControllingPerCluster from "./pages/ControllingPerCluster"; // new Configuration page
import Cashflow from './pages/CashflowOverview'
import CashRegister from "./pages/CashRegister";
import Customers from "./pages/CustomerOverview"; // new Customers page
// import Customer from "./pages/CustomerProfile";
import Customer from "./pages/CustomerProfile/";
import Employees from "./pages/Employees";
import Expenses from "./pages/ExpensesOverview";
// import Employees from "./pages/Employees/";  // new Employees page
import Employee from "./pages/EmployeeProfile/";
import EmployeeOverview from "./pages/EmployeeOverview";
import EmployeeMatrix from "./pages/EmployeeMatrix";
// import EmployeeMatrix from "./pages/EmployeesOverviewMatrix"; // new Employees page
import ExpensesMultiFilial from "./pages/ExpensesMultiFilial";
import PayRollMultiFilial from "./pages/PayRollMultiFilial";
import Filials from "./pages/Filials";
// import Filial from "./pages/Filial";
import Filial from "./pages/Filial/Filial_Details";
import FilialOverview from "./pages/FilialOverview";
import LoansOld from "./pages/Loans";
import Loans from "./pages/LoanOverview"; // new Loans page
import LoansHistory from "./pages/LoansHistory";
import Loan from "./pages/Loan";
import Login from "./pages/Login";
import Logout from "./components/Actions/Logout";
// import NavBar from "./components/NavigationBar/NavBar";
import NavBar from "./components/NavigationBar/NavBarMui";
// import NavBarMobile from "./components/NavigationBar/NavBarMobile";
import IncomingPayments from './pages/PaymentsOverview';
import Payments from "./pages/Payments";
import Paychecks from "./pages/PaychecksOverview";
import RiskIndicators from "./pages/RiskIndicators";
import Reports from "./pages/Reports/Reports";
import ReportsNavigation from "./pages/Reports";
import RegisterCut from "./pages/RegisterCut";
import RegisterCutMultiFilial from "./pages/RegisterCutMultiFilial";
import Ticket from "./pages/Ticket";
import TicketPay from "./pages/TicketPay";

// Private route for automatic redirects
const AdministrationWithContext = withContext(Administration);
const AuditWithContext = withContext(Audit);
const ConfigurationWithContext = withContext(Configuration);
const CompanyWithContext = withContext(Company);
const ControllingPerClusterWithContext = withContext(ControllingPerCluster);
const CashflowWithContext = withContext(Cashflow);
const CashRegisterWithContext = withContext(CashRegister);
const CustomersWithContext = withContext(Customers);
const CustomerWithContext = withContext(Customer);
const EmployeesWithContext = withContext(Employees);
const EmployeeMatrixWithContext = withContext(EmployeeMatrix);
const EmployeeOverviewWithContext = withContext(EmployeeOverview);
const ExpensesMultiFilialWithContext = withContext(ExpensesMultiFilial);
const PayRollMultiFilialWithContext = withContext(PayRollMultiFilial);
const EmployeeWithContext = withContext(Employee);
const ExpensesWithContext = withContext(Expenses);
const FilialOverviewWithContext = withContext(FilialOverview);
const FilialsWithContext = withContext(Filials);
const FilialWithContext = withContext(Filial);
const LoansWithContext = withContext(Loans);
const LoansWithContextOld = withContext(LoansOld);
const LoanWithContext = withContext(Loan);
const LoansHistoryWithContext = withContext(LoansHistory);
const LogoutWithContext = withContext(Logout);
const NavbarWithContext = withContext(NavBar);
const IncomingPaymentsWithContext = withContext(IncomingPayments)
const PaymentsWithContext = withContext(Payments);
const PaychecksWithContext = withContext(Paychecks);
const RegisterCutWithContext = withContext(RegisterCut);
const RegisterCutMultiWithContext = withContext(RegisterCutMultiFilial);
const ReportsWithContext = withContext(Reports);
const ReportsNavigationWithContext = withContext(ReportsNavigation);
const RiskIndicatorsWithContext = withContext(RiskIndicators);
const TicketWithContext = withContext(Ticket);
const TicketPayWithContext = withContext(TicketPay);

function Health() {
  return <h3>Hey There!!! The App is Healthy</h3>;
}

function App() {
  return (
    <ThemeWrapper>
      <CssBaseline />
      {/* <Router> */}
        <ToastContainer />
        <NavbarWithContext />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signout" element={<LogoutWithContext />} />
          <Route path="/health" element={<Health />} />
          <Route path="/" element={<PrivateRoute component={FilialOverviewWithContext} />} />
          <Route path="/AuditTrail" element={<PrivateRoute component={AuditWithContext} />} />
          <Route path="/FilialsOld" element={<PrivateRoute component={FilialsWithContext} />} />
          <Route path="/Filial/:id" element={<PrivateRoute component={FilialWithContext} />} />
          <Route path="/CashRegister" element={<PrivateRoute component={CashRegisterWithContext} />} />
          <Route path="/Cashflow" element={<PrivateRoute component={CashflowWithContext} />} />
          <Route path="/ControllingPerCluster" element={<PrivateRoute component={ControllingPerClusterWithContext} />} />
          <Route path="/Customers" element={<PrivateRoute component={CustomersWithContext} />} />
          <Route path="/Customers/:id" element={<PrivateRoute component={CustomerWithContext} />} />
          <Route path="/Employees" element={<PrivateRoute component={EmployeesWithContext} />} />
          <Route path="/EmployeeMatrix" element={<PrivateRoute component={EmployeeMatrixWithContext} />} />
          <Route path="/EmployeeOverview" element={<PrivateRoute component={EmployeeOverviewWithContext} />} />
          <Route path="/Employees/:id" element={<PrivateRoute component={EmployeeWithContext} />} />
          <Route path="/Expenses" element={<PrivateRoute component={ExpensesWithContext} />} />
          <Route path="/ExpensesMultiFilial" element={<PrivateRoute component={ExpensesMultiFilialWithContext} />} />
          <Route path="/Loans" element={<PrivateRoute component={LoansWithContext} />} />
          <Route path="/LoansOld" element={<PrivateRoute component={LoansWithContextOld} />} />
          <Route path="/Loans/:id" element={<PrivateRoute component={LoanWithContext} />} />
          <Route path="/LoansHistory" element={<PrivateRoute component={LoansHistoryWithContext} />} />
          <Route path="/Administration" element={<PrivateRoute component={AdministrationWithContext} />} />
          <Route path="/Configuration" element={<PrivateRoute component={ConfigurationWithContext} />} />
          <Route path="/Company" element={<PrivateRoute component={CompanyWithContext} />} />
          <Route path="/RiskIndicators" element={<PrivateRoute component={RiskIndicatorsWithContext} />} />
          <Route path="/RegisterCut" element={<PrivateRoute component={RegisterCutWithContext} />} />
          <Route path="/RegisterCutMultiFilial" element={<PrivateRoute component={RegisterCutMultiWithContext} />} />
          <Route path="/Reports" element={<PrivateRoute component={ReportsWithContext} />} />
          <Route path="/ReportsNavigation" element={<PrivateRoute component={ReportsNavigationWithContext} />} />
          <Route path="/PayRollMultiFilial" element={<PrivateRoute component={PayRollMultiFilialWithContext} />} />
          <Route path="/IncomingPayments" element={<PrivateRoute component={IncomingPaymentsWithContext} />} />
          <Route path="/Paychecks" element={<PrivateRoute component={PaychecksWithContext} />} />
          <Route path="/Payments" element={<PrivateRoute component={PaymentsWithContext} />} />
          <Route path="/Ticket/:id" element={<PrivateRoute component={TicketWithContext} />} />
          <Route path="/TicketPay/:id" element={<PrivateRoute component={TicketPayWithContext} />} />
          <Route path="*" element={<PrivateRoute component={FilialOverviewWithContext} />} />
        </Routes>
      {/* </Router> */}
    </ThemeWrapper>
  );
}

export default App;
