// import { getOverlappingDaysInIntervals } from "date-fns";
import React, { useState, useContext, createRef, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from '../context/index';
import { GenderOptions } from "../Helper/Enums";
import { GrEdit, GrUserAdd } from "react-icons/gr";
import Dropdown from "../Dropdown";
import { checkAccess, getUserRolesOptions } from "../Helper/GlobalHelpers";
import Modal from "./Modal";

const NewEditEmployee = (props) => {
    const {
        selectedEmployee,
        modalClass,
        buttonClass,
        setLockStatus
    } = props;

    const { actions, filial, currentRole } = useContext(NovaContext);
    const [display, toggleDisplay] = useState(false);
    const [userRoles, setUserRoles] = useState([]);
    const [userRole, setUserRole] = useState('0');
    const [gender, setGender] = useState('0');
    let navigate = useNavigate();

    const firstName = useRef();
    const lastName = useRef();
    const email = useRef();
    const mobile = useRef();
    const dateOfBirth = useRef();
    const password1 = useRef();
    const password2 = useRef();
    const isNewMode = !selectedEmployee;
    const hasAuditorAccess = checkAccess(currentRole, 'Auditor');
    const hasRegionalManagerAccess = checkAccess(currentRole, 'Gerente Regional');

    const initializeFormEmployee = (Employee) => {
        console.log(Employee)
        firstName.current.value = Employee ? (Employee.employee_name ? Employee.employee_name : '') : '';
        lastName.current.value = Employee ? (Employee.employee_lastname ? Employee.employee_lastname : '') : '';
        email.current.value = Employee ? (Employee.employee_email ? Employee.employee_email : '') : '';
        mobile.current.value = Employee ? (Employee.employee_telephone ? Employee.employee_telephone : '') : '';
        dateOfBirth.current.value = Employee ? (Employee.employee_dateofbirth ? Employee.employee_dateofbirth : '') : '';
        setUserRole(Employee ? (Employee.worksin_roleid ? Employee.worksin_roleid : '') : '');
        setGender(Employee ? (Employee.employee_sex ? Employee.employee_sex : '') : '');
    };

    const mapUserRoles = (data) => {
        const userRoleOptions = getUserRolesOptions(data,currentRole);
        setUserRoles(userRoleOptions);
    }

    const handleChange = (event, section) => {
        const value = event.target.value;
        // console.log(section)
        switch (section) {
            case 'gender': setGender(value); break;
            case 'userRole': setUserRole(value); break;
            default: break;
        }
    };

    const handleClick = () => {
        // console.log(isNewMode, selectedEmployee)
        if (!isNewMode) {
            actions.getEmployee(selectedEmployee.employee_id).then((data) => {
                initializeFormEmployee(data)
            })
        }
        //TBD - change it to have the UserRoles as Constants or Env-variables
        actions.getUserRoles().then((data) => mapUserRoles(data));
        toggleDisplay(!display);
    }

    const closeModalView = () => {
        clearFields();
        toggleDisplay();
    }

    const clearFields = () => {
        // console.log('reseting form')
        initializeFormEmployee();
    }

    const handleCreate = async (e) => {
        e.preventDefault();
        setLockStatus(true);
        // console.log('pws: ', isNewMode, password1.current.value, password2.current.value)
        if ((isNewMode && password1.current.value === password2.current.value) || !isNewMode) {
            const tempEmployee =
            {
                "name": firstName.current.value,
                "lastname": lastName.current.value,
                "email": email.current.value,
                "telephone": mobile.current.value,
                "dateofbirth": dateOfBirth.current.value,
                "password": isNewMode ? password1.current.value : '',
                "sex": gender,
                "roleId": userRole,
                "filialId": filial,
                "worksin_id": !isNewMode ? selectedEmployee.worksin_id : ''
            }
            // console.log(tempEmployee)
            const validation = actions.validateEmployee(tempEmployee);
            // console.log(validation)
            if (validation === true) {
                const employeeId = isNewMode ? 
                    await actions.addEmployee(tempEmployee)
                    : 
                    await actions.updateEmployee(tempEmployee, selectedEmployee.employee_id);
                // console.log('updating employee:',employeeId)
                //probably need a Promise
                if (employeeId) {
                    closeModalView();
                    const path = `/Employees/${employeeId}`;
                    navigate(path);
                } else {
                    console.log('Add Employee Failed');
                }
            }
        } else {
            console.log('passwords do not match');
        }
        setLockStatus(false);
    }

    return (
        (hasRegionalManagerAccess || hasAuditorAccess) ?
            <form onSubmit={handleCreate}>
                <Modal
                    classNames={`${modalClass} ${display ? 'show' : ''}`}
                    toggleModal={handleClick}
                    buttonName={isNewMode ? <GrUserAdd className="icon-small good" /> : <GrEdit className="icon-small good" />}
                    // { isNewMode ? 'Empleado' : null}
                    buttonClass={`${buttonClass ? buttonClass : 'btn btn-primary btn-icon w-auto btn-clean'}`}
                    header={`${isNewMode ? 'Añadir' : 'Editar'} Empleado`}
                    body={() => (
                        <Fragment>
                            <div className="grid-container-form">
                                <label className="ItemFirstName">   Nombre:                 <input type="text" className="form-control" ref={firstName} required /> </label>
                                <label className="ItemLastName">    Apellido:               <input type="text" className="form-control" ref={lastName} required /> </label>
                                <label className="ItemEmail">       Email:                  <input type="email" className="form-control" ref={email} required /> </label>
                                <label className="ItemMobile">      Telefono:               <input type="text" className="form-control" ref={mobile} required /> </label>
                                <label className="ItemBirth">       Fecha de nacimiento:    <input type="integer" className="form-control" ref={dateOfBirth} required /> </label>
                                {/* <div></div> */}
                                <Dropdown className="btn btn-outline-secondary align-left" label="Genero " options={GenderOptions} value={gender} onChange={(e) => handleChange(e, 'gender')} />
                                {isNewMode ?
                                    <>
                                        <Dropdown className="btn btn-outline-secondary align-left" label="Rol " options={userRoles} value={userRole} onChange={(e) => handleChange(e, 'userRole')} />
                                        <label className="ItemPassword">    Contraseña:             <input type="password" className="form-control" ref={password1} required={isNewMode ? true : false} /> </label>
                                        {/* : null} */}
                                        <label className="ItemPassword">    Confirmar contraseña:   <input type="password" className="form-control" ref={password2} required={isNewMode ? true : false} /> </label>
                                        {/* {isNewMode ? */}
                                    </>
                                    : null}
                            </div>
                        </Fragment>
                    )}
                    footer={() => (
                        <Fragment>
                            <button type="submit" className="btn btn-success" data-dismiss="modal" > Continuar </button>
                            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => closeModalView()} > Cancelar </button>
                        </Fragment>
                    )}
                />
            </form >
            :
            null
    )
};

export default NewEditEmployee;