import React, { createContext, useContext, useState, useReducer } from "react";
import { NovaContext } from ".";

// internal
import PaycheckController from "../controllers/PaycheckController";
import { PaycheckReducer } from "../../reducers/PaycheckReducer";
import { DateRangeReducer } from "../common/DateRange/DateRangeReducer";
import { LoadingReducer } from "../../reducers/LoadingReducer";

// date-fns
import subMonths from "date-fns/subMonths";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

// states
const initialPaycheckState = [];
const initialDateRangeState = {
  start: startOfDay(subMonths(new Date(), 1)),
  end: endOfDay(new Date()),
};
const initialLoadingReducer = true;

const PaycheckContext = createContext();

const PaycheckProvider = ({
  children,
  sortingFilter,
  customerIdFilter,
  employeeIdFilter,
}) => {
  const { filial, currentRole } = useContext(NovaContext);
  const [dateRange, dispatchDateRange] = useReducer(
    DateRangeReducer,
    initialDateRangeState
  );
  const [loading, dispatchLoading] = useReducer(
    LoadingReducer,
    initialLoadingReducer
  );
  const [paycheckList, dispatchPaycheckList] = useReducer(
    PaycheckReducer,
    initialPaycheckState
  );

  return (
    <PaycheckContext.Provider
      value={{
        filial,
        currentRole,
        loading,
        dispatchLoading,
        dateRange,
        dispatchDateRange,
        paycheckList,
        dispatchPaycheckList,
      }}
    >
      {children}
      <PaycheckController
        sortingFilter={sortingFilter}
        customerIdFilter={customerIdFilter}
        employeeIdFilter={employeeIdFilter}
      />
    </PaycheckContext.Provider>
  );
};

export { PaycheckContext, PaycheckProvider };

export const usePaycheckContext = () => {
  return useContext(PaycheckContext);
};
