// Utility import
import React, { Fragment, useState, useContext } from "react";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../context";

// Component import
// import EditEmployee from "./EditEmployee";
import NewEditEmployee from "../popups/NewEditEmployee";
import TableEmployees from "../TableEmployees";
import Loading from "./Loading";
import { Grid } from "@mui/material";
import { ItemPaper } from "../Helper/MUIStyledComponents";

const ListEmployeeMatrix = ({ dataList, headersList }) => {
  const { actions, currentRole } = useContext(NovaContext);
  const [lockStatus, setLockStatus] = useState(false);
  let navigate = useNavigate();

  const handleView = (Id) => {
    const path = `Employees/${Id}`;
    navigate(path);
  };

  return (
    // <Fragment>
    //     <div className="page-container">
    <Grid container>
      <div
        className={`over-page-loading ${
          lockStatus ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${
              lockStatus ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>
      {/* <div className="d-flex flex-column-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-custom  card-stretch">
                                    <div className="card-body-sm-padding">
                                        <div className="border-0 pt-5">
                                            <div className="card-title">
                                                <div className="card-label"> */}
      <Grid container>
        <Grid item xs={12}>
          <ItemPaper>
            <Grid container spacing={2} align="left">
              <Grid item xs={12}>
                <NewEditEmployee
                  setLockStatus={setLockStatus}
                  buttonClass={'btn btn-icon w-auto btn-clean"'}
                />
              </Grid>
              <Grid item xs={12}>
                <TableEmployees
                  dataList={dataList}
                  headersList={headersList}
                  setLockStatus={setLockStatus}
                />
              </Grid>
            </Grid>
          </ItemPaper>
        </Grid>
      </Grid>
    </Grid>
    //   {/* </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div> */}
    //   {/* </div>
    // </Fragment> */}
  );
};

export default ListEmployeeMatrix;
