import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../../components/context";
import { checkAccess } from "../../components/Helper/GlobalHelpers";
import Grid from "@mui/material/Grid2"; // Import Grid2
// import AdminCardItem from "../Administration/AdminCardItem";
import {
  Breadcrumbs,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Tooltip,
} from "@mui/material";
import {
  CreditScoreOutlined,
  ManageSearchOutlined,
  PointOfSaleOutlined,
  PriceChangeOutlined,
  ReceiptLongOutlined,
  ReceiptOutlined,
  RequestQuoteOutlined,
} from "@mui/icons-material";
import CardBlockItem from "../../components/CardBlockItem";
// import { ReceiptLongOutlined, PointOfSaleIcon, Receipt ,ManageSearchOutlinedIcon    } from "@mui/icons-material";
// Expense, Payroll, CashFlow, Audit
const ReportsNavigation = () => {
  let navigate = useNavigate();
  const { currentRole } = useContext(NovaContext);

  const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  const hasAuditorAccess = checkAccess(currentRole, "Auditor");
  const hasManagerAccess = checkAccess(currentRole, "Gerente");

  const handleView = (path) => {
    navigate(path);
  };

  const reportItems = [
    {
      header: "Historial de préstamos",
      icon: <ReceiptLongOutlined fontSize="large" />,
      path: "/LoansHistory",
      access: true,
    },
    {
      header: "Pagos ingresados",
      icon: <PriceChangeOutlined fontSize="large" />,
      path: "/IncomingPayments",
      access: true,
    },
    {
      header: "Gastos",
      icon: <RequestQuoteOutlined fontSize="large" />,
      path: "/Expenses",
      access: true,
    },
    {
      header: "Nóminas",
      icon: <CreditScoreOutlined fontSize="large" />,
      path: "/Paychecks",
      access: hasManagerAccess,
    },
    {
      header: "Movimientos de Caja",
      icon: <PointOfSaleOutlined fontSize="large" />,
      path: "/Cashflow",
      access: hasRegionalManagerAccess,
    },
    {
      header: "Auditoría de movimientos",
      icon: <ManageSearchOutlined fontSize="large" />,
      path: "/AuditTrail",
      access: hasRegionalManagerAccess || hasAuditorAccess,
    },
  ];

  return (
    <Grid container sx={{ padding: { xs: "10px", md: "25px" } }} spacing={2}>
      <Grid size={12} display="flex" justifyContent="left" alignItems="left">
        <Typography variant="h4" color="textPrimary">
          Reportes
        </Typography>
      </Grid>
      <Grid container size={12}>
        {reportItems.map(
          (item, index) =>
            item.access && (
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                <CardBlockItem item={item} handleView={handleView} />
              </Grid>
            )
        )}
      </Grid>
    </Grid>
  );
};

export default ReportsNavigation;
