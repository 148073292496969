import React from "react";
import { Route, Navigate } from "react-router-dom";
import { Consumer } from "./context/index";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Consumer>
      {(context) => (
        context.actions.validate() ? (
          <Component {...rest} />
        ) : (
          <Navigate
            to={{
              pathname: "/login",
              state: { from: rest.location },
            }}
          />
        )
      )}
    </Consumer>
  );
};

export default PrivateRoute;
