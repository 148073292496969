import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from './components/context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider>
      <Router>
        <App />
      </Router>
    </Provider>
  // </React.StrictMode> // activate only if the Developer needs it
);

// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import {BrowserRouter as Router} from 'react-router-dom';
// import { Provider } from './components/context';

// ReactDOM.render(
//     <React.StrictMode>
//         <Provider>
//             <Router>
//                 <App />
//             </Router>
//         </Provider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );