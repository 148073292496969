//routes
export const AUTH = "auth";
export const DASHBOARD = "dashboard";
export const CUSTOMERS = "customers";
export const EMPLOYEES = "employees";
export const FILIALS = "filials";
export const LOANS = "loans";
export const LOANINCREMENTS = "loanIncrements";
export const COMMENTS = "Comments";
export const MEDIA = "media";
export const WORKSIN = "worksIn";
export const REGISTRATIONS = "registrations";
export const USERROLES = "userroles";
export const CASHREGISTER = "cashRegister";
export const CASHREGISTERREPORT = "cashRegisterReport";
export const PAYMENTS = "payments";
export const REPORTS = "reports";
export const EXPENSES = "expenses"
export const PAYCHECKS = "paychecks"
export const CASHFLOWS = "cashflows"
export const AUDITTRAIL = "audit"
export const COMPANY = "company"
export const CLUSTER = "cluster"
export const CLUSTERACESS = "clusteraccess"




