// Utility import
import React, { Fragment, useEffect, useState } from "react";
import CashRegisterReportTableData from "./CashRegisterReportTableData";
// Component import

const CashRegisterReport = ({ reportData, reportMode, referenceDate }) => {

    const [RowHeaders, setRowHeaders] = useState([]);
    const ColumnHeaders = ['Prestados', 'Refrendo', 'Mora', 'Capital', 'Prestamos']
    // const date = referenceDate ? referenceDate : new Date();
    const getRowHeaders = (mode) => {
        const data = [];
        switch (mode) {
            case 'WEEK':
                data.push('Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo');
                break;
            case 'MONTH': const weeksInMonth = reportData.length; 
                if (weeksInMonth === 6) {
                    data.push('Semana1', 'Semana2', 'Semana3', 'Semana4', 'Semana5', 'Semana6');
                } else if (weeksInMonth === 5) {
                    data.push('Semana1', 'Semana2', 'Semana3', 'Semana4', 'Semana5');
                } else {
                    data.push('Semana1', 'Semana2', 'Semana3', 'Semana4');
                }
                break;
            case 'YEAR':
                data.push('Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre');
                break;
            default:
                break;
        }
        return data;
    }


    useEffect(() => {
        const headers = getRowHeaders(reportMode)
        setRowHeaders(headers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportMode]);

    return (
        <Fragment>
            {/* <div className="card card-custom  card-stretch">
                <div className="card-body"> */}
                    <div className="grid-container-reports table-responsive ">
                        <div className="pageitemheader1">
                        </div>
                        <div className="pageitemheader2">
                            <div className="grid-container-reports-columns">
                                <CashRegisterReportTableData Data={ColumnHeaders} />
                            </div>
                        </div>
                        <div className="pageitemmain1">
                            <CashRegisterReportTableData Data={RowHeaders} />
                        </div>
                        <div className="pageitemmain2">
                            <div className="grid-container-reports-columns">
                                {reportData && reportData.map((reportDataObj, i) => (
                                    <CashRegisterReportTableData Data={reportDataObj} />
                                ))}

                            </div>
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </Fragment>
    );
};

export default CashRegisterReport;